import { T } from '../../typebox';

export const Gender = T.OurEnum(
  {
    male: {},
    female: {},
  },
  {
    dbSchema: 'public',
  },
);
