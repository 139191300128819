import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { PaymentStatus } from './PaymentStatus';

export const AyoconnectPayment = PrismaModel(
  {
    name: 'AyoconnectPayment',
    dbSchema: 'backoffice',
    key: ['id'],
  },
  {
    id: T.AutoIncrement(),
    createdAt: T.CreatedAt(),
    updatedAt: T.UpdatedAt(),
    amount: T.Money(),
    status: PaymentStatus,
    invoiceDate: T.LocalDate(),
    flowId: T.Unique(T.Nullable(T.String())),
    failureReason: T.Nullable(T.String()),
    maskedCard: T.Nullable(T.String()),
    loanId: T.Integer(),
    cardReferenceNumber: T.Nullable(T.String()),
  },
);
