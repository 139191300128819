import { T } from '../../typebox';

export const Bank = T.OurEnum(
  {
    bca: { title: 'BCA' },
    bri: { title: 'BRI' },
    bni: { title: 'BNI' },
    mandiri: {},
    seabank: { title: 'SeaBank' },
    ocbc: { title: 'OCBC' },
    cimbNiaga: { title: 'CIMB Niaga' },
    other: {},
  },
  {
    dbSchema: 'public',
  },
);
