import { T } from '../../typebox';

export const UnderwritingPhReferenceCheckValidation = T.OurEnum(
  {
    validated: {},
    notValidated: {},
    weakValidation: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
