import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const OnboardingCompanyContact = PrismaModel(
  {
    name: 'OnboardingCompanyContact',
    dbSchema: 'public',
    key: ['onboardingId'],
    description: 'Indonesia only',
  },
  {
    onboardingId: T.Integer(),
    phone: T.Nullable(T.FullPhone({ title: 'Company phone' })),
    email: T.Nullable(T.Email({ title: 'Company email' })),
    finfraUserId: T.Nullable(T.String()),
  },
);
