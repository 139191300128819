import { GeoDivision } from '../models/dictionaries';
import { createListEndpoint } from '@getmo/common/endpoint';
import { T } from '@getmo/common/typebox';

export const geoDivisionListEndpoint = createListEndpoint({
  anonymous: true,
  path: '/references/geoDivisions',
  item: GeoDivision,
  filters: T.Object({
    parentCode: T.Nullable(T.String()),
  }),
});
