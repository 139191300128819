import { createEndpoint } from '@getmo/common/endpoint';
import { T } from '@getmo/common/typebox';

export const feedbackEndpoint = createEndpoint({
  method: 'post',
  path: '/feedback',
  body: T.Object({
    mark: T.Integer(),
    text: T.String(),
  }),
  response: T.Void(),
});
