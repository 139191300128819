import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { FieldVisitBusinessPermit } from './FieldVisitBusinessPermit';
import { FieldVisitEyeTest } from './FieldVisitEyeTest';
import { FieldVisitGross } from './FieldVisitGross';
import { FieldVisitHouseOwnership } from './FieldVisitHouseOwnership';
import { FieldVisitReputation } from './FieldVisitReputation';
import { FieldVisitTenure } from './FieldVisitTenure';

export const UnderwritingPhFieldVisitData = PrismaModel(
  {
    name: 'UnderwritingPhFieldVisitData',
    dbSchema: 'backoffice',
    key: ['underwritingId'],
    description: 'Credit investigation field visit data',
  },
  {
    underwritingId: T.Integer(),
    geoPos: T.Array(T.Number(), { description: 'Visit geoposition' }),
    isLivingInAddress: T.Nullable(
      T.Boolean({ title: 'Living in the given address' }),
    ),
    communityTenure: T.Nullable(FieldVisitTenure),
    barangayReputation: T.Nullable(FieldVisitReputation),
    hasRelativesInGovermentByBarangay: T.Nullable(
      T.Boolean({ title: 'Relative in office or the force' }),
    ),
    hasCollectorVisits: T.Nullable(
      T.Boolean({ title: 'Do other collectors visit the borrower' }),
    ),
    isAlwaysOpen: T.Nullable(
      T.Boolean({ title: 'Is the business always open' }),
    ),
    communityReputation: T.Nullable(FieldVisitReputation),
    hasRelativesInGovermentByNeighbor: T.Nullable(
      T.Boolean({ title: 'Relative in office or the force' }),
    ),
    houseOwnership: T.Nullable(FieldVisitHouseOwnership),
    businessPermit: T.Nullable(FieldVisitBusinessPermit),
    businessTenure: T.Nullable(FieldVisitTenure),
    eyeTest: T.Nullable(FieldVisitEyeTest),
    dailyGross: T.Nullable(FieldVisitGross),
  },
);
