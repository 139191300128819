import { T } from '../../typebox';

export const UnderwritingStatus = T.OurEnum(
  {
    new: { title: 'New application' },
    revision: {},
    fieldVisit: {},
    underwriting: {},
    creditCommittee: {},
    negotiation: {},
    offerAccepted: {},
    disbursed: {},
    declined: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
