import { T } from '../../typebox';

export const UnderwritingPhUnderwriterOpinion = T.OurEnum(
  {
    veryBad: {},
    bad: {},
    moderate: {},
    good: {},
    veryGood: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
