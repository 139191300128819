import { T } from '../../typebox';

export const ShopConnectionStatus = T.OurEnum(
  {
    connectionInit: {},
    connected: {},
    syncInit: {},
    synced: {},
    error: {},
  },
  {
    dbSchema: 'public',
  },
);
