import { $Enums } from '@getmo/common/prisma';
import {
  fillMissingBankStatementsForAccount,
  getBankStatementsPeriod,
  getRequiredBankStatementMonthCount,
} from '@getmo/common/utils/bankStatements';
import { DF } from '@getmo/common/vendor/date-fns';
import { R } from '@getmo/common/vendor/remeda';
import { Alert, Box, Grid, Stack, SvgIcon, Tooltip, Typography } from '@mui/material';
import { Form, FormikErrors, FormikTouched } from 'formik';
import { FormEventHandler, useEffect, useMemo } from 'react';
import { ReactComponent as Target } from '../../../../assets/target.svg';
import { formik } from '../../../../utils/formik';
import { useSnackbar } from '../../../components/snackbar/useSnackbar';
import { useBankFileInput } from '../../../components/upload/useBankFileInput';
import { useThemeQuery } from '../../../hooks/useThemeQuery';
import { BankStatementsInitialValues } from './index';
import { useMissingStatementsDialog } from './useMissingStatementsDialog';
import { PaperCard } from './PaperCard';
import { StatementState, UploadedStatements } from './UploadedStatements';
import { StepSubmitButton } from '../../../../components/StepSubmitButton/StepSubmitButton';
import { noTranslateClassName } from '../../../../utils/weglot';

interface Props {
  inputName: string;
  values: BankStatementsInitialValues;
  errors: FormikErrors<BankStatementsInitialValues>;
  touched: FormikTouched<BankStatementsInitialValues>;
  isValidating: boolean;
  legalType: $Enums.LegalType | null;
  onSubmit: () => void;
}

export const BankDocuments = ({ inputName, values, errors, touched, legalType, isValidating, onSubmit }: Props) => {
  const error = formik.getError(errors.banks, touched.banks);
  const { isSmall } = useThemeQuery();

  const { showError } = useSnackbar();
  useEffect(() => {
    if (isValidating && error) {
      showError('Upload at least 1 bank statement');
    }
  }, [isValidating, error]);

  const bs = useMemo(() => {
    const todayDate = new Date();
    const period = getBankStatementsPeriod(todayDate, legalType);

    return R.mapValues(values.banks, (files) =>
      R.pipe(
        files,
        R.flatMap((f) => f.bankStatements?.map((s) => ({ fileId: f.id, ...s })) ?? []),
        R.groupBy((d) => d.accountNumber),
        R.mapValues((statements) =>
          R.pipe(
            fillMissingBankStatementsForAccount(statements, legalType, todayDate),
            R.flatMap((s) => {
              let d = s.startDate;
              const res: (typeof s)[] = [];
              while (DF.add(d, { months: 1 }) < s.endDate) {
                res.push({ ...s, startDate: d, endDate: DF.add(d, { months: 1, days: -1 }) });
                d = DF.add(d, { months: 1 });
              }
              res.push({ ...s, startDate: d });
              return res;
            }),
            R.filter((s) => s.startDate >= period.start && s.endDate <= period.end),
            R.groupBy((s) => DF.startOfMonth(s.startDate).valueOf()),
            R.entries(),
            R.map(([d, g]) => ({
              month: new Date(Number(d)),
              isUploaded: g.every((s) => 'fileId' in s),
            })),
            R.sortBy(({ month }) => month.valueOf()),
          ),
        ),
        R.entries(),
        R.sortBy(([accountNumber]) => accountNumber),
      ),
    );
  }, [values]);

  const monthCount = useMemo(() => getRequiredBankStatementMonthCount(legalType), [legalType]);
  const isPeriodCovered = useMemo(
    () =>
      R.pipe(
        values.banks,
        R.values(),
        R.flat(),
        R.flatMap((f) => f.bankStatements ?? []),
        (s) => fillMissingBankStatementsForAccount(s, legalType, new Date()),
        R.find((s) => !('bank' in s)),
        (hasMissing) => !hasMissing,
      ),
    [values.banks, legalType],
  );
  const hasUnparsedDocuments = useMemo(
    () =>
      !!(
        values.banks.bankStatementPersonal.some((f) => !f.bankStatements?.length) ||
        values.banks.bankStatementCorporate.some((f) => !f.bankStatements?.length)
      ),
    [values.banks],
  );

  const { modal, openModal } = useMissingStatementsDialog(monthCount, onSubmit);
  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    if (!isPeriodCovered && hasUnparsedDocuments) {
      openModal();
    } else {
      onSubmit();
    }
  };

  const Total = ({ statementsByAccount }: { statementsByAccount: [string, StatementState[]][] }) => {
    const info = (
      <>
        Please, try to cover the period of at least {getRequiredBankStatementMonthCount(legalType)} months with your
        statements
      </>
    );
    const requiredCount = getRequiredBankStatementMonthCount(legalType);
    const uploadedCount = Math.max(
      0,
      ...statementsByAccount.map(([, statements]) => statements.filter(({ isUploaded }) => isUploaded).length),
    );

    return (
      <Box display="flex" flexDirection="column" sx={{ marginLeft: 'auto' }}>
        <Tooltip title={info} enterTouchDelay={0}>
          <SvgIcon
            component={Target}
            inheritViewBox
            sx={{ width: '60px', height: '60px', color: uploadedCount === requiredCount ? 'primary.main' : 'grey' }}
          />
        </Tooltip>
        <Typography variant="body2" pl="10px" className={noTranslateClassName}>
          {uploadedCount} / {requiredCount}
        </Typography>
      </Box>
    );
  };

  const Header = ({ statementsByAccount }: { statementsByAccount: [string, StatementState[]][] }) => (
    <Box mb={1}>
      <Box display="flex" gap={3} mb={isSmall ? 1 : 0}>
        <Alert severity="info" sx={{ width: '100%', height: 'fit-content' }}>
          Please, provide statements for the last <b>{monthCount}&nbsp;months</b>
        </Alert>
        <Total statementsByAccount={statementsByAccount} />
      </Box>
      <Stack gap={1} maxHeight={400} overflow="scroll">
        {statementsByAccount.map(([accountNumber, statements]) => (
          <UploadedStatements
            key={accountNumber}
            accountNumber={accountNumber}
            statementStates={statements}
            monthCount={getRequiredBankStatementMonthCount(legalType)}
          />
        ))}
      </Stack>
    </Box>
  );

  const corporate = useBankFileInput({
    inputName,
    header: <Header statementsByAccount={bs[$Enums.DocumentType.bankStatementCorporate]} />,
    accountType: $Enums.DocumentType.bankStatementCorporate,
  });

  const personal = useBankFileInput({
    inputName,
    header: <Header statementsByAccount={bs[$Enums.DocumentType.bankStatementPersonal]} />,
    accountType: $Enums.DocumentType.bankStatementPersonal,
  });

  return (
    <Form onSubmit={handleSubmit}>
      <Stack gap={4}>
        {legalType === $Enums.LegalType.corporate && (
          <PaperCard title="2. Upload your corporate bank statements">
            <Stack direction="row" gap={1} flexWrap="wrap">
              {corporate.element}
            </Stack>
            <>
              {!!corporate.previews.length && (
                <Stack direction="row" gap={1} flexWrap="wrap" pt={0} pb={2} maxHeight={200} overflow="scroll">
                  {corporate.previews}
                </Stack>
              )}
            </>
          </PaperCard>
        )}
        <PaperCard
          title={
            legalType === $Enums.LegalType.corporate
              ? '3. Upload your personal bank statements'
              : '2. Upload your bank statements'
          }
        >
          <Stack justifyContent="space-between" gap={4}>
            <Stack direction="row" gap={1} flexWrap="wrap">
              {personal.element}
            </Stack>
          </Stack>
          <>
            {!!personal.previews.length && (
              <Stack direction="row" gap={1} flexWrap="wrap" pt={0.5} maxHeight={200} overflow="scroll">
                {personal.previews}
              </Stack>
            )}
          </>
        </PaperCard>

        {!isPeriodCovered && !hasUnparsedDocuments ? (
          <Alert severity="error" sx={{ width: '100%', height: 'fit-content' }}>
            Please, upload statements for the last {monthCount} months
          </Alert>
        ) : null}

        <Grid item display="flex" justifyContent="center" py={3}>
          <StepSubmitButton disabled={!isPeriodCovered && !hasUnparsedDocuments} />
        </Grid>
      </Stack>

      {modal}
    </Form>
  );
};
