import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const UnderwritingRequestDescriptionData = PrismaModel(
  {
    name: 'UnderwritingRequestDescriptionData',
    dbSchema: 'backoffice',
    key: ['underwritingId'],
    description: 'Indonesia only',
  },
  {
    underwritingId: T.Integer(),
    organizationName: T.Nullable(T.String({ title: 'Name of organization' })),
    npwp: T.Nullable(T.Npwp({ title: 'NPWP' })),
    businessRegistrationDate: T.Nullable(
      T.LocalDate({ title: 'Date of business registration' }),
    ),
    generalDirectorAssumptionDate: T.Nullable(
      T.LocalDate({
        title: 'Date of assumption of the position of General Director',
      }),
    ),
    foundersJoiningDate: T.Nullable(
      T.LocalDate({ title: 'Date of joining the founders' }),
    ),
    founderFirstBusinessRegistrationDate: T.Nullable(
      T.LocalDate({
        title: "Date of registration of the founder's first business",
      }),
    ),
    businessRegistrationPlace: T.Nullable(
      T.String({ title: 'Place of business registration' }),
    ),
    kbliText: T.Nullable(T.String()),
    affilatedCompanies: T.Nullable(T.String({ title: 'Affiliated companies' })),
  },
);
