import { T } from '../../typebox';

export const MessageStatus = T.OurEnum(
  {
    queued: {},
    queuingFailed: {},
    sent: {},
    deliveredToOperator: {},
    deliveredToRecipient: {},
    rejectedByOperator: {},
    rejectedByRecipient: {},
    read: {},
    ok: {},
    error: {},
    unknown: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
