import { T } from '../../typebox';

export const EmployeeTag = T.OurEnum(
  {
    inboundLeadsOwner: {},
    lowAndMidTicketOwner: {},
    phFieldSalesAgent: {},
    creditInvestigationAgent: {},
    fieldCollectionAgent: {},
    writeOffAccess: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
