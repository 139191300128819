import { AllHTMLAttributes } from 'react';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useSnackbar } from '../snackbar/useSnackbar';
import { DeleteFunction, FileInputOptionProps, UploadFunction, useFileUpload } from './base/useFileUpload';
import { callApi } from '../../../api';
import { documentDeleteEndpoint, documentUploadEndpoint } from '@getmo/onboarding/schemas/endpoints/documents';
import { $Enums } from '@getmo/common/prisma';

interface Props extends FileInputOptionProps {
  inputName: string;
  label: string;
  subtitle?: string | JSX.Element;
  docType: $Enums.DocumentType;
  required?: boolean;
  coownerId?: number;
  extractImageFromPdf?: 'ktp' | 'npwp';
  capture?: AllHTMLAttributes<HTMLInputElement>['capture'];
}

export interface DocumentFileInput {
  element: JSX.Element;
  previews: JSX.Element[];
}

export const useDocumentFileInput = ({
  inputName,
  label,
  subtitle,
  docType,
  coownerId,
  extractImageFromPdf,
  required = true,
  capture,
  ...fileInputOpts
}: Props): DocumentFileInput => {
  const { showError } = useSnackbar();

  const upload: UploadFunction = async (file) => {
    try {
      return await callApi(documentUploadEndpoint, {
        body: { type: docType, file, coownerId },
      });
    } catch (e) {
      const { error } = useApiErrors(e);
      showError(error);
      throw e;
    }
  };

  const deleteFile: DeleteFunction = async (id) => {
    try {
      await callApi(documentDeleteEndpoint, {
        params: { documentId: id },
      });
    } catch (e) {
      const { error } = useApiErrors(e);
      showError(error);
      throw e;
    }
  };

  return useFileUpload({
    inputName,
    upload,
    deleteFile,
    label,
    subtitle,
    isRequired: required,
    extractImageFromPdf,
    showInfoAlert: true,
    capture,
    ...fileInputOpts,
  });
};
