import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { LoanActionType } from './LoanActionType';

export const LoanAction = PrismaModel(
  {
    name: 'LoanAction',
    dbSchema: 'backoffice',
    key: ['id'],
    indexes: [['loanId']],
    description: 'Activities related to loans',
  },
  {
    id: T.AutoIncrement(),
    type: LoanActionType,
    loanId: T.Integer(),
    date: T.CreatedAt(),
    comment: T.RichText(),
    handler: T.Nullable(T.String({ title: '3rd Party Handling' })),
    promiseToPayDate: T.Nullable(T.LocalDate()),
    promiseToPayAmount: T.Nullable(T.Money()),
    assigneeId: T.Nullable(
      T.Integer({
        description:
          'Employee that have been assigned to an activity\n# Assignee',
      }),
    ),
    reporterId: T.Integer({
      description:
        'Employee who created the record (assignment / comment/ confirmed field visit)',
    }),
  },
);
