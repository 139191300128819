import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const KtpOcrData = PrismaModel(
  {
    name: 'KtpOcrData',
    dbSchema: 'public',
    key: ['documentId'],
    description: 'Indonesia only',
  },
  {
    documentId: T.Integer(),
    province: T.String(),
    cityDistrict: T.String(),
    bloodGroup: T.String(),
    religion: T.String(),
    address: T.String(),
    validUntil: T.String(),
    citizenship: T.String(),
    nik: T.String(),
    fullName: T.String(),
    work: T.String(),
    placeOfBirth: T.String(),
    subdistrict: T.String(),
    gender: T.String(),
    rt: T.String(),
    rw: T.String(),
    dob: T.LocalDate(),
    maritalStatus: T.String(),
    villageSubdistrict: T.String(),
  },
);
