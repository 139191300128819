import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { FieldVisitEyeTest } from './FieldVisitEyeTest';

export const CollectionVisit = PrismaModel(
  {
    name: 'CollectionVisit',
    dbSchema: 'backoffice',
    key: ['id'],
  },
  {
    id: T.Integer({ description: 'Assignment ID' }),
    isBusinessOperational: T.Boolean({
      title: 'Is business still operational',
    }),
    isClientInTheStore: T.Boolean({ title: 'Is client in the store' }),
    inventoryCondition: FieldVisitEyeTest,
  },
);
