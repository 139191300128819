import { Option } from '../../components/FormikSelectInput';
import { $Enums } from '@getmo/common/prisma';

export const SalesChannel: Option[] = [
  { label: 'Online', value: $Enums.SaleChannelType.online },
  { label: 'Offline', value: $Enums.SaleChannelType.offline },
  { label: 'Online & Offline', value: $Enums.SaleChannelType.onlineOffline },
];

export const BusinessTypes: Option[] = [
  { label: 'Personal', value: $Enums.LegalType.personal },
  { label: 'Corporate', value: $Enums.LegalType.corporate },
];

export const IdBusinessCategory: Option[] = [
  { label: 'Accommodation and Hospitality', value: $Enums.IdBusinessCategory.accommodationHospitality },
  { label: 'Agriculture and Fisheries', value: $Enums.IdBusinessCategory.agricultureFisheries },
  { label: 'Construction and Infrastructure', value: $Enums.IdBusinessCategory.constructionInfrastructure },
  { label: 'Consumer Goods Manufacturing', value: $Enums.IdBusinessCategory.consumerGoodsManufacturing },
  { label: 'Energy and Utilities', value: $Enums.IdBusinessCategory.energyUtilities },
  { label: 'Financial Services and Insurance', value: $Enums.IdBusinessCategory.financialServicesInsurance },
  { label: 'Food and Beverage Manufacturing', value: $Enums.IdBusinessCategory.foodBeverageManufacturing },
  { label: 'Information Technology and Software', value: $Enums.IdBusinessCategory.informationTechnologySoftware },
  { label: 'Mining and Quarrying', value: $Enums.IdBusinessCategory.miningQuarrying },
  { label: 'Professional and Consultancy Services', value: $Enums.IdBusinessCategory.professionalConsultancyServices },
  { label: 'Real Estate and Property Management', value: $Enums.IdBusinessCategory.realEstatePropertyManagement },
  { label: 'Restaurants and Food Services', value: $Enums.IdBusinessCategory.restaurantsFoodServices },
  { label: 'Transportation and Logistics', value: $Enums.IdBusinessCategory.transportationLogistics },
  {
    label: 'Waste Management and Environmental Services',
    value: $Enums.IdBusinessCategory.wasteManagementEnvironmentalServices,
  },
  { label: 'Wholesale and Retail Trade', value: $Enums.IdBusinessCategory.wholesaleRetailTrade },
  { label: 'Other', value: $Enums.IdBusinessCategory.other },
];

export const IndonesianCities: Option[] = [
  { label: 'Bali', value: $Enums.IdCity.bali },
  { label: 'Bandar Lampung', value: $Enums.IdCity.bandarLampung },
  { label: 'Bandung', value: $Enums.IdCity.bandung },
  { label: 'Jabodetabek', value: $Enums.IdCity.jabodetabek },
  { label: 'Malang', value: $Enums.IdCity.malang },
  { label: 'Medan', value: $Enums.IdCity.medan },
  { label: 'Palembang', value: $Enums.IdCity.palembang },
  { label: 'Semarang', value: $Enums.IdCity.semarang },
  { label: 'Surabaya', value: $Enums.IdCity.surabaya },
  { label: 'Surakarta', value: $Enums.IdCity.surakarta },
  { label: 'Yogyakarta', value: $Enums.IdCity.yogyakarta },
  { label: 'Other', value: $Enums.IdCity.other },
];
