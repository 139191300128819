import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const UnderwritingPhCashCycle = PrismaModel(
  {
    name: 'UnderwritingPhCashCycle',
    dbSchema: 'backoffice',
    key: ['underwritingId'],
  },
  {
    underwritingId: T.Integer(),
    paymentTermsSuppliers: T.Nullable(
      T.Integer({ title: 'Payment Terms with Suppliers (days)' }),
    ),
    paymentTermsCustomers: T.Nullable(
      T.Integer({ title: 'Payment Terms with Customers (days)' }),
    ),
    goodsSellTime: T.Nullable(
      T.Integer({ title: 'Time to sell goods / deliver service (days)' }),
    ),
    receivablesTotal: T.Nullable(
      T.Integer({ title: 'Total Receivables from Aging List' }),
    ),
    receivablesOnTime: T.Nullable(
      T.Integer({ title: 'On Time Receivables from Aging List' }),
    ),
    receivablesLate0to30Days: T.Nullable(
      T.Integer({ title: 'Receivables late 0-30 days from Aging List' }),
    ),
    receivablesLate30to60Days: T.Nullable(
      T.Integer({ title: 'Receivables late 30-60 days from Aging List' }),
    ),
  },
);
