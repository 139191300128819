import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { Bank } from './Bank';
import { BankStatementParser } from './BankStatementParser';
import { Currency } from './Currency';

export const BankStatement = PrismaModel(
  {
    name: 'BankStatement',
    dbSchema: 'public',
    key: ['id'],
    description: 'Data parsed from bank statements',
  },
  {
    id: T.AutoIncrement({ description: 'Bank statement ID' }),
    documentId: T.Integer({ description: 'Document ID (Table Document)' }),
    bank: Bank,
    startDate: T.LocalDate(),
    endDate: T.LocalDate(),
    startBalance: T.Nullable(T.Money()),
    endBalance: T.Nullable(T.Money()),
    accountNumber: T.String(),
    currency: Currency,
    clientName: T.String(),
    address: T.String(),
    isFullyParsed: T.Boolean(),
    parser: BankStatementParser,
    parsingErrorPageNumber: T.Nullable(T.Integer()),
    parsingErrorRowNumber: T.Nullable(T.Integer()),
  },
);
