import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router';
import { setAuthorization } from '../api';
import { Path } from '../onboarding/routes/path';
import { AppState, useAppSelector } from '../store/store';
import { useNextStep } from '../onboarding/hooks/useNextStep';
import { $Enums } from '@getmo/common/prisma';
import { tokenStorage } from './tokenStorage';
import * as Sentry from '@sentry/react';
import { useApplication } from './applicationContext';

export const AuthGuard = ({ children }: { children: JSX.Element }) => {
  const { token } = useAppSelector((state: AppState) => state.account);
  const location = useLocation();

  if (!token) {
    return <Navigate to={Path.AccountPhoneStarter} state={{ from: location }} replace />;
  }

  setAuthorization(token);

  const { goToStep, isLastStep } = useNextStep();

  const { application, refreshApplication } = useApplication();

  useEffect(() => {
    (async () => {
      if (application.status === $Enums.OnboardingStatus.data_receaved && !isLastStep) {
        tokenStorage.deleteAccess();
        window.location.href = '/';
      }

      if (application.id) return;

      const res = await refreshApplication();
      if (res.step) goToStep(res.step);

      Sentry.setUser({
        id: res.id,
        username: res.phone ?? '',
      });
    })();
  }, [isLastStep, application.id, refreshApplication, goToStep]);

  return children;
};
