import { createAsyncThunk } from '@reduxjs/toolkit';
import { callApi, ErrorResponse } from '../../api';
import { GeoDivision } from '@getmo/onboarding/schemas/models/dictionaries';
import { AppState } from '../store';
import { geoDivisionListEndpoint } from '@getmo/onboarding/schemas/endpoints/dictionaries';

type Reject = { rejectValue: ErrorResponse };

export const dictionariesThunk = {
  loadGeoDivisions: createAsyncThunk<
    GeoDivision[],
    { parentDivision: GeoDivision | null },
    Reject & { state: AppState }
  >(
    'dictionaries/geoDivisions',
    ({ parentDivision }, { rejectWithValue }) =>
      callApi(geoDivisionListEndpoint, { qs: { parentCode: parentDivision?.code ?? 'null', pageSize: 1000 } })
        .then((data) => data.results)
        .catch((e) => rejectWithValue(e)),
    {
      condition: ({ parentDivision }, api) => {
        const state = api.getState();
        return (
          !state.dictionaries.isLoadingGeoDivisions && !state.dictionaries.geoDivisions[parentDivision?.code ?? '']
        );
      },
    },
  ),
};
