import { createType, FormatFuncKind, PrismaTypeKind, type SimpleFormattable } from '../base';
import { type StaticDecode, type TEnum, Type } from '@sinclair/typebox';
import { titleCase } from 'scule';
import { type SchemaOptions } from 'type-fest';

declare module '@sinclair/typebox' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-interface, @typescript-eslint/no-shadow
  interface TEnum<T> extends SimpleFormattable {}
}

export { type TEnum } from '@sinclair/typebox';

export const Enum = <T extends Record<string, string | number>>(e: T, opts?: SchemaOptions): TEnum<T> =>
  createType(Type.Enum(e, opts), {
    [FormatFuncKind]: (value, _, schema) => formatEnumValue(schema, value),
    [PrismaTypeKind]: 'NAME_PLACEHOLDER',
  });

export const formatEnumValue = <T extends Record<string, string | number>>(
  schema: TEnum<T>,
  value: StaticDecode<TEnum<T>>,
) => schema.anyOf.find((s) => s.const === value)?.title ?? titleCase(String(value));
