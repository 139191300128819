import { HighlightOffSharp } from '@mui/icons-material';
import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, Paper, Typography } from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import { FC, useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { StepContent } from '../../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../../components/StepSubmitButton/StepSubmitButton';
import { phone } from '../../../utils/phone';
import { yup } from '../../../utils/yup';
import FormikAutocompleteInput from '../../components/FormikAutocompleteInput';
import { Option } from '../../components/FormikSelectInput';
import FormikTextInput from '../../components/FormikTextInput';
import { InputPhoneIcon } from '../../components/input/icons/InputPhoneIcon';
import { useSnackbar } from '../../components/snackbar/useSnackbar';
import { DocumentFileInput } from '../../components/upload/DocumentFileInput';
import { FullDocument } from '@getmo/onboarding/schemas/models/documents';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useNextStep } from '../../hooks/useNextStep';
import { defaultMimeTypes } from '../../types/mimeType';
import { useApplication } from '../../../auth/applicationContext';
import { $Enums } from '@getmo/common/prisma';
import { callApi } from '../../../api';
import { coownerCreateUpdateEndpoint, coownerDeleteEndpoint } from '@getmo/onboarding/schemas/endpoints/application';

type CoownerValue = {
  data: {
    id?: number;
    name: string;
    phone: string;
    email: string;
  };
  npwp: FullDocument[];
  ktp: FullDocument[];
};
interface InitialValues {
  relation: Option | undefined;
  name: string;
  phone: string;
  files: {
    ktp: FullDocument[];
    npwp: FullDocument[];
  };
  coowners: CoownerValue[];
}

const relationOptions: Option[] = [
  { label: 'Spouse', value: 'Spouse' },
  { label: 'Parent', value: 'Parent' },
  { label: 'Sibling', value: 'Sibling' },
  { label: 'Child', value: 'Child' },
  { label: 'Friend', value: 'Friend' },
];

const IdentityVerification: FC = () => {
  const title = <>Identity Verification</>;
  const subtitle = <>For your personal verification, we kindly request the following information.</>;

  const { application, updateApplication, setApplication } = useApplication();
  const initialValues = useMemo<InitialValues>(
    () => ({
      name: application.emergencyContact?.name || '',
      phone: application.emergencyContact?.phone?.replace(/^\+?62/, '') || '',
      relation: application.emergencyContact?.relation
        ? { label: application.emergencyContact.relation, value: application.emergencyContact.relation }
        : undefined,
      files: {
        ktp: application.documents.filter((d) => d.type === $Enums.DocumentType.ktp && !d.coownerId),
        npwp: application.documents.filter((d) => d.type === $Enums.DocumentType.npwpPersonal && !d.coownerId),
      },
      coowners: application.coowners.map((coowner) => ({
        data: {
          name: coowner.name ?? '',
          phone: coowner.phone?.replace(/^\+?62/, '') || '',
          email: coowner.email ?? '',
        },
        ktp: application.documents.filter((d) => d.type === $Enums.DocumentType.ktp && d.coownerId === coowner.id),
        npwp: application.documents.filter(
          (d) => d.type === $Enums.DocumentType.npwpPersonal && d.coownerId === coowner.id,
        ),
      })),
    }),
    [application.id],
  );

  const validation = Yup.object({
    name: Yup.string().required(),
    phone: yup.validatePhone(),
    relation: Yup.object().required(),
    files: Yup.object({
      ktp: Yup.array().min(1, yup.uploadFile()),
      npwp: Yup.array().min(1, yup.uploadFile()),
    }),
    coowners: Yup.array().of(
      Yup.object({
        data: Yup.object({
          name: Yup.string().required(),
          phone: yup
            .validatePhone()
            .test(
              'matchApplicantPhone',
              "Coowner's phone shouldn't be the same as your phone number. Please do not add yourself as a coowner.",
              (v) => v !== application.phone?.replace(/^\+62/, ''),
            )
            .test('uniq', "Coowners' phone numbers must be different.", (v, ctx) => {
              const coowners: CoownerValue[] = ctx.from?.at(-1)?.value?.coowners ?? [];
              const phoneCount = coowners.filter((c) => c.data.phone === v).length;

              return phoneCount <= 1;
            }),
          email: yup.validateEmail(),
        }),
        ktp: Yup.array().min(1, yup.uploadFile()),
        npwp: Yup.array().min(1, yup.uploadFile()),
      }),
    ),
  });

  const [initialErrors, setInitialErrors] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const { showError } = useSnackbar();

  const { goToNextStep } = useNextStep();
  const onSubmit = useCallback(
    async ({ name, phone: phoneVal, relation: type, files, coowners }: InitialValues) => {
      setInitialErrors({});
      setSubmitting(true);

      try {
        await updateApplication({
          emergencyContact: { name, phone: phone.toApiValue(phoneVal), relation: type?.value || '' },
        });
        const uploadIds = files.ktp.concat(files.npwp).map((u) => u.id);
        setApplication((a) => ({
          ...a,
          documents: a.documents
            .filter((d) => !uploadIds.includes(d.id))
            .concat(files.ktp)
            .concat(files.npwp),
        }));

        return goToNextStep();
      } catch (e) {
        const { error, validationErrors } = useApiErrors(e);
        setInitialErrors(validationErrors);
        if (error) {
          showError(error);
        }
      } finally {
        setSubmitting(false);
      }

      application.coowners.forEach((existingCoowner) => {
        if (!coowners.some(({ data: coowner }) => coowner.id === existingCoowner.id)) {
          callApi(coownerDeleteEndpoint, {
            params: { coownerId: existingCoowner.id },
          });
          setApplication((a) => ({ ...a, coowners: a.coowners.filter((c) => c.id !== existingCoowner.id) }));
        }
      });

      const uploadedToId = (file: FullDocument) => file.id;
      coowners.map(async ({ data: coowner, ktp, npwp }) => {
        const { id } = await callApi(coownerCreateUpdateEndpoint, {
          body: {
            ...coowner,
            phone: phone.toApiValue(coowner.phone),
            documentIds: [...ktp.map(uploadedToId), ...npwp.map(uploadedToId)],
          },
        });

        const files = [...ktp, ...npwp].flat().map((d) => ({ ...d, coownerId: id ?? null }));
        const uploadIds = files.map((u) => u.id);
        setApplication((a) => ({
          ...a,
          documents: a.documents.filter((d) => !uploadIds.includes(d.id)).concat(files),
        }));
      });
    },
    [application.coowners],
  );

  return (
    <StepContent title={title} subtitle={subtitle}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        initialErrors={initialErrors}
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container direction="column">
                <DocumentFileInput
                  name="files.ktp"
                  label="KTP photo"
                  docType={$Enums.DocumentType.ktp}
                  mimeTypes={defaultMimeTypes.ktp}
                  multiple={false}
                  extractImageFromPdf="ktp"
                />
                <DocumentFileInput
                  name="files.npwp"
                  label="NPWP photo"
                  docType={$Enums.DocumentType.npwpPersonal}
                  mimeTypes={defaultMimeTypes.npwp_pers}
                  multiple={false}
                  extractImageFromPdf="npwp"
                />
                <Grid item>
                  <FormikTextInput name="name" type="text" label="Emergency Contact Full Name" required />
                </Grid>
                <Grid item>
                  <FormikTextInput
                    name="phone"
                    type="text"
                    label="Emergency Contact Phone Number"
                    required
                    transform={(v) => phone.prettify(v)}
                    untransform={(v) => phone.depretty(v)}
                    InputProps={{ startAdornment: <InputPhoneIcon /> }}
                  />
                </Grid>
                <FormikAutocompleteInput
                  name="relation"
                  options={relationOptions}
                  label="Your Relation with Emergency Contact"
                  required
                />
                <Grid item sx={{ backgroundColor: 'info' }} py={2}>
                  <Typography fontSize="1.1rem" fontWeight="500">
                    Multiple Business Owners?{' '}
                  </Typography>
                  <Typography color="text.secondary">
                    Verification is mandatory to all business owner. Please click &apos;Fill Another Owner Data&apos;
                    button below to fill in another owner&apos;s data for identity verification.
                  </Typography>
                </Grid>

                <FieldArray
                  name="coowners"
                  render={({ push, remove }) => (
                    <>
                      {values.coowners.map((_, idx) => {
                        return (
                          <Grid item key={idx}>
                            <Card component={Paper} elevation={0}>
                              <CardHeader
                                titleTypographyProps={{
                                  variant: 'h6',
                                }}
                                sx={{ pr: 1, pl: 0, pb: 2 }}
                                action={
                                  <IconButton onClick={() => remove(idx)}>
                                    <HighlightOffSharp />
                                  </IconButton>
                                }
                                title={`Owner ${idx + 2} Personal Data`}
                              />
                              <CardContent sx={{ p: 0 }}>
                                <Grid item>
                                  <FormikTextInput
                                    type="text"
                                    name={`coowners.${idx}.data.name`}
                                    label="Full Name"
                                    required
                                  />
                                </Grid>
                                <Grid item>
                                  <FormikTextInput type="text" name={`coowners.${idx}.data.role`} label="Role" />
                                </Grid>
                                <FormikTextInput
                                  name={`coowners.${idx}.data.phone`}
                                  type="text"
                                  label="Phone Number"
                                  required
                                  transform={(v) => phone.prettify(v)}
                                  untransform={(v) => phone.depretty(v)}
                                  InputProps={{
                                    startAdornment: <InputPhoneIcon />,
                                  }}
                                />
                                <Grid item>
                                  <FormikTextInput
                                    type="text"
                                    name={`coowners.${idx}.data.email`}
                                    label="Email"
                                    transform={(v) => v.trim()}
                                    placeholder="example@gmail.com"
                                  />
                                </Grid>
                                <Grid item>
                                  <DocumentFileInput
                                    name={`coowners.${idx}.ktp`}
                                    label="KTP photo"
                                    docType={$Enums.DocumentType.ktp}
                                    mimeTypes={defaultMimeTypes.ktp}
                                    multiple={false}
                                    coownerId={values.coowners[idx].data.id}
                                    extractImageFromPdf="ktp"
                                  />
                                </Grid>

                                <Grid item>
                                  <DocumentFileInput
                                    name={`coowners.${idx}.npwp`}
                                    label="NPWP photo"
                                    docType={$Enums.DocumentType.npwpPersonal}
                                    mimeTypes={defaultMimeTypes.npwp_pers}
                                    multiple={false}
                                    coownerId={values.coowners[idx].data.id}
                                    extractImageFromPdf="npwp"
                                  />
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        );
                      })}

                      {values.coowners.length < 4 && (
                        <Box pb={2}>
                          <Button
                            size="small"
                            disableRipple
                            variant="outlined"
                            onClick={async () => {
                              const res = await callApi(coownerCreateUpdateEndpoint, {
                                body: { name: '', phone: '', email: '' },
                              });
                              push({ data: res, ktp: [], npwp: [] });
                            }}
                            sx={{
                              maxWidth: 'fit-content',
                            }}
                          >
                            + Fill Another Owner Data
                          </Button>
                        </Box>
                      )}
                    </>
                  )}
                />

                <Grid item display="flex" justifyContent="center" py={3}>
                  <StepSubmitButton isSubmitting={isSubmitting} />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StepContent>
  );
};

export default IdentityVerification;
