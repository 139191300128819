import { createType, LogTransformKind } from '../base';
import { Format } from '../formats';
import * as Type from '../vendorType';
import { FormatRegistry, type TAny, type TTransform } from '@sinclair/typebox';

export interface TFile extends TTransform<TAny, File> {
  format: Format.Binary;
}

FormatRegistry.Set(Format.Binary, () => true);

const FileType = (): TFile =>
  createType(
    Type.Transform(Type.Any({ type: 'string', format: Format.Binary }))
      .Decode((v) => v as unknown as File)
      .Encode((v) => v as unknown as File),
    {
      [LogTransformKind]: () => '## binary ##',
    },
  );
export { FileType as File };
