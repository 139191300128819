import { T } from '../../typebox';

export const ShopOrderStatus = T.OurEnum(
  {
    new: {},
    shipped: {},
    delivered: {},
    canceled: {},
  },
  {
    dbSchema: 'public',
  },
);
