import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Page, Step } from '../../store/reducers/stepsReducer';
import { AppState, useAppStore } from '../../store/store';
import { useApplication } from '../../auth/applicationContext';
import { $Enums } from '@getmo/common/prisma';
import { Application } from '@getmo/onboarding/schemas/endpoints/application';

const findCurrentStep = (steps: Step[], currentURL: string) => {
  return steps.find((s) =>
    s.pages.some(({ path }) => {
      return currentURL.match(new RegExp(`^${path}/?$`));
    }),
  );
};

const findCurrentPageIdx = (step: Step, currentURL: string) =>
  step.pages.findIndex(({ path }) => {
    return currentURL.match(new RegExp(`^${path}/?$`));
  });

const getNextPage = (state: AppState, application: Application, currentURL: string) => {
  let nextPage: Page | undefined;

  const { steps } = state.steps;
  const current = findCurrentStep(steps, currentURL);
  if (!current) {
    return null;
  }

  const stepIdx = steps.indexOf(current);
  const pageIdx = findCurrentPageIdx(current, currentURL);

  steps.slice(stepIdx).every((step, idx) => {
    const pages = idx === 0 ? step.pages.slice(pageIdx + 1) : step.pages;
    const possibleNextPage = pages.find(({ condition }) => !condition || condition(application));

    if (possibleNextPage) {
      nextPage = possibleNextPage;
      return false;
    }
    return true;
  });

  return nextPage;
};

const getPrevPage = (state: AppState, application: Application, currentURL: string) => {
  let prevPage: Page | undefined;

  const { steps } = state.steps;
  const current = findCurrentStep(steps, currentURL);
  if (!current) {
    return null;
  }

  const stepIdx = steps.indexOf(current);
  const pageIdx = findCurrentPageIdx(current, currentURL);

  steps
    .slice(0, stepIdx + 1)
    .reverse()
    .every((step, idx) => {
      const pages = idx === 0 ? step.pages.slice(0, pageIdx) : step.pages;

      const possiblePrevPage = [...pages].reverse().find(({ condition }) => !condition || condition(application));

      if (possiblePrevPage) {
        prevPage = possiblePrevPage;
        return false;
      }
      return true;
    });

  return prevPage;
};

const checkIsLastStep = (state: AppState, currentURL: string) => {
  const { steps } = state.steps;
  const lastStep = steps[steps.length - 1];
  const lastPage = lastStep?.pages[lastStep.pages.length - 1];

  return lastPage && !!currentURL.match(`^${lastPage.path}/?$`);
};

export const useNextStep = () => {
  const { pathname: currentURL } = useLocation();
  const store = useAppStore();

  const navigate = useNavigate();

  const { applicationRef, updateApplication } = useApplication();

  const goToNextStep = useCallback(async () => {
    const currentState = store.getState();
    const nextStep = getNextPage(currentState, applicationRef.current, currentURL);

    if (!nextStep) {
      return;
    }

    const lastStep = checkIsLastStep(currentState, nextStep.path);
    if (lastStep) {
      await updateApplication({ status: $Enums.OnboardingStatus.data_receaved });
    } else if (currentState.account.token && applicationRef.current.id) {
      void updateApplication({ step: nextStep.name });
    }

    navigate(nextStep.path);
  }, [store, currentURL, getNextPage, applicationRef, updateApplication]);

  const goToPrevStep = useCallback(() => {
    const currentState = store.getState();
    const prevStep = getPrevPage(currentState, applicationRef.current, currentURL);

    if (!prevStep) {
      return;
    }

    const lastStep = checkIsLastStep(currentState, currentURL);
    if (currentState.account.token && applicationRef.current.id && !lastStep) {
      void updateApplication({ step: prevStep.name });
    }

    navigate(prevStep.path);
  }, [store, currentURL, getPrevPage, applicationRef, updateApplication]);

  const goToStep = (stepName: string) => {
    const { steps } = store.getState().steps;
    let path: string | undefined;

    steps.forEach((step) => {
      if (path) {
        return;
      }

      path = step.pages.find(({ name }) => name === stepName)?.path;
    });

    if (path) {
      navigate(path);
    }
  };

  const isLastStep = useMemo(() => {
    return checkIsLastStep(store.getState(), currentURL);
  }, [store, currentURL]);

  return { goToNextStep, goToPrevStep, goToStep, isLastStep };
};
