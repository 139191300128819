import { T } from '../../typebox';

export const PhBusinessCategory = T.OurEnum(
  {
    sariSariStore: {},
    petSuppliesStore: {},
    grocery: {},
    wholesaler: {},
    pharmacy: {},
    motorcycleShop: {},
    marketVendor: {},
    hardwareStore: {},
    foodBusiness: {},
    shopeeLazadaSeller: { title: 'Shopee/Lazada seller' },
    fbSeller: { title: 'FB seller' },
    onlineSeller: {},
    streetVendor: {},
    b2bSupplier: { title: 'B2B Supplier' },
    other: {},
  },
  {
    dbSchema: 'public',
    title: 'Phillipine business category',
  },
);
