import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { UnderwritingPipeline } from './UnderwritingPipeline';
import { UnderwritingStatus } from './UnderwritingStatus';

export const Underwriting = PrismaModel(
  {
    name: 'Underwriting',
    dbSchema: 'backoffice',
    key: ['id'],
    indexes: [['selectedTermsId']],
    description: "State of application's underwriting process",
  },
  {
    id: T.Integer(),
    salesAgentId: T.Nullable(T.Integer()),
    createdAt: T.CreatedAt(),
    updatedAt: T.UpdatedAt(),
    visitedAt: T.Nullable(
      T.Date({ description: 'Date and time of Field visit' }),
    ),
    order: T.Unique(T.AutoIncrement()),
    pipeline: UnderwritingPipeline,
    status: UnderwritingStatus,
    fieldAgentId: T.Nullable(T.Integer()),
    underwriterId: T.Nullable(T.Integer()),
    selectedTermsId: T.Nullable(T.Integer()),
  },
);
