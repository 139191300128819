import { isPrismaType, PrismaTypeKind } from '../base';
import { type TSchema } from '@sinclair/typebox';

const UniqueKind = Symbol.for('UniqueKind');

export type TUnique<T extends TSchema> = T & { [UniqueKind]: true };

export const Unique = <T extends TSchema>(schema: T): TUnique<T> => ({
  ...schema,
  [UniqueKind]: true,
  [PrismaTypeKind]: isPrismaType(schema) ? schema[PrismaTypeKind] + ' @unique' : undefined,
});
