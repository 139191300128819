import { T } from '../../typebox';

export const IdBusinessCategory = T.OurEnum(
  {
    agricultureFisheries: { title: 'Agriculture and Fisheries' },
    miningQuarrying: { title: 'Mining and Quarrying' },
    foodBeverageManufacturing: { title: 'Food and Beverage Manufacturing' },
    consumerGoodsManufacturing: { title: 'Consumer Goods Manufacturing' },
    energyUtilities: { title: 'Energy and Utilities' },
    wasteManagementEnvironmentalServices: {
      title: 'Waste Management and Environmental Services',
    },
    constructionInfrastructure: { title: 'Construction and Infrastructure' },
    wholesaleRetailTrade: { title: 'Wholesale and Retail Trade' },
    transportationLogistics: { title: 'Transportation and Logistics' },
    accommodationHospitality: { title: 'Accommodation and Hospitality' },
    restaurantsFoodServices: { title: 'Restaurants and Food Services' },
    informationTechnologySoftware: {
      title: 'Information Technology and Software',
    },
    financialServicesInsurance: { title: 'Financial Services and Insurance' },
    realEstatePropertyManagement: {
      title: 'Real Estate and Property Management',
    },
    professionalConsultancyServices: {
      title: 'Professional and Consultancy Services',
    },
    other: {},
  },
  {
    dbSchema: 'public',
  },
);
