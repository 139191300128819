import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { NotificationType } from './NotificationType';

export const NotificationUnsubscription = PrismaModel(
  {
    name: 'NotificationUnsubscription',
    dbSchema: 'public',
    key: ['email', 'type'],
  },
  {
    email: T.String(),
    type: NotificationType,
    createdAt: T.CreatedAt(),
  },
);
