import { T } from '../../typebox';

export const PhPersonalIdType = T.OurEnum(
  {
    umid: { title: 'UMID' },
    sss: { title: 'SSS' },
    passport: {},
    driversLicense: {},
    tin: { title: 'TIN' },
    philhealth: { title: 'PhilHealth' },
    votersId: { title: "Voter's ID" },
    nationalId: {},
    postalId: {},
    barangayId: {},
  },
  {
    dbSchema: 'public',
  },
);
