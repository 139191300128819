import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const OnboardingCoowner = PrismaModel(
  {
    name: 'OnboardingCoowner',
    dbSchema: 'public',
    key: ['id'],
    description: 'Business coowner information\nIndonesia only',
  },
  {
    id: T.AutoIncrement(),
    onboardingId: T.Integer(),
    name: T.Nullable(T.String()),
    role: T.Nullable(T.String()),
    phone: T.Nullable(T.FullPhone()),
    email: T.Nullable(T.Email()),
    ktp: T.Nullable(T.String({ title: 'NIK' })),
    shareholding: T.Nullable(T.Percent()),
    finfraUserId: T.Nullable(T.String()),
  },
);
