import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const UnderwritingInterviewData = PrismaModel(
  {
    name: 'UnderwritingInterviewData',
    dbSchema: 'backoffice',
    key: ['underwritingId'],
    description: 'Indonesia only',
  },
  {
    underwritingId: T.Integer(),
    actualBusinessAddress: T.Nullable(T.String()),
    website: T.Nullable(T.Uri()),
    activitiesDetail: T.Nullable(T.String({ title: 'Activities in detail' })),
    buyers: T.Nullable(T.String({ title: 'Who are the buyers' })),
    loanPurposeDescription: T.Nullable(
      T.String({ title: 'Detailed description of the purpose of the loan' }),
    ),
    customerRequestAmount: T.Nullable(
      T.Money({ title: "Customer's request in the application form (amount)" }),
    ),
  },
);
