import { T } from '../../typebox';

export const ProductType = T.OurEnum(
  {
    fixedInterest: {},
    rbf: {},
    bullet: {},
    balloon: {},
    archivedIndonesian: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
