import { DocumentType, FullDocument } from '../models/documents';
import { messageResponse } from './common';
import { createEndpoint } from '@getmo/common/endpoint';
import { T } from '@getmo/common/typebox';

export const documentUploadEndpoint = createEndpoint({
  consumes: 'multipart/form-data',
  method: 'post',
  path: '/documents',
  body: T.Object({
    file: T.File(),
    type: DocumentType,
    coownerId: T.Optional(T.Number()),
  }),
  response: FullDocument,
});

export const documentDeleteEndpoint = createEndpoint({
  method: 'patch',
  path: '/user/document/delete/:documentId',
  params: T.Object({
    documentId: T.Number(),
  }),
  response: messageResponse,
});
