import { T } from '../../typebox';

export const LegalType = T.OurEnum(
  {
    personal: {},
    corporate: {},
  },
  {
    dbSchema: 'public',
  },
);
