import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { Gender } from './Gender';
import { PhPersonalIdType } from './PhPersonalIdType';

export const PersonUnverifiedData = PrismaModel(
  {
    name: 'PersonUnverifiedData',
    dbSchema: 'public',
    key: ['onboardingId'],
    description:
      "Applicant's information submitted during onboarding and not passed KYC",
  },
  {
    onboardingId: T.Integer(),
    fullName: T.String({ title: 'Full name' }),
    personalIdNumber: T.String({ title: 'Personal ID' }),
    phPersonalIdType: T.Nullable(PhPersonalIdType),
    dob: T.LocalDate({ title: 'Birth Date' }),
    selfiePhotoIds: T.Array(T.String()),
    gender: T.Nullable(Gender),
    selfieFaceScores: T.Array(T.IntPercent()),
    selfieBusinessScores: T.Array(T.IntPercent()),
  },
);
