import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const AyoconnectCard = PrismaModel(
  {
    name: 'AyoconnectCard',
    dbSchema: 'public',
    key: ['referenceNumber'],
  },
  {
    referenceNumber: T.String(),
    authCode: T.String(),
    publicUserId: T.Nullable(T.String()),
    maskedCard: T.Nullable(T.String()),
    accountToken: T.Nullable(T.String()),
    bankCode: T.Nullable(T.String()),
    onboardingId: T.Unique(T.Integer()),
  },
);
