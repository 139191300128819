import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const BankTransactionSummary = PrismaModel(
  {
    name: 'BankTransactionSummary',
    dbSchema: 'analytics',
    key: ['onboardingId'],
  },
  {
    onboardingId: T.Integer(),
    avgMonthlyCreditAmount: T.Money(),
  },
);
