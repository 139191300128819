import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const XenditCustomer = PrismaModel(
  {
    name: 'XenditCustomer',
    dbSchema: 'public',
    key: ['externalId'],
  },
  {
    externalId: T.String(),
    linkedAccountTokenId: T.Nullable(T.String()),
    paymentMethodId: T.Nullable(T.String()),
    paymentMethodDisplay: T.Nullable(T.String()),
    onboardingId: T.Unique(T.Integer()),
  },
);
