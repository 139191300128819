import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { GeoDivisionType } from './GeoDivisionType';

export const GeoDivision = PrismaModel(
  {
    name: 'GeoDivision',
    dbSchema: 'public',
    key: ['code'],
    description: 'Philippines geographic area',
  },
  {
    code: T.String(),
    type: GeoDivisionType,
    name: T.String(),
    parentCode: T.Nullable(T.String()),
  },
);
