import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { IdBusinessCategory } from './IdBusinessCategory';
import { IdCity } from './IdCity';
import { KycStatus } from './KycStatus';
import { LegalType } from './LegalType';
import { OnboardingQualification } from './OnboardingQualification';
import { OnboardingStatus } from './OnboardingStatus';
import { PhBusinessCategory } from './PhBusinessCategory';
import { PhCity } from './PhCity';
import { Region } from './Region';
import { SaleChannelType } from './SaleChannelType';

export const Onboarding = PrismaModel(
  {
    name: 'Onboarding',
    dbSchema: 'public',
    key: ['id'],
    indexes: [['leadOwnerId'], ['phone']],
    description: 'Application data filled by a client',
  },
  {
    id: T.AutoIncrement(),
    name: T.Nullable(T.String()),
    phone: T.Nullable(T.FullPhone()),
    email: T.Nullable(T.Email()),
    createdAt: T.CreatedAt(),
    updatedAt: T.UpdatedAt({ description: 'Date of last application change' }),
    submittedAt: T.CreatedAt({
      description:
        'Date of onboarding finish. Ignore this value if status = new',
    }),
    status: OnboardingStatus,
    region: Region,
    utm: T.Nullable(
      T.Any({
        description: 'UTM tags from site (all values without utm_ prefixes)',
      }),
    ),
    ip: T.Nullable(T.IpAddress({ title: 'IP' })),
    userAgent: T.Nullable(T.String()),
    loanAmount: T.Nullable(T.Money({ description: 'Desired amount' })),
    revenue: T.Nullable(T.Money()),
    loanDuration: T.Nullable(T.Integer()),
    originalId: T.Nullable(
      T.Integer({
        description: 'Link to original application if this one is a copy',
      }),
    ),
    loanOrderNumber: T.Integer({
      description:
        '> 1 if the client already had disbursed loans during onboarding',
      default: 1,
    }),
    shopName: T.Nullable(T.String()),
    geoDivisionCode: T.Nullable(T.String()),
    location: T.Nullable(T.String({ description: 'Business address' })),
    landmark: T.Nullable(
      T.String({ description: 'Location or address explication' }),
    ),
    geoPos: T.Array(T.Number()),
    phCheckingAccount: T.Nullable(
      T.Boolean({
        title: 'Does the client have checking account under his name ',
      }),
    ),
    phHasAfs: T.Nullable(
      T.Boolean({ title: 'Does the client have ITR/AFS for 2023?' }),
    ),
    phIsBusinessOwner: T.Nullable(
      T.Boolean({ title: 'Does client own the business' }),
    ),
    phIsReadyForVisit: T.Nullable(
      T.Boolean({ title: 'Is client ready for field visit' }),
    ),
    phCity: T.Nullable(PhCity),
    phBusinessCategory: T.Nullable(PhBusinessCategory),
    step: T.Nullable(
      T.String({
        description:
          'Current step of the onboarding. Where the client stopped.',
      }),
    ),
    qualification: T.Nullable(OnboardingQualification),
    leadOwnerId: T.Nullable(
      T.Integer({ description: 'Employee in charge of this application' }),
    ),
    applicantId: T.Nullable(
      T.Integer({ description: 'Link to Person data verified during KYC' }),
    ),
    slackReportThreadId: T.Nullable(T.String()),
    idCity: T.Nullable(IdCity),
    idBusinessCategory: T.Nullable(IdBusinessCategory),
    loanPurpose: T.Nullable(T.String({ description: 'Indonesia only' })),
    legalType: T.Nullable(LegalType),
    legalCategory: T.Array(T.String(), { description: 'Indonesia only' }),
    typeOfSalesChannel: T.Nullable(SaleChannelType),
    yearsBusinessOperation: T.Nullable(
      T.Integer({
        title: 'How many years your business operates',
        description: 'Indonesia only',
      }),
    ),
    vidaKycStatus: T.Nullable(KycStatus),
    finfraUserId: T.Nullable(T.String({ description: 'Indonesia only' })),
    top5Suppliers: T.Nullable(
      T.Multiline({
        title: 'List of Top 5 Suppliers and Top 5 Customers',
        description: 'Indonesia only',
      }),
    ),
    outstandingDebt: T.Nullable(
      T.String({ description: 'Debt in previous loans\nIndonesia only' }),
    ),
    hubspotContactId: T.Nullable(T.String({ description: 'Trash' })),
    leadSource: T.Nullable(T.String({ description: 'Trash' })),
    hubspotLeadSource: T.String({
      title: 'Lead Source',
      description: 'Trash',
      default: '',
    }),
    hubspotTrafficSource: T.String({
      title: 'Traffic Source',
      description: 'Trash',
      default: '',
    }),
    shownName: T.Nullable(
      T.DbGeneratedString({
        description:
          'Name shown in BackOffice application\nEquals "name" column for lowTickets and "shopName" for others ',
      }),
    ),
  },
);
