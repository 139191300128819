import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const LoanRestructure = PrismaModel(
  {
    name: 'LoanRestructure',
    dbSchema: 'backoffice',
    key: ['loanId', 'date'],
    description: 'Trash. Not used anymore',
  },
  {
    loanId: T.Integer(),
    date: T.LocalDate(),
    loanAgreementNumber: T.Nullable(T.String()),
    termsId: T.Unique(T.Integer()),
  },
);
