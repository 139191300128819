import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const UnderwritingPhBankAccount = PrismaModel(
  {
    name: 'UnderwritingPhBankAccount',
    dbSchema: 'backoffice',
    key: ['id'],
  },
  {
    id: T.AutoIncrement(),
    underwritingId: T.Integer(),
    bankId: T.Integer({ title: 'Bank' }),
    accountNumber: T.String(),
  },
);
