import { Box, Grid, Link, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { StepContentHeader } from '../../../components/StepContent/StepContentHeader';
import { StepSubmitButton } from '../../../components/StepSubmitButton/StepSubmitButton';
import { updatePhoneNumber } from '../../../store/reducers/actions';
import { useAppDispatch } from '../../../store/store';
import { phone } from '../../../utils/phone';
import FormikCheckbox from '../../components/FormikCheckbox';
import FormikTextInput from '../../components/FormikTextInput';
import { InputPhoneIcon } from '../../components/input/icons/InputPhoneIcon';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useNextStep } from '../../hooks/useNextStep';
import { useTermsAndConditionsModal } from '../welcome/TermAndConditionsModal';
import { WelcomeStepper } from '../welcome/WelcomeStepper';
import { yup } from '../../../utils/yup';
import { useSnackbar } from '../../components/snackbar/useSnackbar';
import { useBrandSettings, usePhoneCode } from '../../hooks/useBrandSettings';
import { useVidaTermsAndConditionsModal } from '../welcome/VidaTermAndConditionsModal';
import { callApi } from '../../../api';
import { sendOtpEndpoint } from '@getmo/onboarding/schemas/endpoints/auth';

interface InitialValues {
  phoneNumber: string;
  consent: boolean;
}

export const PhoneStarter = () => {
  const { name } = useBrandSettings();
  const title = [<>Welcome to {name} Onboarding!</>];

  const initialValues: InitialValues = {
    phoneNumber: '',
    consent: false,
  };
  const [initialErrors, setInitialErros] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const validation = Yup.object({
    phoneNumber: yup.validatePhone(),
    consent: Yup.boolean().isTrue('Please, agree to the Terms and Conditions'),
  });

  const { showError } = useSnackbar();
  const dispatch = useAppDispatch();
  const { goToNextStep } = useNextStep();

  const onSubmit = async ({ phoneNumber }: InitialValues) => {
    try {
      setInitialErros({});
      setSubmitting(true);

      const { sessionId } = await callApi(sendOtpEndpoint, {
        body: { phone: phone.toApiValue(phoneNumber) },
      });

      dispatch(updatePhoneNumber({ phoneNumber, sessionId }));
      goToNextStep();
    } catch (e) {
      const { error } = useApiErrors(e);
      if (error) {
        showError(error);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const { modal, openModal } = useTermsAndConditionsModal();
  const { modal: vidaModal, openModal: openVidaModal } = useVidaTermsAndConditionsModal();
  const brandSettings = useBrandSettings();

  const consentLabel = brandSettings.consent ? (
    <Typography variant="body2">
      {brandSettings.consent}{' '}
      <Link
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openModal();
        }}
        color="primary.main"
        variant="body2"
        sx={{ cursor: 'pointer' }}
      >
        Privacy Notice & Terms & Conditions
      </Link>
    </Typography>
  ) : (
    <Typography variant="body2">
      I hereby give consent for my personal data to be processed by Vida as a partner of Getmo, and to be collected,
      used and disclosed for the purposes set in the{' '}
      <Link
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openVidaModal();
        }}
        color="primary.main"
        variant="body2"
        sx={{ cursor: 'pointer' }}
      >
        Vida Terms & Conditions
      </Link>{' '}
      and the{' '}
      <Link
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openModal();
        }}
        color="primary.main"
        variant="body2"
        sx={{ cursor: 'pointer' }}
      >
        Privacy Notice & Terms & Conditions
      </Link>
    </Typography>
  );

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%" pt={4}>
      <StepContentHeader title={title} />
      <Grid
        container
        direction={{ xs: 'column', md: 'row' }}
        width={{ xs: '80%', sm: '70%', md: '100%' }}
        sx={{ mb: 2, py: 4 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item md={5.5} lg={5.5}>
          <WelcomeStepper />
          {brandSettings.partnerSign && (
            <Box display="flex" justifyContent="end">
              {brandSettings.partnerSign}
            </Box>
          )}
        </Grid>
        <Grid item md={5} lg={5}>
          <Box mb={1} mt={{ xs: 4, lg: 0 }}>
            <Typography variant="subtitle1" fontWeight={600}>
              Phone number
            </Typography>
          </Box>

          <Formik
            enableReinitialize
            initialValues={initialValues}
            initialErrors={initialErrors}
            onSubmit={onSubmit}
            validationSchema={validation}
          >
            {({ handleSubmit }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Grid container direction="column" gap={2}>
                    <Grid item>
                      <FormikTextInput
                        fullWidth
                        type="tel"
                        name="phoneNumber"
                        placeholder={usePhoneCode() === '63' ? '900 000 0000' : '123 4567-8910'}
                        inputMode="numeric"
                        InputProps={{ startAdornment: <InputPhoneIcon /> }}
                        transform={(v) => phone.prettify(v)}
                        untransform={(v) => phone.depretty(v)}
                      />
                    </Grid>
                    <Box pt={1} display="flex" gap={1} alignItems="center">
                      <FormikCheckbox name="consent" label={consentLabel} />
                    </Box>
                    {modal}
                    {vidaModal}
                    <Grid item display="flex" justifyContent="center">
                      <StepSubmitButton isSubmitting={submitting} text="Continue" />
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  );
};
