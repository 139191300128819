import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const UnderwritingCreditHistoryData = PrismaModel(
  {
    name: 'UnderwritingCreditHistoryData',
    dbSchema: 'backoffice',
    key: ['underwritingId'],
    description: 'Indonesia only',
  },
  {
    underwritingId: T.Integer(),
    totalDebtLegalEntitiesFounders: T.Nullable(
      T.Money({
        title:
          'Total debt on all loans and credits of legal entities and founders',
      }),
    ),
    maxLoanServicing12Months: T.Nullable(
      T.Money({
        title:
          'Maximum amount of single-entity servicing of loans and borrowings for 12 months',
      }),
    ),
    monthlyPaymentLegalEntitiesFounders: T.Nullable(
      T.Money({
        title:
          'Monthly payment on all loans and credits of legal entities and founders',
      }),
    ),
    overduePaymentsLoans12MonthsUpTo30Days: T.Nullable(
      T.Integer({
        title: 'Overdue payments and loans for 12 months up to 30 days',
        unit: 'pcs',
      }),
    ),
    overduePaymentsLoans24MonthsMoreThan60Days: T.Nullable(
      T.Integer({
        title: 'Overdue payments and loans for 24 months more than 60 days',
        unit: 'pcs',
      }),
    ),
    loansCreditsReceivedLastMonth: T.Nullable(
      T.Integer({
        title:
          'Number of loans and credits received during the month prior to application',
        unit: 'pcs',
      }),
    ),
    amountLoansCreditsReceivedLastMonth: T.Nullable(
      T.Money({
        title:
          'Amount of loans and credits received for the month prior to application',
      }),
    ),
    borrowerCreditBurdenRelativeToAvgMonthlyRevenue: T.Nullable(
      T.IntPercent({
        title:
          "Borrower's credit burden in relation to average monthly revenue",
      }),
    ),
    monthlyPaymentsLoansCreditsRelativeToAvgMonthlyRevenue: T.Nullable(
      T.IntPercent({
        title:
          'Monthly payments on loans and credits relative to average monthly revenue',
      }),
    ),
    pdlLoans12Months: T.Nullable(
      T.Integer({ title: 'Number of PDL for 12 months', unit: 'pcs' }),
    ),
    dscr: T.Nullable(T.Integer({ title: 'DSCR, xN of monthly revenue' })),
    isShopeeDebitor: T.Boolean({ default: false }),
  },
);
