import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const PhCreditHistory = PrismaModel(
  {
    name: 'PhCreditHistory',
    dbSchema: 'backoffice',
    key: ['id'],
    description: 'Phillipines. Credit History',
  },
  {
    id: T.AutoIncrement(),
    createdAt: T.CreatedAt(),
    score: T.Integer(),
    reasons: T.Array(T.String()),
    rawResult: T.Any({ description: 'Total information from Credit Bureau. ' }),
    onboardingId: T.Integer(),
  },
);
