import { Decimal } from '../../prisma';
import { currency } from '../../utils/formats/currency';
import { createType, FormatFuncKind, type FormattableSchema, PrismaTypeKind, type SimpleFormattable } from '../base';
import { numberFormatter } from '../vendorType';
import * as Type from '../vendorType';
import { type $Enums } from '@prisma/client';
import { type NumberOptions, type TNumber, type TString, type TTransform, TypeGuard } from '@sinclair/typebox';

export const percentFormatter = Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 3 });

export interface TBigInt extends SimpleFormattable, TTransform<TNumber, bigint> {}
const TypeBigInt = (opts?: NumberOptions): TBigInt =>
  createType(
    Type.Transform(Type.Number(opts))
      // Temp fix for decimals
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .Decode((v) => BigInt(Number.parseInt(v as any, 10)))
      .Encode((v) => Number(v.toString())),
    {
      [FormatFuncKind]: String,
    },
  );
export { TypeBigInt as BigInt };

export interface TPercent extends SimpleFormattable, TNumber {
  numberFormat: 'percent';
}
export const IsPercent = (schema: unknown): schema is TPercent =>
  TypeGuard.IsNumber(schema) && schema.numberFormat === 'percent';
export const Percent = (opts?: NumberOptions): TPercent =>
  createType(Type.Number({ numberFormat: 'percent', unit: '%', ...opts }), {
    [FormatFuncKind]: (value) => percentFormatter.format(value / 100),
    [PrismaTypeKind]: 'Float /// [Percent]',
  });
export const IntPercent = (opts?: NumberOptions): TPercent =>
  createType(Type.Number({ numberFormat: 'percent', unit: '%', ...opts }), {
    [FormatFuncKind]: (value) => percentFormatter.format(value / 100),
    [PrismaTypeKind]: 'Int /// [Percent]',
  });

export interface TDecimal extends SimpleFormattable, TTransform<TNumber, Decimal> {
  numberFormat: 'decimal';
}
export const IsDecimal = (schema: unknown): schema is TDecimal =>
  TypeGuard.IsNumber(schema) && schema.numberFormat === 'decimal';
const TypeDecimal = (opts?: NumberOptions): TDecimal =>
  createType(
    Type.Transform(Type.Number({ ...opts, numberFormat: 'decimal' }))
      .Decode((v) => new Decimal(v))
      .Encode((v) => v.toNumber()),
    {
      [FormatFuncKind]: (value) => numberFormatter.format(value.toNumber()),
      [PrismaTypeKind]: 'Decimal',
    },
  );
export { TypeDecimal as Decimal };

export interface TDecimalString extends SimpleFormattable, TTransform<TString, Decimal> {}
export const DecimalString = (opts?: NumberOptions): TDecimalString =>
  createType(
    Type.Transform(Type.String({ ...opts }))
      .Decode((v) => new Decimal(v))
      .Encode((v) => v.toString()),
    {
      [FormatFuncKind]: (value) => numberFormatter.format(value.toNumber()),
    },
  );

export interface TMoney extends TTransform<TNumber, Decimal>, FormattableSchema<{ region: $Enums.Region }> {
  numberFormat: 'percent';
  money: true;
}
export const IsMoney = (schema: unknown): schema is TMoney =>
  TypeGuard.IsNumber(schema) && schema.numberFormat === 'decimal' && schema.money;
export const Money = (opts?: NumberOptions): TMoney =>
  createType(TypeDecimal({ ...opts, money: true }), {
    [FormatFuncKind]: (value, options) => currency.formatMoney(value, options),
    [PrismaTypeKind]: 'Decimal /// [Money]',
  });

export const SmallInt = (opts?: NumberOptions): TNumber =>
  createType(Type.Number(opts), { [PrismaTypeKind]: 'Int @db.SmallInt' });

export const AutoIncrement = (opts?: NumberOptions): TNumber =>
  createType(Type.Number(opts), { [PrismaTypeKind]: 'Int @default(autoincrement())' });
