import { Box, Button, Grid } from '@mui/material';
import { Stack } from '@mui/system';
import { FieldArray, Form, Formik } from 'formik';
import { FC, useMemo } from 'react';
import { StepContent } from '../../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../../components/StepSubmitButton/StepSubmitButton';
import { useNextStep } from '../../hooks/useNextStep';
import Blibli from '@getmo/common/assets/stores/blibli.svg';
import Lazada from '@getmo/common/assets/stores/lazada.svg';
import Shopee from '@getmo/common/assets/stores/shopee.svg';
import Tiktok from '@getmo/common/assets/stores/tiktok.svg';
import Tokopedia from '@getmo/common/assets/stores/tokopedia.svg';
import Website from '@getmo/common/assets/stores/website.svg';
import Instagram from '@getmo/common/assets/stores/instagram.svg';
import { ConnectStoreLink } from './ConnectionStoreLink';
import { StoreIcon } from './StoreIcon';
import { useConnectStoresModal } from './useConnectStoresModal';
import { useConnectionStore } from './useConnectionStore';
import { getShopConnectionType } from '@getmo/common/utils/shops';
import { useApplication } from '../../../auth/applicationContext';

const title = [<>Share Your Online Platform</>];
const subtitle = [
  <>
    Enhance your online presence by sharing the links to your e-commerce platform, official website, and social media
    accounts. You can connect your e-commerce & share with us view access to your marketplace data. Rest assured, your
    data&apos;s security is our top priority.
  </>,
];

type Input = {
  id?: number;
  placeholder?: string;
};

const ConnectStores: FC = () => {
  useConnectionStore();
  const { goToNextStep } = useNextStep();

  const { application } = useApplication();
  const isLoading = !application.id;
  const initialValues = useMemo(
    () => ({
      channels: application.saleChannels
        .map<Input>((c) => ({ id: c.id }))
        .concat(
          ...[
            'https://www.tokopedia.com/your-store-name',
            'https://www.shopee.co.id/your-store-name',
            'https://www.lazada.co.id/your-store-name',
          ].map((p) => ({ placeholder: p })),
        ),
    }),
    [application.id],
  );

  const notSyncedShops = useMemo(
    () => application.saleChannels.filter((c) => !c.isSynced && getShopConnectionType(c.type)),
    [application.saleChannels],
  );

  const { modal, openModal } = useConnectStoresModal(notSyncedShops, goToNextStep);

  const onSubmit = () => {
    if (notSyncedShops.length || !application.saleChannels.length) {
      openModal();
      return;
    }

    goToNextStep();
  };
  return (
    <StepContent title={title} subtitle={subtitle} width="extended">
      <Formik initialValues={initialValues} enableReinitialize onSubmit={onSubmit}>
        {({ values, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container direction="column" gap={3}>
              <Grid item>
                <Stack display="flex" direction="row" justifyContent="space-around" mb={1}>
                  <StoreIcon src={Tokopedia} title="Tokopedia" />
                  <StoreIcon src={Shopee} title="Shopee" />
                  <StoreIcon src={Lazada} title="Lazada" />
                  <StoreIcon src={Tiktok} title="Tiktok Shop" />
                  <StoreIcon src={Blibli} title="Blibli" />
                  <StoreIcon src={Instagram} title="Instagram" />
                  <StoreIcon src={Website} title="Website" />
                </Stack>
              </Grid>
              <Grid item>
                <Box display="flex" flexDirection="column" rowGap={2}>
                  <Stack rowGap={0}>
                    <FieldArray
                      name="channels"
                      render={({ push, remove }) => (
                        <>
                          {values.channels.map((input, idx) => (
                            <ConnectStoreLink
                              key={idx}
                              placeholder={input.placeholder}
                              setShopId={(id: number) => setFieldValue(`channels.${idx}.id`, id)}
                              deleteInput={() => remove(idx)}
                              shopId={input.id}
                              isLoading={isLoading}
                              isLast={values.channels.length === 1}
                            />
                          ))}
                          {values.channels.length < 11 && (
                            <Button
                              size="small"
                              disableRipple
                              variant="outlined"
                              onClick={() => push({})}
                              sx={{
                                maxWidth: 'fit-content',
                              }}
                            >
                              + Add another link
                            </Button>
                          )}
                        </>
                      )}
                    />
                  </Stack>
                </Box>
              </Grid>
              <Grid item display="flex" justifyContent="center" py={3}>
                <StepSubmitButton disabled={isLoading} />
              </Grid>
              {modal}
            </Grid>
          </Form>
        )}
      </Formik>
    </StepContent>
  );
};

export default ConnectStores;
