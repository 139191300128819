import { T } from '../../typebox';

export const SaleChannelType = T.OurEnum(
  {
    offline: {},
    online: {},
    onlineOffline: { title: 'Online&Offline' },
  },
  {
    dbSchema: 'public',
    title: 'Type of sales channel',
  },
);
