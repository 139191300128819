import { T } from '../../typebox';

export const RepaymentSource = T.OurEnum(
  {
    danabijak: {},
    dragonpay: {},
    manual: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
