import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { Bank } from './Bank';
import { DocumentType } from './DocumentType';

export const Document = PrismaModel(
  {
    name: 'Document',
    dbSchema: 'public',
    key: ['id'],
    indexes: [['onboardingId']],
    description:
      'Document attached to an application during onboarding or in backoffice',
  },
  {
    id: T.AutoIncrement(),
    createdAt: T.CreatedAt(),
    fileName: T.String(),
    filePath: T.String({ description: 'Google Drive id' }),
    type: DocumentType,
    onboardingId: T.Integer(),
    isDeleted: T.Boolean({ default: false }),
    isUploadedByClient: T.Boolean({
      description: 'Whether this file was uploaded by client or by employee ',
      default: false,
    }),
    originalId: T.Nullable(
      T.Integer({
        description:
          'For copied application this field points to the original document',
      }),
    ),
    md5Checksum: T.String(),
    validationScore: T.Nullable(
      T.IntPercent({
        description: 'Filled by OpenAI prompt for personalId images',
      }),
    ),
    coownerId: T.Nullable(T.Integer()),
    bank: T.Nullable(Bank),
    ocrStarted: T.Boolean({ default: false }),
    ocrDocId: T.Nullable(T.Integer()),
    pdfCreator: T.Nullable(T.String()),
    pdfProducer: T.Nullable(T.String()),
  },
);
