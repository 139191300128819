import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { MessageChannel } from './MessageChannel';
import { MessageDirection } from './MessageDirection';
import { MessageStatus } from './MessageStatus';
import { NotificationType } from './NotificationType';

export const Message = PrismaModel(
  {
    name: 'Message',
    dbSchema: 'backoffice',
    key: ['id'],
    indexes: [['destination'], ['externalId']],
    description: 'Communication message with a client. SMS, Email',
  },
  {
    id: T.AutoIncrement(),
    externalId: T.Unique(T.String()),
    channel: MessageChannel,
    notificationType: T.Nullable(NotificationType),
    direction: MessageDirection,
    status: MessageStatus,
    createdAt: T.CreatedAt(),
    updatedAt: T.UpdatedAt(),
    text: T.Multiline(),
    destination: T.String(),
    price: T.Nullable(T.Money()),
    errorCode: T.Nullable(T.Integer()),
    errorMessage: T.Nullable(T.String()),
  },
);
