import { T } from '../../typebox';

export const SaleChannel = T.OurEnum(
  {
    shopee: {},
    tokopedia: {},
    tikTok: {},
    lazada: {},
    blibli: {},
    zalora: {},
    b2b: {},
    offline: {},
    reseller: {},
    others: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
