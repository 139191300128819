import { T } from '../../typebox';

export const UnderwritingPhObligationType = T.OurEnum(
  {
    autoLoan: {},
    creditCard: {},
    unsecuredLoan: {},
    securedLoan: {},
    other: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
