import { type Decimal } from '../../prisma';
import { type $Enums } from '@prisma/client';

export type CurrencyFormatOptions = Intl.NumberFormatOptions & { region?: $Enums.Region };

export const currency = {
  format: (value: number, options?: Intl.NumberFormatOptions) => {
    const opts: Intl.NumberFormatOptions = {
      notation: 'standard',
      minimumSignificantDigits: 2,
      style: 'currency',
      currency: 'IDR',
      currencyDisplay: 'narrowSymbol',
      ...options,
    };

    return Intl.NumberFormat('en-US', opts).format(value);
  },

  formatMoney: (value: Decimal, options?: CurrencyFormatOptions) => {
    const opts: Intl.NumberFormatOptions = {
      notation: 'standard',
      minimumSignificantDigits: 2,
      ...(options?.region && {
        style: 'currency',
        currency: options.region === 'ph' ? 'PHP' : 'IDR',
      }),
      currencyDisplay: 'narrowSymbol',
      ...options,
    };

    return Intl.NumberFormat('en-US', opts).format(value.toNumber());
  },
};
