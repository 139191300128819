import { T } from '../../typebox';

export const OnboardingStatus = T.OurEnum(
  {
    new: {},
    data_receaved: {}, // Legacy naming because of wide usage in analytics
  },
  {
    dbSchema: 'public',
    default: 'new',
  },
);
