import { Stack, StackProps } from '@mui/material';
import { MimeType } from '../../types/mimeType';
import { useDocumentFileInput } from './useDocumentFileInput';
import { AllHTMLAttributes } from 'react';
import { $Enums } from '@getmo/common/prisma';

type Props = {
  name: string;
  label: string;
  subtitle?: string | JSX.Element;
  docType: $Enums.DocumentType;
  mimeTypes: MimeType[];
  multiple?: boolean;
  extractImageFromPdf?: 'ktp' | 'npwp';
  required?: boolean;
  coownerId?: number;
  capture?: AllHTMLAttributes<HTMLInputElement>['capture'];
} & StackProps;

export const DocumentFileInput = ({
  name,
  label,
  subtitle,
  docType,
  mimeTypes,
  coownerId,
  extractImageFromPdf,
  multiple = true,
  required = true,
  capture,
  ...stackProps
}: Props) => {
  const { element, previews } = useDocumentFileInput({
    inputName: name,
    subtitle,
    label: label,
    docType,
    coownerId,
    mimeTypes,
    multiple,
    extractImageFromPdf,
    required,
    capture,
  });

  return (
    <Stack {...stackProps}>
      {element}
      {previews && (
        <Stack direction="row" gap={1} flexWrap="wrap" mb={2}>
          {previews}
        </Stack>
      )}
    </Stack>
  );
};
