import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const UnderwritingMainMarketplaceData = PrismaModel(
  {
    name: 'UnderwritingMainMarketplaceData',
    dbSchema: 'backoffice',
    key: ['underwritingId'],
    description: 'Indonesia only',
  },
  {
    underwritingId: T.Integer(),
    followersCount: T.Nullable(T.Integer({ title: 'Number of followers' })),
    reviewsCount: T.Nullable(T.Integer({ title: 'Number of reviews' })),
    reputationLevel: T.Nullable(T.String()),
    chatResponseRate: T.Nullable(T.IntPercent({ title: 'Chat response rate' })),
    avarageRating: T.Nullable(T.Number({ title: 'Average rating' })),
  },
);
