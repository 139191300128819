import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { PaymentFrequency } from './PaymentFrequency';
import { ProductType } from './ProductType';

export const TermsSuggestion = PrismaModel(
  {
    name: 'TermsSuggestion',
    dbSchema: 'backoffice',
    key: ['id'],
    indexes: [['underwritingId'], ['employeeId']],
    description: 'Terms suggested (might be several for each application)',
  },
  {
    id: T.AutoIncrement({ description: 'Terms ID' }),
    underwritingId: T.Integer(),
    date: T.CreatedAt(),
    productType: ProductType,
    rate: T.Percent(),
    amount: T.Money(),
    term: T.Integer(),
    originationFee: T.Percent(),
    paymentFrequency: PaymentFrequency,
    holdback: T.Percent(),
    minPayment: T.Money(),
    comment: T.Multiline({ title: 'Pre-disbursement conditions' }),
    nplRate: T.Percent({ title: 'NPL rate', default: 5 }),
    pdcAccount: T.Nullable(T.String({ title: 'Account for PDC' })),
    employeeId: T.Integer(),
  },
);
