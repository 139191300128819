import { T } from '../../typebox';

export const FieldVisitGross = T.OurEnum(
  {
    g2kAndBelow: { title: '2K and below' },
    g3kTo5k: { title: '2.1K to 6K' },
    g6kTo10k: { title: '6K to 10K' },
    g10kAndUp: { title: '10K and up' },
  },
  {
    dbSchema: 'backoffice',
  },
);
