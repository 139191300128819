import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const CreditHistoryLoan = PrismaModel(
  {
    name: 'CreditHistoryLoan',
    dbSchema: 'backoffice',
    key: ['creditHistoryId', 'contractCode'],
    uniques: [['creditHistoryId', 'contractCode']],
    description: 'Indonesia. Credit History, loans description',
  },
  {
    id: T.String(),
    createdAt: T.Date(),
    customerId: T.String(),
    contractCode: T.String(),
    namaLender: T.String(),
    role: T.String(),
    startDate: T.LocalDate(),
    dueDate: T.Nullable(T.LocalDate()),
    contractStatus: T.String(),
    economicSector: T.Nullable(T.String()),
    interestRateAnnual: T.String(),
    initialCreditLimit: T.Money(),
    creditLimit: T.Money(),
    debitBalance: T.Money(),
    interest: T.Integer(),
    tenure: T.Nullable(T.Integer()),
    closed: T.Boolean(),
    creditHistoryId: T.Integer(),
  },
);
