import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { UnderwritingStatus } from './UnderwritingStatus';

export const UnderwritingStageTimings = PrismaModel(
  {
    name: 'UnderwritingStageTimings',
    dbSchema: 'analytics',
    key: ['underwritingId', 'stage'],
    description: 'Time spent by applications in each underwriting status.',
  },
  {
    underwritingId: T.Integer(),
    stage: UnderwritingStatus,
    hoursInStatus: T.Number({
      description: 'Total time (working and not working hours)',
    }),
    hoursInRevision: T.Number({
      description: 'Time in revision  (working and not working hours)',
    }),
    workingHoursInStatus: T.Number({
      description: 'Only working hours spent in status',
    }),
  },
);
