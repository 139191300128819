import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const PostalCode = PrismaModel(
  {
    name: 'PostalCode',
    dbSchema: 'public',
    key: ['id'],
    description: 'Indonesian postal code',
  },
  {
    id: T.AutoIncrement(),
    province: T.String(),
    cityDistrict: T.String(),
    subdistrict: T.String(),
    villageSubdistrict: T.String(),
    code: T.Integer(),
  },
);
