import { T } from '../../typebox';

export const NotificationType = T.OurEnum(
  {
    onboardingReminder: {},
    applicationReceived: {},
    loanApprovement: {},
    repaymentReminder: {},
    repaymentReceived: {},
  },
  {
    dbSchema: 'public',
  },
);
