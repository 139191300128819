import { T } from '../../typebox';
import { R } from '../../vendor/remeda';
import { type StaticDecode } from '../../vendor/typebox';

export const UTM_KEYS = ['source', 'campaign', 'medium', 'term', 'content'] as const;

export const UtmKey = T.Union(R.map(UTM_KEYS, (k) => T.Literal(k)));

export const Utm = T.Partial(T.Record(UtmKey, T.String()));
export type Utm = StaticDecode<typeof Utm>;

export const UtmSourceFieldSales = 'fieldSales';
