import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const RepaymentAssignment = PrismaModel(
  {
    name: 'RepaymentAssignment',
    dbSchema: 'backoffice',
    key: ['repaymentId', 'loanId', 'repaymentScheduleDate'],
    description:
      'Part of an incoming repayment assigned to a repayment schedule',
  },
  {
    repaymentId: T.Integer(),
    loanId: T.Integer({ description: 'Loan ID the repayment is assigned to' }),
    repaymentScheduleDate: T.LocalDate({
      description:
        'Invoice (repayment scheduled) date that the repayment is assigned to',
    }),
    principalAmount: T.Money(),
    interestAmount: T.Money(),
    penaltyAmount: T.Money(),
    amount: T.Money({
      description:
        'Sum of principal, interests and penalties assigned to a specific Loan ID and a specific invoice date',
    }),
  },
);
