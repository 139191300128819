import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const Kbli = PrismaModel(
  {
    name: 'Kbli',
    dbSchema: 'backoffice',
    key: ['code'],
    description: 'KBLI codes directory\nIndonesia only',
  },
  {
    code: T.String(),
    parentCode: T.Nullable(T.String()),
    kbliId: T.Unique(T.String()),
    title: T.String(),
    description: T.String(),
    originalTitle: T.String(),
    originalDesc: T.String(),
  },
);
