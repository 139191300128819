import { R } from '../../vendor/remeda';
import { createType, FormatFuncKind, PrismaTypeKind } from '../base';
import { formatEnumValue } from '../vendorType';
import * as Type from '../vendorType';
import { Hint, type SchemaOptions, type TEnum, type TEnumValue, TypeGuard } from '@sinclair/typebox';

export type TOurEnum<T extends TEnumValue = TEnumValue> = TEnum<Record<T, T>>;

export const IsEnum = (schema: unknown): schema is TOurEnum => TypeGuard.IsUnion(schema) && schema[Hint] === 'Enum';
export const OurEnum = <const T extends TEnumValue>(
  values: Record<T, SchemaOptions>,
  opts?: SchemaOptions,
): TOurEnum<T> =>
  createType(
    {
      ...Type.Union(
        R.map(R.entries(values) as never, ([k, v]: [T, SchemaOptions]) => Type.Literal(k, v)),
        opts,
      ),
      [Hint]: 'Enum',
    },
    {
      [FormatFuncKind]: (value, _, schema) => formatEnumValue(schema, value),
      [PrismaTypeKind]: 'NAME_PLACEHOLDER',
    },
  );
export const OurEnumNumeric = <const T extends TEnumValue>(
  values: [T, SchemaOptions][],
  opts?: SchemaOptions,
): TOurEnum<T> =>
  createType(
    {
      ...Type.Union(
        R.map(values, ([k, v]) => Type.Literal(k, v)),
        opts,
      ),
      [Hint]: 'Enum',
    },
    {
      [FormatFuncKind]: (value, _, schema) => formatEnumValue(schema, value),
      [PrismaTypeKind]: 'NAME_PLACEHOLDER',
    },
  );
