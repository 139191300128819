import { T } from '../../typebox';

export const BankStatementParser = T.OurEnum(
  {
    bca: {},
    bcaBlu: {},
    bni: {},
    bniTi: {},
    bniMobile: {},
    bniIb: {},
    bri: {},
    mandiri: {},
    mandiriRk: {},
    mandiriTi: {},
    mandiriPie: {},
    mandiriNew: {},
    seabank: {},
    ocbc: {},
    ocbcCas: {},
    cimbNiaga: {},
  },
  {
    dbSchema: 'public',
  },
);
