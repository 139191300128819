import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { EmployeeTag } from './EmployeeTag';
import { PhCity } from './PhCity';
import { Region } from './Region';
import { UnderwritingPipeline } from './UnderwritingPipeline';
import { UserRole } from './UserRole';

export const Employee = PrismaModel(
  {
    name: 'Employee',
    dbSchema: 'backoffice',
    key: ['id'],
    indexes: [['email']],
    description: 'GetMo employee. Backoffice user.',
  },
  {
    id: T.AutoIncrement(),
    isActive: T.Boolean({ default: true }),
    tags: T.Array(EmployeeTag),
    email: T.Unique(T.Nullable(T.String())),
    phone: T.Unique(T.Nullable(T.String())),
    name: T.String(),
    role: UserRole,
    region: T.Nullable(Region),
    pipelines: T.Array(UnderwritingPipeline),
    phCities: T.Array(PhCity),
    geoDivisions: T.Array(T.String()),
    hubspotId: T.Unique(
      T.Nullable(
        T.String({
          description:
            "HubSpot has 2 entities for each employee: Contact and Owner. I'm not sure what's the difference, but Contacts are connected to Owners.\nAlso Owner contains more information. For now we store only Owner ids on our side.\nhttps://developers.hubspot.com/docs/api/crm/owners",
        }),
      ),
    ),
    gmailToken: T.Nullable(T.String()),
  },
);
