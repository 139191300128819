import { R } from '../../vendor/remeda';
import {
  type ObjectOptions,
  type StaticDecode,
  type TObject,
  type TSchema,
  type TTransform,
  Type,
} from '@sinclair/typebox';
import { pascalCase, snakeCase } from 'scule';
import { type PascalCase, type SnakeCase } from 'type-fest';

export const SnakeCaseKeysTransform = <T extends Record<string, TSchema>, K extends string = keyof T & string>(
  schema: TObject<T>,
  options?: ObjectOptions,
): TTransform<TObject<{ [Key in K as SnakeCase<Key>]: T[Key] }>, { [Key in K]: StaticDecode<T[Key]> }> => {
  const encodeMap = R.mapValues(schema.properties, (_, k) => snakeCase(k)) as Record<string, string>;
  const decodeMap = R.invert(encodeMap);

  return Type.Transform(
    Type.Object(R.mapKeys(schema.properties, (k) => encodeMap[k as string] as string) as never, options),
  )
    .Decode((o) => R.mapKeys(o, (k) => decodeMap[k] as string))
    .Encode((o) => R.mapKeys(o, (k) => encodeMap[k] as string) as never) as never;
};

export const PascalCaseKeysTransform = <T extends Record<string, TSchema>, K extends string = keyof T & string>(
  schema: TObject<T>,
  options?: ObjectOptions,
): TTransform<TObject<{ [Key in K as PascalCase<Key>]: T[Key] }>, { [Key in K]: StaticDecode<T[Key]> }> => {
  const encodeMap = R.mapValues(schema.properties, (_, k) => pascalCase(k)) as Record<string, string>;
  const decodeMap = R.invert(encodeMap);

  return Type.Transform(
    Type.Object(R.mapKeys(schema.properties, (k) => encodeMap[k as string] as string) as never, options),
  )
    .Decode((o) => R.mapKeys(o, (k) => decodeMap[k] as string))
    .Encode((o) => R.mapKeys(o, (k) => encodeMap[k] as string) as never) as never;
};
