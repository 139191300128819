import { type StaticDecode, type TSchema } from '@sinclair/typebox';
import { type Component, defineAsyncComponent, h } from 'vue';

// These must be `Symbol.for()` and not `Symbol`, because in nuxt
// production build this variable declaration appears twice
export const LogTransformKind = Symbol.for('LogTransformKind');
export const VueComponentKind = Symbol.for('VueComponentKind');
export const FormatFuncKind = Symbol.for('FormatFuncKind');
export const PrismaTypeKind = Symbol.for('PrismaTypeKind');

export type IfDefined<T> = T extends undefined ? NonNullable<unknown> : NonNullable<T>;

export interface FormattableSchema<in O = never> {
  [FormatFuncKind]: (value: unknown, opts: O, schema: TSchema) => string;
}
export type SimpleFormattable = FormattableSchema<undefined>;

export type MaybeObjectFormatOptions<O> = Record<keyof O, undefined> extends O ? undefined | Partial<O> : O;

export type FormatOptions<T> = T extends FormattableSchema<infer O> ? O : undefined;
type SchemaFormatFunc<T extends TSchema> = (value: StaticDecode<T>, opts: FormatOptions<T>, schema: T) => string;

type MaybeRequired<C extends boolean, O> = C extends true ? O : Partial<O>;
type CommonModifiers<T extends TSchema> = {
  [LogTransformKind]?: (value: StaticDecode<T>, schema: T) => unknown;
  [VueComponentKind]?: FormatComponent<T>;
  [PrismaTypeKind]?: string;
} & MaybeRequired<
  T extends FormattableSchema ? true : false,
  {
    [FormatFuncKind]: SchemaFormatFunc<T>;
  }
>;
export type FormatComponent<T extends TSchema> = Component<{
  value: StaticDecode<T>;
  schema: T;
  opts: FormatOptions<T>;
}>;

export const createType = <T extends TSchema, M extends Partial<CommonModifiers<T>>>(
  schema: Omit<T, keyof M>,
  modifiers: M,
): T =>
  ({
    ...schema,
    ...modifiers,
  }) as never;
export const inheritFormattable = <F extends TSchema, T extends TSchema>(
  from: F,
  schema: Omit<T, typeof FormatFuncKind>,
  mods: NoInfer<(nestedSchema: FormattableSchema<FormatOptions<F>>) => CommonModifiers<T>>,
): T => (isFormattable(from) ? createType(schema as never, mods(from as never) as never) : schema) as never;

export const createFormatComponent = <T extends TSchema, C extends Component>(
  importComponent: () => Promise<C>,
  render: (component: C) => FormatComponent<T>,
) => {
  let component: FormatComponent<T> | undefined;
  return ((props) => {
    component ??= render(defineAsyncComponent<C>(importComponent));
    return h(component, props);
  }) satisfies FormatComponent<T>;
};

export type MaybeFormatOptions<O> = undefined extends O ? [opts?: O] : [opts: O];
export const format = <T extends TSchema & FormattableSchema>(
  schema: T,
  value: NoInfer<StaticDecode<T>>,
  ...[opts]: NoInfer<MaybeFormatOptions<FormatOptions<T>>>
) => schema[FormatFuncKind](value, opts as never, schema);

export const isFormattable = (schema: TSchema): schema is TSchema & FormattableSchema =>
  FormatFuncKind in schema && !!schema[FormatFuncKind];
export const hasCustomLogTransform = <T extends TSchema>(
  schema: T,
): schema is T & {
  [LogTransformKind]: (value: StaticDecode<T>, schema: T) => unknown;
} => LogTransformKind in schema;
export const hasCustomComponent = (
  schema: TSchema,
): schema is TSchema & {
  [VueComponentKind]: Component<{ schema: unknown; value: unknown; opts: unknown }>;
} => VueComponentKind in schema;

export interface PrismaType extends TSchema {
  [PrismaTypeKind]: string;
}
export const isPrismaType = (schema: TSchema): schema is PrismaType =>
  PrismaTypeKind in schema && typeof schema[PrismaTypeKind] === 'string';
