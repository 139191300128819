import { createType, FormatFuncKind, PrismaTypeKind, type SimpleFormattable } from '../base';
import { type StringOptions, type TString, Type, TypeGuard } from '@sinclair/typebox';

export { type TString } from '@sinclair/typebox';
declare module '@sinclair/typebox' {
  // eslint-disable-next-line  @typescript-eslint/no-shadow, @typescript-eslint/no-empty-interface
  interface TString extends SimpleFormattable {}
}

export const { IsString } = TypeGuard;
export const String = (opts?: StringOptions): TString =>
  createType(Type.String(opts), {
    [FormatFuncKind]: (value) => value,
    [PrismaTypeKind]: 'String',
  });
