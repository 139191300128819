import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { RepaymentScheduleStatus } from './RepaymentScheduleStatus';

export const RepaymentSchedule = PrismaModel(
  {
    name: 'RepaymentSchedule',
    dbSchema: 'backoffice',
    key: ['id'],
    uniques: [['loanId', 'date']],
    description:
      'Repayment plan for a loan. Schedules are created in the moment of disbursement and can be corrected by submitted Invoices.',
  },
  {
    id: T.AutoIncrement(),
    loanId: T.Integer(),
    date: T.LocalDate({ description: 'Invoice date' }),
    number: T.SmallInt(),
    amount: T.Money({ description: 'Total invoice amount' }),
    principalAmount: T.Money(),
    interestAmount: T.Money(),
    penaltyAmount: T.Money(),
    penaltyRebateAmount: T.Money(),
    interestRebateAmount: T.Money(),
    calculatedPenaltyAmount: T.Money(),
    paidAmount: T.Money({
      description: 'Paid amount correspondent to each invoice ',
      default: 0,
    }),
    paymentDate: T.Nullable(T.Date()),
    status: RepaymentScheduleStatus,
    isHiddenForCollection: T.Boolean({ default: false }),
  },
);
