import { T } from '../../typebox';

export const ShopType = T.OurEnum(
  {
    shopee: {},
    tokopedia: {},
    lazada: {},
    tiktok: {},
    blibli: {},
    website: {},
    youtube: {},
    instagram: {},
    facebook: {},
    twitter: { title: 'X (Twitter)' },
  },
  {
    dbSchema: 'public',
  },
);
