import { phone } from '../../utils/formats/phone';
import {
  createFormatComponent,
  createType,
  type FormatComponent,
  FormatFuncKind,
  LogTransformKind,
  PrismaTypeKind,
  type SimpleFormattable,
  VueComponentKind,
} from '../base';
import { Format } from '../formats';
import * as Type from '../vendorType';
import { FormatRegistry, type StringOptions, type TString, TypeGuard } from '@sinclair/typebox';
import { h } from 'vue';

interface StringType<F extends Format> extends TString, SimpleFormattable {
  format: F;
}
const createStringType = <const F extends Format>(
  format: F,
  mods: {
    [FormatFuncKind]: (value: string) => string;
    [LogTransformKind]?: (value: string) => string;
    [VueComponentKind]?: FormatComponent<StringType<F>>;
    [PrismaTypeKind]?: string;
  },
) => {
  FormatRegistry.Set(format, () => true);
  return {
    Type: (opts?: StringOptions): StringType<F> => createType(Type.String({ format, ...opts }), mods),
    Guard: (schema: unknown): schema is StringType<F> => TypeGuard.IsString(schema) && schema.format === format,
  };
};

export type TBase64 = StringType<Format.Base64>;
export const { Type: Base64, Guard: IsBase64 } = createStringType(Format.Base64, {
  [FormatFuncKind]: () => '## base64 ##',
  [LogTransformKind]: () => '## base64 ##',
});

export type TSecret = StringType<Format.Secret>;
export const { Type: Secret, Guard: IsSecret } = createStringType(Format.Secret, {
  [FormatFuncKind]: () => '## hidden value ##',
  [LogTransformKind]: () => '## hidden value ##',
});

export type TEmail = StringType<Format.Email>;
export const { Type: Email, Guard: IsEmail } = createStringType(Format.Email, {
  [FormatFuncKind]: (value) => value,
  [LogTransformKind]: (value) => {
    const [name = '', domain = ''] = value.split('@');
    return [name.length > 2 ? `${name[0]}***${name.at(-1)}` : '**', domain].join('@');
  },
  [PrismaTypeKind]: 'String /// [Email]',
});

export type TUri = StringType<Format.URI>;
export const { Type: Uri, Guard: IsUri } = createStringType(Format.URI, {
  [FormatFuncKind]: (value) => value,
  [PrismaTypeKind]: 'String /// [Uri]',
});

export type TFullPhone = StringType<Format.FullPhone>;
export const { Type: FullPhone, Guard: IsFullPhone } = createStringType(Format.FullPhone, {
  [FormatFuncKind]: (value) => phone.format(value),
  [LogTransformKind]: (value) => (value.length > 5 ? `${value.slice(0, 5)}***` : '**'),
  [PrismaTypeKind]: 'String /// [FullPhone]',
});

export type TNpwp = StringType<Format.npwp>;
export const { Type: Npwp, Guard: IsNpwp } = createStringType(Format.npwp, {
  [FormatFuncKind]: (value) => value,
  [LogTransformKind]: (value) => (value.length > 5 ? `${value.slice(0, 5)}***` : '**'),
  [PrismaTypeKind]: 'String /// [Npwp]',
});

export type TMultiline = StringType<Format.Multiline>;
export const { Type: Multiline, Guard: IsMultiline } = createStringType(Format.Multiline, {
  [FormatFuncKind]: (value) => value,
  [VueComponentKind]: ({ value }) =>
    value.split('\n').map((line, i) => h('span', { key: i }, [i === 0 ? null : h('br'), line])),
  [PrismaTypeKind]: 'String /// [Multiline]',
});

const RichTextFormat = createFormatComponent(
  () => import('../../components/RichText.vue'),
  (Component) => Component as FormatComponent<StringType<Format.RichText>>,
);

export type RichText = StringType<Format.RichText>;
export const { Type: RichText, Guard: IsRichText } = createStringType(Format.RichText, {
  [FormatFuncKind]: (value) => value,
  [VueComponentKind]: RichTextFormat,
  [PrismaTypeKind]: 'String /// [RichText]',
});

export type YearMonth = StringType<Format.YearMonth>;
export const { Type: YearMonth, Guard: IsYearMonth } = createStringType(Format.YearMonth, {
  [FormatFuncKind]: (value) => value,
  [PrismaTypeKind]: 'String /// [YearMonth]',
});

export const Uuid = (opts?: StringOptions): TString =>
  createType(Type.String(opts), { [PrismaTypeKind]: 'String @default(uuid()) @db.Uuid' });

export const IpAddress = (opts?: StringOptions): TString =>
  createType(Type.String(opts), { [PrismaTypeKind]: 'String @db.Inet' });

export const DbGeneratedString = (opts?: StringOptions): TString =>
  createType(Type.String(opts), { [PrismaTypeKind]: 'String @default(dbgenerated())' });
