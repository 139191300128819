import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const UnderwritingPhSalesReport = PrismaModel(
  {
    name: 'UnderwritingPhSalesReport',
    dbSchema: 'backoffice',
    key: ['underwritingId', 'month'],
  },
  {
    underwritingId: T.Integer(),
    month: T.YearMonth({ title: 'Reporting month' }),
    sales: T.Money(),
    cogs: T.Money({ title: 'COGS' }),
    opex: T.Money(),
    payroll: T.Money(),
    rentalAndUtilities: T.Money({ title: 'Rental & Utilities' }),
    otherExpenses: T.Money(),
  },
);
