import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const CreditHistoryLoanHistoricalData = PrismaModel(
  {
    name: 'CreditHistoryLoanHistoricalData',
    dbSchema: 'backoffice',
    key: ['id'],
    description: 'Indonesia. Credit History, historical data',
  },
  {
    id: T.AutoIncrement(),
    referenceDate: T.String(),
    daysPastDue: T.Nullable(T.Integer()),
    overdue: T.Nullable(T.Money()),
    debitBalance: T.Nullable(T.Money()),
    currentMonthRealization: T.Nullable(T.Money()),
    contractStatus: T.Nullable(T.String()),
    creditHistoryLoanContractCode: T.String(),
    creditHistoryId: T.Integer(),
  },
);
