import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const NpwpOcrData = PrismaModel(
  {
    name: 'NpwpOcrData',
    dbSchema: 'public',
    key: ['documentId'],
    description: 'Indonesia only',
  },
  {
    documentId: T.Integer(),
    npwp: T.String(),
    name: T.String(),
    nik: T.String(),
    address: T.String(),
  },
);
