import { createAction } from '@reduxjs/toolkit';

export const updateCompletionByPath = createAction<string>('steps/updateCompletedSteps');

export const updatePhoneNumber = createAction<{ phoneNumber: string; sessionId?: string }>('account/updatePhoneNumber');

export const setAccessToken = createAction<string>('account/setAccessToken');

export const kycAction = {
  decrementAttemps: createAction('kyc/decrementAttemps'),
};
