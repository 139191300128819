import { Decimal } from '../prisma';
import { R } from '../vendor/remeda';
import { unwrapNullable } from './ourType/nullable';
import * as Type from './vendorType';
import { type StaticDecode, type TArray, type TObject, type TSchema, type TUnion, TypeGuard } from '@sinclair/typebox';
import { Value } from '@sinclair/typebox/value';

export type Path<T> = T extends (infer U)[]
  ? `${number}.${Path<U>}`
  : T extends object
    ? {
        [K in keyof T & (string | number)]: K extends string ? `${K}` | `${K}.${Path<T[K]>}` : never;
      }[keyof T & (string | number)]
    : never;

export type FlatPath<T> = T extends (infer U)[]
  ? `${FlatPath<U>}`
  : T extends object
    ? {
        [K in keyof T & (string | number)]: K extends string ? `${K}` | `${K}.${FlatPath<T[K]>}` : never;
      }[keyof T & (string | number)]
    : never;

export type ObjectOnlyPath<T> = T extends object
  ? {
      [K in keyof T & (string | number)]: K extends string ? `${K}` | `${K}.${ObjectOnlyPath<T[K]>}` : never;
    }[keyof T & (string | number)]
  : never;

export type PathForSchema<T extends TSchema> =
  T extends TUnion<infer U>
    ? PathForSchema<U[number]>
    : T extends TArray<infer I>
      ? `${number}` | `${number}.${PathForSchema<I>}`
      : T extends TObject<infer P>
        ? {
            [K in keyof P]: K extends string ? `${K}` | `${K}.${PathForSchema<P[K]>}` : never;
          }[keyof P]
        : never;

export type FlatPathForSchema<T extends TSchema> =
  T extends TUnion<infer U>
    ? ObjectOnlyPathForSchema<U[number]>
    : T extends TArray<infer I>
      ? FlatPathForSchema<I>
      : T extends TObject<infer P>
        ? {
            [K in keyof P]: K extends string ? `${K}` | `${K}.${FlatPathForSchema<P[K]>}` : never;
          }[keyof P]
        : never;

export type ObjectOnlyPathForSchema<T extends TSchema> =
  T extends TUnion<infer U>
    ? ObjectOnlyPathForSchema<U[number]>
    : T extends TObject<infer P>
      ? {
          [K in keyof P]: K extends string ? `${K}` | `${K}.${ObjectOnlyPathForSchema<P[K]>}` : never;
        }[keyof P]
      : never;

type SchemaForKey<T extends TSchema, K extends string> =
  T extends TUnion<infer U> ? SchemaForKey<U[number], K> : T extends TObject<infer Props> ? Props[K] : never;
export type SchemaForPath<T extends TSchema, P extends string> = P extends `${infer K}.${infer R}`
  ? SchemaForPath<SchemaForKey<T, K>, R>
  : SchemaForKey<T, P>;

export const getSchemaPath = (schema: TSchema, path: string) => {
  let s = schema;
  for (const segment of R.stringToPath(path) as string[]) {
    if (TypeGuard.IsArray(s)) {
      s = s.items;
      if (!Number.isNaN(Number.parseInt(segment, 10))) {
        continue;
      }
    }

    if (TypeGuard.IsObject(s)) {
      const nested = s.properties[segment] ?? s.additionalProperties;
      if (typeof nested !== 'object') {
        return null;
      }
      s = nested;
    } else if (TypeGuard.IsUnion(s)) {
      const member = s.anyOf.find(
        (nested) => TypeGuard.IsObject(nested) && typeof nested.properties[segment] === 'object',
      );
      if (!member) {
        return null;
      }

      s = member.properties[segment];
    } else {
      return null;
    }
  }

  return s;
};

export type SchemaFor<O> = O extends StaticDecode<infer S extends TSchema> ? S : never;

export {
  createNullableGuard,
  type TNullable as Nullable,
  type UnwrapNullable,
  unwrapNullable,
} from './ourType/nullable';

export type Money = Decimal;
export const Money = Decimal;

export const sortOrder = Type.Union([Type.Literal('asc'), Type.Literal('desc')]);
export type SortOrder = StaticDecode<typeof sortOrder>;
export const SortOrder: Record<SortOrder, SortOrder> = {
  asc: 'asc',
  desc: 'desc',
};

export const cleanUndefineds = <T>(value: T): T => {
  if (R.isArray(value)) return value.map(cleanUndefineds) as T;

  if (R.isPlainObject(value)) {
    return R.fromEntries(
      R.entries(value as Record<string, unknown>)
        .filter(([, v]) => v !== undefined)
        .map(([k, v]) => [k, cleanUndefineds(v)]),
    ) as T;
  }

  return value;
};

export const stringifyQsJsons = (schema: TSchema, query: unknown) => {
  if (TypeGuard.IsObject(schema) && R.isPlainObject(query)) {
    for (const [key, propSchema] of R.entries(schema.properties)) {
      const value = query[key];
      if ((TypeGuard.IsArray(propSchema) || TypeGuard.IsObject(propSchema)) && typeof value === 'object') {
        query[key] = JSON.stringify(value);
      }
    }
  }
};

const parseQsJsons = (schema: TSchema, query: unknown) => {
  if (TypeGuard.IsObject(schema) && R.isPlainObject(query)) {
    for (const [key, propSchema] of R.entries(schema.properties)) {
      const value = query[key];
      if (
        (TypeGuard.IsObject(propSchema) || TypeGuard.IsArray(propSchema) || TypeGuard.IsRecord(propSchema)) &&
        typeof value === 'string'
      ) {
        query[key] = JSON.parse(value);
      }
    }
  }
};

export const convertQs = (schema: TSchema, query: unknown) => {
  parseQsJsons(schema, query);
  return Value.Convert(schema, query);
};

export const mapSchemaTree = <S extends TSchema, T>(
  _rootSchema: S,
  cb: (opts: { property: string; path: Path<S>; schema: TSchema; children?: T[] }) => T | undefined,
): T[] => {
  const traverse = (schema: TObject, pathPrefix = ''): T[] => {
    const result: T[] = [];

    for (const property of R.keys(schema.properties)) {
      const path = pathPrefix ? `${pathPrefix}.${property}` : property;
      const s = schema.properties[property];
      const nonNullable = s && unwrapNullable(s);

      if (TypeGuard.IsObject(nonNullable)) {
        const item = cb({
          path: path as Path<S>,
          property,
          schema: nonNullable,
          children: traverse(nonNullable, path),
        });
        if (item) {
          result.push(item);
        }
      } else if (nonNullable) {
        const item = cb({
          path: path as Path<S>,
          property,
          schema: nonNullable,
        });
        if (item) {
          result.push(item);
        }
      }
    }

    return result;
  };

  const rootSchema = unwrapNullable(_rootSchema);
  return TypeGuard.IsObject(rootSchema)
    ? traverse(rootSchema)
    : ([cb({ path: '' as Path<S>, property: '', schema: rootSchema })].filter(Boolean) as T[]);
};
