import { JSX } from 'react';
import { Navigate } from 'react-router-dom';
import BusinessInformation from './pages/businessInfo/BusinessInformation';
import CompanyInformation from './pages/businessInfo/CompanyInformation';
import ConnectStores from './pages/ConnectStores/ConnectStores';
import { ContactInformation } from './pages/ContactInformation';
import FinancialData from './pages/financialData/FinancialData';
import IdentityVerification from './pages/identityVerification/IdentityVerification';
import { PhoneStarter } from './pages/phone/PhoneStarter';
import { PhoneVerification } from './pages/phone/PhoneVerification';
import { LayoutProps } from './pages/StepLayout';
import { LoanSimulation } from './pages/LoanSimulation';
import { Finish } from './pages/Finish';
import { KycInfo } from './pages/identityVerification/Ktp/KycInfo';
import { Path } from './routes/path';
import { $Enums } from '@getmo/common/prisma';
import { KycSelfie } from './pages/identityVerification/Ktp/KycSelfie';
import { useBrandSettings } from './hooks/useBrandSettings';
import PhBusinessInformation from './pages/ph/PhBusinessInformation';
import PhCompanyInformation from './pages/ph/PhCompanyInformation';
import PhFinish from './pages/ph/PhFinish';
import PhIdentityVerification from './pages/ph/PhIdentityVerification';
import PhBusinessLocation from './pages/ph/PhBusinessLocation';
import BankStatements from './pages/financialData/BankStatements/index';
import PhSelfie from './pages/ph/PhSelfie';
import { Application } from '@getmo/onboarding/schemas/endpoints/application';
import PhBusinessRegistration from './pages/ph/PhBusinessRegistration';

export interface StepContent {
  name: string;
  path: string;
  element: JSX.Element;
  isPublic?: boolean;
  layoutProps?: LayoutProps;
  condition?: (state: Application) => boolean;
}

export interface Step {
  label: string;
  content: StepContent[];
}

export const steps: Step[] =
  useBrandSettings().region === $Enums.Region.ph
    ? [
        {
          label: 'Account',
          content: [
            {
              name: 'Index',
              path: Path.Index,
              element: <Navigate to={'/account'} replace />,
              isPublic: true,
            },
            {
              name: 'PhoneStarter',
              path: Path.AccountPhoneStarter,
              element: <PhoneStarter />,
              isPublic: true,
              layoutProps: {
                size: 'large',
              },
            },
            {
              name: 'PhoneVerification',
              path: Path.AccountVerify,
              element: <PhoneVerification />,
              isPublic: true,
            },
          ],
        },
        {
          label: 'Email',
          content: [
            {
              name: 'ContactInformation',
              path: Path.Email,
              element: <ContactInformation />,
            },
          ],
        },
        {
          label: 'Business',
          content: [
            {
              name: 'BusinessInformation',
              path: Path.BusinessInformation,
              element: <PhBusinessInformation />,
            },
            {
              name: 'BusinessLocation',
              path: Path.BusinessLocation,
              element: <PhBusinessLocation />,
            },
            {
              name: 'IdentityVerification',
              path: Path.Verification,
              element: <PhIdentityVerification />,
            },
            {
              name: 'VerificationKycSelfie',
              path: Path.VerificationKycSelfie,
              element: <PhSelfie />,
              condition: ({ qualification }) => qualification !== $Enums.OnboardingQualification.highTicket,
            },
            {
              name: 'VerificationKycSelfie',
              path: Path.BusinessRegistration,
              element: <PhBusinessRegistration />,
              condition: ({ qualification }) => qualification === $Enums.OnboardingQualification.highTicket,
            },
            {
              name: 'CompanyInformation',
              path: Path.BusinessCompany,
              element: <PhCompanyInformation />,
            },
          ],
        },
        {
          label: 'Finish',
          content: [
            {
              name: 'Finish',
              path: Path.Finish,
              element: <PhFinish />,
            },
          ],
        },
      ]
    : [
        {
          label: 'Account',
          content: [
            {
              name: 'Index',
              path: Path.Index,
              element: <Navigate to={'/account'} replace />,
              isPublic: true,
            },
            {
              name: 'PhoneStarter',
              path: Path.AccountPhoneStarter,
              element: <PhoneStarter />,
              isPublic: true,
              layoutProps: {
                size: 'large',
              },
            },
            {
              name: 'PhoneVerification',
              path: Path.AccountVerify,
              element: <PhoneVerification />,
              isPublic: true,
            },
          ],
        },
        {
          label: 'Email',
          content: [
            {
              name: 'ContactInformation',
              path: Path.Email,
              element: <ContactInformation />,
            },
          ],
        },
        {
          label: 'Business',
          content: [
            {
              name: 'BusinessInformation',
              path: Path.BusinessInformation,
              element: <BusinessInformation />,
            },
            {
              name: 'CompanyInformation',
              path: Path.BusinessCompany,
              element: <CompanyInformation />,
              condition: ({ legalType }) => legalType === $Enums.LegalType.corporate,
            },
            {
              name: 'ConnectStores',
              path: Path.BusinessStores,
              element: <ConnectStores />,
              condition: ({ typeOfSalesChannel }) => typeOfSalesChannel !== $Enums.SaleChannelType.offline,
            },
          ],
        },
        {
          label: 'Simulation',
          content: [
            {
              name: 'LoanSimulation',
              path: Path.Simulation,
              element: <LoanSimulation />,
            },
          ],
        },
        {
          label: 'Finance',
          content: [
            {
              name: 'Bank statements',
              path: Path.BankStatements,
              element: <BankStatements />,
            },
            {
              name: 'FinancialData',
              path: Path.Financial,
              element: <FinancialData />,
            },
          ],
        },
        {
          label: 'Personal',
          content: [
            {
              name: 'IdentityVerification',
              path: Path.Verification,
              element: <IdentityVerification />,
            },
          ],
        },
        {
          label: 'Verification',
          content: [
            {
              name: 'VerificationKycInfo',
              path: Path.VerificationKycInfo,
              element: <KycInfo />,
              condition: ({ vidaKycStatus }) => vidaKycStatus !== $Enums.KycStatus.success,
            },
            {
              name: 'VerificationKycSelfie',
              path: Path.VerificationKycSelfie,
              element: <KycSelfie />,
              condition: ({ vidaKycStatus }) => vidaKycStatus !== $Enums.KycStatus.success,
            },
          ],
        },
        {
          label: 'Finish',
          content: [
            {
              name: 'Finish',
              path: Path.Finish,
              element: <Finish />,
            },
          ],
        },
      ];
