import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { UnderwritingNegative } from './UnderwritingNegative';
import { UnderwritingStatus } from './UnderwritingStatus';
import { UserRole } from './UserRole';

export const UnderwritingAction = PrismaModel(
  {
    name: 'UnderwritingAction',
    dbSchema: 'backoffice',
    key: ['id'],
    indexes: [['selectedTermsId'], ['underwritingId'], ['employeeId']],
    description:
      'Backoffice statuses and comments. All steps passed by each application. The very first stage "new" is not included.\nIf an application was declined and then got back into the process, "new" stage will be included.',
  },
  {
    id: T.AutoIncrement(),
    underwritingId: T.Integer(),
    date: T.CreatedAt({
      description: 'Date and time when the application got the status',
    }),
    negatives: T.Array(UnderwritingNegative),
    comment: T.RichText(),
    newStatus: T.Nullable(UnderwritingStatus),
    isAutoCheck: T.Boolean({ default: false }),
    employeeId: T.Integer({
      description: 'Employee who transfered the application into the status',
    }),
    employeeRole: UserRole,
    selectedTermsId: T.Nullable(
      T.Integer({ title: 'Terms suggested at the stage' }),
    ),
  },
);
