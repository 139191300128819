import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { FC, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { FullDocument } from '@getmo/onboarding/schemas/models/documents';
import { StepContent } from '../../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../../components/StepSubmitButton/StepSubmitButton';
import { phone } from '../../../utils/phone';
import { yup } from '../../../utils/yup';
import FormikTextInput from '../../components/FormikTextInput';
import { InputEmailIcon } from '../../components/input/icons/InputEmailIcon';
import { InputPhoneIcon } from '../../components/input/icons/InputPhoneIcon';
import { useSnackbar } from '../../components/snackbar/useSnackbar';
import { DocumentFileInput } from '../../components/upload/DocumentFileInput';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useNextStep } from '../../hooks/useNextStep';
import { defaultMimeTypes as defaultMimes } from '../../types/mimeType';
import { useApplication } from '../../../auth/applicationContext';
import { $Enums } from '@getmo/common/prisma';
import { R } from '@getmo/common/vendor/remeda';

interface InitialValues {
  email: string;
  phone: string;
  files: {
    nib: FullDocument[];
    npwpCorporate: FullDocument[];
    sk: FullDocument[];
    trademarkCertificate: FullDocument[];
    businessLicense: FullDocument[];
    companyProfile: FullDocument[];
  };
}

const CompanyInformation: FC = () => {
  const title = <>Company Information</>;
  const subtitle = (
    <>
      Please provide the following essential details to assist us in tailoring the perfect financing solution for your
      business.
    </>
  );

  const { goToNextStep } = useNextStep();
  const { application, updateApplication, setApplication } = useApplication();
  const initialValues = useMemo<InitialValues>(
    () => ({
      email: application.companyContact?.email || '',
      phone: application.companyContact?.phone?.replace(/^\+?62/, '') || '',
      files: {
        nib: application.documents.filter((d) => !d.coownerId && d.type === $Enums.DocumentType.nib),
        npwpCorporate: application.documents.filter(
          (d) => !d.coownerId && d.type === $Enums.DocumentType.npwpCorporate,
        ),
        sk: application.documents.filter((d) => !d.coownerId && d.type === $Enums.DocumentType.sk),
        trademarkCertificate: application.documents.filter(
          (d) => !d.coownerId && d.type === $Enums.DocumentType.trademarkCertificate,
        ),
        businessLicense: application.documents.filter(
          (d) => !d.coownerId && d.type === $Enums.DocumentType.businessPermit,
        ),
        companyProfile: application.documents.filter(
          (d) => !d.coownerId && d.type === $Enums.DocumentType.companyProfile,
        ),
      },
    }),
    [application.companyContact?.email, application.companyContact?.phone, application.documents],
  );

  const validation = Yup.object({
    email: yup.validateEmail().required(),
    phone: yup.validatePhone(),
    files: Yup.object({
      nib: Yup.array().min(1, yup.uploadMinFile(1)),
      npwpCorporate: Yup.array().min(1, yup.uploadMinFile(1)),
      sk: Yup.array().min(1, yup.uploadMinFile(1)),
    }),
  });

  const [isSubmitting, setSubmitting] = useState(false);
  const [initialErrors, setInitialErrors] = useState({});
  const { showError } = useSnackbar();

  const onSubmit = async (values: typeof initialValues) => {
    setInitialErrors({});
    setSubmitting(true);

    try {
      await updateApplication({ companyContact: { ...values, phone: phone.toApiValue(values.phone) } });
      const files = R.values(values.files).flat();
      const uploadIds = files.map((u) => u.id);
      setApplication((a) => ({
        ...a,
        documents: a.documents.filter((d) => !uploadIds.includes(d.id)).concat(files),
      }));
      await goToNextStep();
    } catch (e) {
      const { error, validationErrors } = useApiErrors(e);
      setInitialErrors(validationErrors);
      if (error) {
        showError(error);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <StepContent title={title} subtitle={subtitle} width="medium">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        initialErrors={initialErrors}
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container direction="column" gap={0} pt={0}>
                <Grid item>
                  <DocumentFileInput
                    name="files.nib"
                    label="Company NIB"
                    docType={$Enums.DocumentType.nib}
                    mimeTypes={defaultMimes.nib}
                  />
                </Grid>
                <Grid item>
                  <DocumentFileInput
                    name="files.npwpCorporate"
                    label="Company NPWP"
                    docType={$Enums.DocumentType.npwpCorporate}
                    mimeTypes={defaultMimes.npwpCorporate}
                    extractImageFromPdf="npwp"
                  />
                </Grid>
                <Grid item>
                  <FormikTextInput
                    name="email"
                    type="email"
                    label="Company Email"
                    transform={(v) => v.trim()}
                    InputProps={{ startAdornment: <InputEmailIcon /> }}
                    required
                  />
                </Grid>
                <Grid item sx={{ mb: 1 }}>
                  <FormikTextInput
                    name="phone"
                    type="text"
                    label="Company Phone Number"
                    transform={(v) => phone.prettify(v)}
                    untransform={(v) => phone.depretty(v)}
                    InputProps={{ startAdornment: <InputPhoneIcon /> }}
                    required
                  />
                </Grid>
                <Grid item>
                  <DocumentFileInput
                    name="files.sk"
                    label="Company SK or Akta Notaris (for CV)"
                    docType={$Enums.DocumentType.sk}
                    mimeTypes={defaultMimes.sk}
                  />
                </Grid>
                <Grid item>
                  <DocumentFileInput
                    name="files.trademarkCertificate"
                    label="Trademark Certificate"
                    docType={$Enums.DocumentType.trademarkCertificate}
                    mimeTypes={defaultMimes.trademarkCertificate}
                    required={false}
                  />
                </Grid>
                <Grid item>
                  <DocumentFileInput
                    name="files.businessLicense"
                    label="Business License"
                    subtitle="Example: Export License, PIRT, SIUP, etc."
                    docType={$Enums.DocumentType.businessPermit}
                    mimeTypes={defaultMimes.businessLicense}
                    required={false}
                  />
                </Grid>
                <Grid item>
                  <DocumentFileInput
                    name="files.companyProfile"
                    label="Company Profile"
                    docType={$Enums.DocumentType.companyProfile}
                    mimeTypes={defaultMimes.companyProfile}
                    required={false}
                  />
                </Grid>
                <Grid item display="flex" justifyContent="center" pt={2}>
                  <StepSubmitButton isSubmitting={isSubmitting} />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StepContent>
  );
};

export default CompanyInformation;
