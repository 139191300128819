import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const OnboardingEmergencyContact = PrismaModel(
  {
    name: 'OnboardingEmergencyContact',
    dbSchema: 'public',
    key: ['onboardingId'],
    description: 'Indonesia only',
  },
  {
    onboardingId: T.Integer(),
    name: T.Nullable(T.String()),
    phone: T.Nullable(T.FullPhone()),
    email: T.Nullable(T.Email()),
    relation: T.Nullable(T.String()),
  },
);
