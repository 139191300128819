import { T } from '../../typebox';

export const PhCity = T.OurEnum(
  {
    malabon: {},
    navotas: {},
    caloocan: {},
    valenzuela: {},
    taguig: {},
    paranaque: {},
    mandaluyong: {},
    pasay: {},
    pasig: {},
    makati: {},
    manila: {},
    marikina: {},
    lasPinas: {},
    qc: { title: 'Quezon City' },
    pateros: {},
    sanJuan: {},
    muntinlupa: {},
    bulacan: {},
    rizal: {},
    cavite: {},
    notNcr: { title: 'Not NCR' },
  },
  {
    dbSchema: 'public',
    title: 'Phillipine city',
  },
);
