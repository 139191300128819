export enum Format {
  Base64 = 'base64',
  Binary = 'binary',
  Date = 'date',
  DateTime = 'date-time',
  Email = 'email',
  FullPhone = 'full-phone',
  LocalDateTime = 'local-date-time',
  FixedZoneDateTime = 'fixed-zone-date-time',
  Multiline = 'multiline',
  RichText = 'rich-text',
  Secret = 'secret',
  URI = 'uri',
  YearMonth = 'year-month',
  npwp = 'npwp',
}
