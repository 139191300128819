import { R } from '../../vendor/remeda';
import {
  createFormatComponent,
  createType,
  format,
  type FormatComponent,
  FormatFuncKind,
  type FormatOptions,
  type FormattableSchema,
  type IfDefined,
  inheritFormattable,
  isPrismaType,
  type MaybeObjectFormatOptions,
  PrismaTypeKind,
  VueComponentKind,
} from '../base';
import * as Type from '../vendorType';
import { type SchemaOptions, type TNull, type TSchema, type TUnion, TypeGuard } from '@sinclair/typebox';
import { h } from 'vue';

export interface TNullable<T extends TSchema>
  extends TUnion<[TNull, T]>,
    FormattableSchema<
      MaybeObjectFormatOptions<
        IfDefined<FormatOptions<T>> & {
          nullableFallback?: string;
        }
      >
    > {}

export const Nullable = <T extends TSchema>(schema: T, o?: SchemaOptions) =>
  inheritFormattable<T, TNullable<T>>(
    schema,
    createType(
      Type.Union([Type.Null(), schema], {
        ...R.pick(schema, ['title', 'description']),
        default: null,
        ...o,
      }) as TNullable<T>,
      {
        [PrismaTypeKind]: isPrismaType(schema)
          ? R.splice(schema[PrismaTypeKind].split(' '), 1, 0, ['?']).join(' ')
          : undefined,
      },
    ) satisfies TNullable<T>,
    (s) => ({
      [FormatFuncKind]: (value, opts?: Record<string, unknown>) =>
        value === null
          ? ((opts?.nullableFallback as string | undefined) ?? 'N/A')
          : format(s as never, value as never, opts as never),
      [VueComponentKind]: createFormatComponent(
        () => import('../Format.vue'),
        (Format): FormatComponent<TNullable<T>> =>
          ({ value, schema: nullableSchema, opts }) =>
            value === null
              ? (opts?.nullableFallback ?? 'N/A')
              : h(Format, { value, opts, schema: unwrapNullable(nullableSchema) }),
      ),
    }),
  );

export const IsNullable = (schema: unknown): schema is TNullable<TSchema> =>
  TypeGuard.IsUnion(schema) && schema.anyOf.length === 2 && schema.anyOf.some((s) => TypeGuard.IsNull(s));

export const createNullableGuard =
  <T extends TSchema>(predicate: (s: unknown) => s is T) =>
  (v: unknown): v is T | TNullable<T> =>
    predicate(v) || (TypeGuard.IsUnion(v) && v.anyOf.every((s) => TypeGuard.IsNull(s) || predicate(s)));

export type UnwrapNullable<T extends TSchema> = T extends TNullable<infer S> ? S : T;
export const unwrapNullable = <T extends TSchema>(schema: T) =>
  (IsNullable(schema) ? schema.anyOf.find((s) => !TypeGuard.IsNull(s)) : schema) as UnwrapNullable<T>;
