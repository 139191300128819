import { DF } from '../../vendor/date-fns';
import {
  createFormatComponent,
  createType,
  FormatFuncKind,
  PrismaTypeKind,
  type SimpleFormattable,
  VueComponentKind,
} from '../base';
import { Format } from '../formats';
import * as Type from '../vendorType';
import { tz } from '@getmo/common/vendor/@date-fns/tz';
import { FormatRegistry, type SchemaOptions, type TString, type TTransform, TypeGuard } from '@sinclair/typebox';
import { h } from 'vue';

export const dateFormat = Intl.DateTimeFormat(undefined, { day: '2-digit', month: 'short', year: 'numeric' });
export const dateTimeFormat = Intl.DateTimeFormat('en-US', {
  hourCycle: 'h23',
  dateStyle: 'medium',
  timeStyle: 'short',
});

const DateFormat = createFormatComponent(
  () => import('../../components/DateFormat.vue'),
  (Component) =>
    ({ value }) =>
      h(Component, { date: value, format: 'PP' }),
);

const DateTimeFormat = createFormatComponent(
  () => import('../../components/DateFormat.vue'),
  (Component) =>
    ({ value }) =>
      h(Component, { date: value, format: 'PPpp' }),
);

export interface TDate extends SimpleFormattable, TTransform<TString, Date> {
  format: Format.DateTime;
}
FormatRegistry.Set(Format.DateTime, () => true);
export const IsDate = (schema: unknown): schema is TDate =>
  TypeGuard.IsTransform(schema) && TypeGuard.IsString(schema) && schema.format === Format.DateTime;
const DateType = (opts?: SchemaOptions): TDate =>
  createType(
    Type.Transform(Type.String({ format: Format.DateTime, ...opts }))
      .Decode((v) => {
        const d = new Date(v);
        if (!DF.isValid(d)) throw new Error('Invalid date');
        return d;
      })
      .Encode((v) => v.toISOString()),
    {
      [FormatFuncKind]: (value) => dateTimeFormat.format(value),
      [VueComponentKind]: DateTimeFormat,
      [PrismaTypeKind]: 'DateTime @db.Timestamptz',
    },
  );
export { DateType as Date };

export interface TLocalDate extends SimpleFormattable, TTransform<TString, Date> {
  format: Format.Date;
}
FormatRegistry.Set(Format.Date, () => true);
export const IsLocalDate = (schema: unknown): schema is TLocalDate =>
  TypeGuard.IsTransform(schema) && TypeGuard.IsString(schema) && schema.format === Format.Date;
export const LocalDate = (opts?: SchemaOptions): TLocalDate =>
  createType(
    Type.Transform(Type.String({ format: Format.Date, ...opts }))
      .Decode((v) => {
        const d = DF.parse(v, 'yyyy-MM-dd', 0);
        return d;
      })
      .Encode((v) => DF.format(v, 'yyyy-MM-dd')),
    {
      [FormatFuncKind]: (value) => dateFormat.format(value),
      [VueComponentKind]: DateFormat,
      [PrismaTypeKind]: 'DateTime @db.Date /// [LocalDate]',
    },
  );

export interface TLocalDateTime extends SimpleFormattable, TTransform<TString, Date> {
  format: Format.LocalDateTime;
}
FormatRegistry.Set(Format.LocalDateTime, () => true);
export const IsLocalDateTime = (schema: unknown): schema is TLocalDate =>
  TypeGuard.IsTransform(schema) && TypeGuard.IsString(schema) && schema.format === Format.LocalDateTime;
export const LocalDateTime = (opts?: SchemaOptions): TLocalDateTime =>
  createType(
    Type.Transform(Type.String({ format: Format.LocalDateTime, ...opts }))
      .Decode((v) => {
        const d = new Date(v);
        if (!DF.isValid(d)) throw new Error('Invalid date');
        return d;
      })
      .Encode((v) => DF.format(v, 'yyyy-MM-dd HH:mm:ss')),
    {
      [FormatFuncKind]: (value) => dateTimeFormat.format(value),
      [VueComponentKind]: DateTimeFormat,
    },
  );

export const CreatedAt = (opts?: SchemaOptions): TDate =>
  createType(DateType(opts), { [PrismaTypeKind]: 'DateTime @default(now()) @db.Timestamptz' });
export const UpdatedAt = (opts?: SchemaOptions): TDate =>
  createType(DateType(opts), { [PrismaTypeKind]: 'DateTime @updatedAt @db.Timestamptz' });

export interface TFixedZoneDateTime extends SimpleFormattable, TTransform<TString, Date> {
  format: Format.FixedZoneDateTime;
}
FormatRegistry.Set(Format.FixedZoneDateTime, () => true);
export const IsFixedZoneDateTime = (schema: unknown): schema is TLocalDate =>
  TypeGuard.IsTransform(schema) && TypeGuard.IsString(schema) && schema.format === Format.FixedZoneDateTime;
export const FixedZoneDateTime = (timeZone: string, opts?: SchemaOptions): TFixedZoneDateTime =>
  createType(
    Type.Transform(Type.String({ format: Format.FixedZoneDateTime, ...opts }))
      .Decode((v) => new Date(DF.parseISO(v, { in: tz(timeZone) })))
      .Encode((v) => DF.format(v, 'yyyy-MM-dd HH:mm:ss', { in: tz(timeZone) })),
    {
      [FormatFuncKind]: (value) => dateTimeFormat.format(value),
      [VueComponentKind]: DateFormat,
    },
  );
