import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const LoanHistoryIntervalValues = PrismaModel(
  {
    name: 'LoanHistoryIntervalValues',
    dbSchema: 'analytics',
    key: ['loanId', 'daysOnBooks'],
  },
  {
    loanId: T.Integer(),
    daysOnBooks: T.Integer(),
    cumulativeRepaidAmount: T.Money(),
  },
);
