import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useSnackbar } from '../snackbar/useSnackbar';
import { DeleteFunction, FileInputOptionProps, UploadFunction, useFileUpload } from './base/useFileUpload';
import { defaultMimeTypes } from '../../types/mimeType';
import { isPdfResaved } from '@getmo/common/utils/bankStatements';
import { callApi } from '../../../api';
import { documentDeleteEndpoint, documentUploadEndpoint } from '@getmo/onboarding/schemas/endpoints/documents';
import { $Enums } from '@getmo/common/prisma';
import { useApplication } from '../../../auth/applicationContext';
import { FullDocument } from '@getmo/onboarding/schemas/models/documents';

interface Props extends FileInputOptionProps {
  inputName: string;
  label?: string;
  header?: JSX.Element;
  accountType: $Enums.DocumentType;
}

export const useBankFileInput = ({ inputName, label, header, accountType, ...fileInputOpts }: Props) => {
  const { showError, showWarning, closeSnackbar } = useSnackbar();

  const { setApplication } = useApplication();
  const upload: UploadFunction = async (file): Promise<FullDocument | null> => {
    try {
      const doc = await callApi(documentUploadEndpoint, {
        body: {
          type: accountType,
          file,
        },
      });

      if (!doc.bankStatements?.length && isPdfResaved(doc)) {
        showWarning(
          "You've uploaded a PDF file generated by editing tools\n" +
            'For best results, please provide the original PDF file.',
          {
            autoHideDuration: 15000,
            style: { whiteSpace: 'pre-line' },
            action: (id) => (
              <IconButton size="small" onClick={() => closeSnackbar(id)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            ),
          },
        );
      }

      const files = [doc];
      const uploadIds = files.map((u) => u.id);
      setApplication((a) => ({
        ...a,
        documents: a.documents.filter((d) => !uploadIds.includes(d.id)).concat(files),
      }));

      return doc;
    } catch (e) {
      const { error } = useApiErrors(e);
      showError(error);

      return null;
    }
  };

  const deleteFile: DeleteFunction = async (id) => {
    try {
      await callApi(documentDeleteEndpoint, { params: { documentId: id } });
    } catch (e) {
      const { error } = useApiErrors(e);
      showError(error);
      throw e;
    }
  };

  return useFileUpload({
    inputName: `${inputName}.${accountType}`,
    upload,
    deleteFile,
    label,
    header,
    mimeTypes: defaultMimeTypes.bank,
    multiple: true,
    maxFiles: 100,
    ...fileInputOpts,
  });
};
