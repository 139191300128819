import { T } from '../../typebox';

export const UnderwritingNegative = T.OurEnum({
  // All tickets
  amount: { title: 'Requested amount' },
  creditHistory: { title: 'Credit history is negative' },
  weakFinancialPosition: { title: 'Weak financial position' },
  misleadLender: { title: 'Tried to mislead the lender' },

  // Low tickets
  lowInventory: {},
  rarelyOpened: { title: 'Store is rarely opened' },
  notOwner: { title: 'Store doesnt belong to the applicant' },
  lowScore: {},

  // Qualification
  businessCategory: {},
  location: {},
  revenue: {},
  notReadyForVisit: {},
  age: { title: "Client's age" },

  // Common
  cancelled: { title: 'Client requested to cancel' },
  productCriteria: { title: 'Does not meet the product criteria' },
  other: {},
}, { dbSchema: 'backoffice' });
