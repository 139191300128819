import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { FC, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { StepContent } from '../../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../../components/StepSubmitButton/StepSubmitButton';
import { number } from '../../../utils/number';
import FormikSelectInput, { booleanSelectOptions, Option } from '../../components/FormikSelectInput';
import FormikSliderInput from '../../components/FormikSliderInput';
import FormikTextInput from '../../components/FormikTextInput';
import { InputMoneyIcon } from '../../components/input/icons/InputMoneyIcon';
import { useSnackbar } from '../../components/snackbar/useSnackbar';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useNextStep } from '../../hooks/useNextStep';
import { $Enums } from '@getmo/common/prisma';
import { formatEnumValue } from '@getmo/common/utils/formats';
import { PhBusinessCategory } from '@getmo/common/schemas/models/onboarding';
import { GeoDivisionSelect } from './GeoDivisionSelect';
import { R } from '@getmo/common/vendor/remeda';
import { useApplication } from '../../../auth/applicationContext';
import { Money } from '@getmo/common/typebox';

const businessCategoryOptions: Option[] = R.pipe(
  $Enums.PhBusinessCategory,
  R.values(),
  R.map((v) => ({
    label: formatEnumValue(PhBusinessCategory, v),
    value: v,
  })),
  R.filter((o) => o.value !== $Enums.PhBusinessCategory.onlineSeller),
);

const queryValues = new URLSearchParams(location.search);

const PhBusinessInformation: FC = () => {
  const title = <>Business Information</>;
  const subtitle = (
    <>
      To help us tailor the perfect financing solution for your business, please provide the following details. Your
      answer will assist us in understanding your unique needs and ensuring a seamless loan application process. Rest
      assured, your information is kept strictly confidential.
    </>
  );

  const { application, updateApplication } = useApplication();
  const initialValues = useMemo(
    () => ({
      shopName: application.shopName || '',
      phBusinessCategory: application.phBusinessCategory || ('' as $Enums.PhBusinessCategory),
      revenue: String(application.revenue?.toNumber() || queryValues.get('revenue') || ''),
      loanAmount: String(application.loanAmount?.toNumber() || queryValues.get('loanAmount') || ''),
      loanDuration: application.loanDuration || Number(queryValues.get('loanDuration')) || 3,
      location: application.location || '',
      phCheckingAccount: application.phCheckingAccount?.toString() ?? '',
      phHasAfs: application.phHasAfs?.toString() ?? '',
      phIsBusinessOwner: application.phIsBusinessOwner?.toString() ?? '',
      geoPos: application.geoPos.length === 2 ? application.geoPos : ([] as number[]),
      geoDivision: application.geoDivision,
    }),
    [
      application.shopName,
      application.phBusinessCategory,
      application.revenue,
      application.loanAmount,
      application.loanDuration,
      application.location,
      application.phCheckingAccount,
      application.phHasAfs,
      application.phIsBusinessOwner,
      application.geoPos,
      application.geoDivision,
    ],
  );

  const [initialErrors, setInitialErrors] = useState({});
  const { showError } = useSnackbar();
  const { goToNextStep } = useNextStep();
  const [isSubmitting, setSubmitting] = useState(false);

  const validation = Yup.object({
    shopName: Yup.string().required(),
    phBusinessCategory: Yup.string().required(),
    revenue: Yup.number().required().min(1000),
    loanAmount: Yup.number().required(),
    loanDuration: Yup.number().required(),
    location: Yup.string().required(),
    phCheckingAccount: Yup.string().required(),
    phHasAfs: Yup.string().required(),
    phIsBusinessOwner: Yup.string().required(),
    geoPos: Yup.array(Yup.number()).required(),
    geoDivision: Yup.object().required(),
  });

  const onSubmit = async ({ geoDivision, ...values }: typeof initialValues) => {
    setInitialErrors({});
    setSubmitting(true);

    try {
      await updateApplication({
        ...values,
        geoPos: values.geoPos.length === 2 ? (values.geoPos as [number, number]) : undefined,
        geoDivisionCode: geoDivision?.code,
        phIsBusinessOwner: values.phIsBusinessOwner === 'true',
        phCheckingAccount: values.phCheckingAccount === 'true',
        phHasAfs: values.phHasAfs === 'true',
        loanAmount: new Money(values.loanAmount),
        revenue: new Money(values.revenue),
      });
      await goToNextStep();
    } catch (e) {
      const { error, validationErrors } = useApiErrors(e);
      setInitialErrors(validationErrors);
      if (error) {
        showError(error);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <StepContent title={title} subtitle={subtitle} width="standard">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        initialErrors={initialErrors}
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {({ initialValues, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Grid container direction="column" width="100%">
                <Grid item>
                  <FormikTextInput type="text" name="shopName" label="Shop Name" required />
                </Grid>
                <Grid item width="100%">
                  <FormikSelectInput
                    label="Business category"
                    name="phBusinessCategory"
                    options={businessCategoryOptions}
                    required
                  />
                </Grid>
                <Grid item>
                  <GeoDivisionSelect />
                </Grid>
                <Grid item>
                  <FormikTextInput name="location" type="text" label="Your Business Location - Address" required />
                </Grid>
                <Grid item>
                  <FormikSelectInput
                    name="phIsBusinessOwner"
                    label="Are you an owner of the business?"
                    options={booleanSelectOptions}
                    required
                  />
                </Grid>
                <Grid item>
                  <FormikTextInput
                    type="text"
                    name="revenue"
                    isNumeric
                    label="Monthly Gross Sales"
                    required
                    placeholder={(100000).toLocaleString()}
                    InputProps={{ startAdornment: <InputMoneyIcon /> }}
                    transform={(value) => number.prettifyMoney(value)}
                    untransform={(value) => number.deprettifyMoney(value)}
                  />
                </Grid>
                <Grid item>
                  <FormikTextInput
                    type="text"
                    name="loanAmount"
                    isNumeric
                    label="Desired Loan Amount"
                    required
                    placeholder={(100000).toLocaleString()}
                    InputProps={{ startAdornment: <InputMoneyIcon /> }}
                    transform={(value) => number.prettifyMoney(value)}
                    untransform={(value) => number.deprettifyMoney(value)}
                  />
                </Grid>
                <Grid item>
                  <FormikSliderInput
                    name="loanDuration"
                    label="Desired Loan Duration"
                    required
                    initialValue={initialValues.loanDuration}
                    valueLabel={(value) => `${value} Month${value > 1 ? 's' : ''}`}
                    min={2}
                    max={12}
                  />
                </Grid>
                <Grid item>
                  <FormikSelectInput
                    name="phCheckingAccount"
                    label="Do you have checking account under your name?"
                    options={booleanSelectOptions}
                    required
                  />
                </Grid>
                <Grid item>
                  <FormikSelectInput
                    name="phHasAfs"
                    label="Do you have ITR/AFS for 2023?"
                    options={booleanSelectOptions}
                    required
                  />
                </Grid>
                <Grid item display="flex" justifyContent="center" py={3}>
                  <StepSubmitButton isSubmitting={isSubmitting} />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StepContent>
  );
};

export default PhBusinessInformation;
