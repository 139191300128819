import { log } from '../../utils/log';
import { R } from '../../vendor/remeda';
import { createType, LogTransformKind } from '../base';
import { type ObjectOptions, type TObject, type TSchema, Type, TypeGuard } from '@getmo/common/vendor/typebox';

export { type TObject } from '@getmo/common/vendor/typebox';

export const { IsObject } = TypeGuard;
const ObjectType = <T extends Record<string, TSchema>>(properties: T, opts?: ObjectOptions): TObject<T> =>
  createType(Type.Object(properties, opts), {
    [LogTransformKind]: (value, schema) => {
      if (!R.isPlainObject(value)) {
        return value;
      }

      const result: Record<string, unknown> = {};
      for (const [key, v] of R.entries(value)) {
        const s = schema.properties[key];
        result[key] = s ? log.cleanExtraValue(s, v) : v;
      }

      return result;
    },
  });
export { ObjectType as Object };
