import { T } from '../../typebox';

export const FieldVisitReputation = T.OurEnum(
  {
    positive: {},
    negative: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
