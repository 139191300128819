import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { Gender } from './Gender';
import { PhPersonalIdType } from './PhPersonalIdType';

export const Person = PrismaModel(
  {
    name: 'Person',
    dbSchema: 'public',
    key: ['id'],
    indexes: [['personalIdNumber'], ['phone']],
    description: 'Verified during KYC applicant data',
  },
  {
    id: T.AutoIncrement(),
    name: T.Nullable(T.String()),
    phone: T.FullPhone(),
    email: T.Nullable(T.Email()),
    personalIdNumber: T.Nullable(T.String({ title: 'Personal ID' })),
    phPersonalIdType: T.Nullable(PhPersonalIdType),
    dob: T.Nullable(T.LocalDate({ title: 'Date of birth' })),
    gender: T.Nullable(Gender),
    photo: T.Nullable(T.String()),
    photoId: T.Nullable(T.String()),
    kyc: T.Nullable(T.Boolean()),
  },
);
