import { Kind, type TSchema, TypeRegistry } from '@sinclair/typebox';

const customTypeKind = 'Custom';
const CustomPredicate = 'Custom predicate';
export interface CustomType<T> extends TSchema {
  [Kind]: typeof customTypeKind;
  [CustomPredicate]: (v: unknown) => boolean;
  static: T;
}
export const CustomType = <T>(base: TSchema, predicate: (v: unknown) => boolean): CustomType<T> =>
  ({
    ...base,
    [Kind]: customTypeKind,
    [CustomPredicate]: predicate,
  }) as never;
TypeRegistry.Set<CustomType<TSchema>>(customTypeKind, (schema, value) => schema[CustomPredicate](value));
