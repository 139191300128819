import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const ShopProduct = PrismaModel(
  {
    name: 'ShopProduct',
    dbSchema: 'public',
    key: ['id'],
    uniques: [['externalId', 'connectionId']],
    indexes: [['price']],
    description: "Client's product loaded from a marketplace connection",
  },
  {
    id: T.AutoIncrement(),
    category: T.Nullable(T.String()),
    name: T.String(),
    photo: T.Nullable(T.String()),
    article: T.String(),
    stock: T.Integer(),
    price: T.Decimal(),
    oldPrice: T.Nullable(T.Decimal()),
    externalId: T.String(),
    connectionId: T.Integer(),
  },
);
