import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { Region } from './Region';
import { RepaymentSource } from './RepaymentSource';

export const Repayment = PrismaModel(
  {
    name: 'Repayment',
    dbSchema: 'backoffice',
    key: ['id'],
    uniques: [['source', 'externalId']],
    description: 'Income money transaction',
  },
  {
    id: T.AutoIncrement(),
    createdAt: T.CreatedAt({ description: 'Date and time of record creation' }),
    phone: T.Nullable(T.FullPhone()),
    date: T.Date({ description: 'Transaction date' }),
    amount: T.Money({
      description:
        'Total amount paid in one transaction (may include payments for several invoices)',
    }),
    email: T.Nullable(T.String()),
    region: Region,
    source: RepaymentSource,
    externalId: T.Nullable(T.String()),
    description: T.String({ default: '' }),
    isBeforeInstamoney: T.Boolean({
      description:
        'Repayment loaded from Payment Data spreadsheet but missing in Instamoney',
      default: false,
    }),
    vaNumber: T.String(),
  },
);
