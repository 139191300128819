import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { StepContent } from '../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../components/StepSubmitButton/StepSubmitButton';
import FormikTextInput from '../components/FormikTextInput';
import { useSnackbar } from '../components/snackbar/useSnackbar';
import { useApiErrors } from '../hooks/useApiErrors';
import { useNextStep } from '../hooks/useNextStep';
import { yup } from '../../utils/yup';
import { useBrandSettings } from '../hooks/useBrandSettings';
import { useApplication } from '../../auth/applicationContext';

interface InitialValues {
  name: string;
  email: string;
}

export const ContactInformation = () => {
  const title = [<>Please fill out your</>, <>contact information below</>];
  const subtitle = (
    <>
      <Box>{`Rest assured, we won't send any advertising.`}</Box>
      <Box>{`We'll only send you important updates and information.`}</Box>
    </>
  );

  const { application, updateApplication } = useApplication();

  const initialValues: InitialValues = useMemo(
    () => ({ name: application.name ?? '', email: application.email ?? '' }),
    [application.name, application.email],
  );
  const [initialErrors, setInitialErrors] = useState<Partial<InitialValues>>({});
  const [submitting, setSubmitting] = useState(false);

  const validation = Yup.object({
    name: Yup.string().required(),
    email: yup.validateEmail().required(),
  });

  const { showError } = useSnackbar();
  const { goToNextStep } = useNextStep();
  const { source } = useBrandSettings();

  const onSubmit = async (values: InitialValues) => {
    setInitialErrors({});
    setSubmitting(true);

    try {
      await updateApplication({
        ...values,
        leadSource: source,
      });
      await goToNextStep();
    } catch (e) {
      const { error, validationErrors } = useApiErrors(e);
      setInitialErrors(validationErrors);
      if (error) {
        showError(error);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <StepContent title={title} subtitle={subtitle} width="small">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        initialErrors={initialErrors}
        onSubmit={onSubmit}
        validationSchema={validation}
      >
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container direction="column">
                <Grid item>
                  <FormikTextInput type="text" name="name" label="Full Name" required />
                </Grid>
                <Grid item>
                  <FormikTextInput
                    type="text"
                    name="email"
                    label="Email"
                    placeholder="example@gmail.com"
                    required
                    transform={(v) => v.trim()}
                  />
                </Grid>
                <Grid item display="flex" justifyContent="center" py={3}>
                  <StepSubmitButton isSubmitting={submitting} />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StepContent>
  );
};
