import { createType, FormatFuncKind, PrismaTypeKind, type SimpleFormattable } from '../base';
import { type NumberOptions, type TNumber, Type, TypeGuard } from '@sinclair/typebox';

export const numberFormatter = Intl.NumberFormat('en-US', { style: 'decimal', useGrouping: 'min2' });

export { type TNumber as TInteger, type TNumber } from '@sinclair/typebox';
declare module '@sinclair/typebox' {
  // eslint-disable-next-line  @typescript-eslint/no-shadow, @typescript-eslint/no-empty-interface
  interface TNumber extends SimpleFormattable {}
  // eslint-disable-next-line  @typescript-eslint/no-empty-interface
  interface TInteger extends SimpleFormattable {}
}

export const { IsNumber } = TypeGuard;
export const Number = (opts?: NumberOptions): TNumber =>
  createType(Type.Number(opts), {
    [FormatFuncKind]: (value) => numberFormatter.format(value),
    [PrismaTypeKind]: 'Float',
  });

export const { IsInteger } = TypeGuard;
// TODO: replace with true Integer type
export const Integer = (opts?: NumberOptions): TNumber =>
  createType(Type.Number(opts), {
    [FormatFuncKind]: (value) => numberFormatter.format(value),
    [PrismaTypeKind]: 'Int',
  });
