import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const UnderwritingNote = PrismaModel(
  {
    name: 'UnderwritingNote',
    dbSchema: 'backoffice',
    key: ['id'],
    indexes: [['underwritingId'], ['employeeId']],
    description: "Additional comment for an underwriting form's value",
  },
  {
    id: T.AutoIncrement(),
    underwritingId: T.Integer(),
    key: T.String(),
    date: T.CreatedAt(),
    text: T.Multiline(),
    employeeId: T.Integer(),
  },
);
