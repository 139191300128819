import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const UnderwritingFinancialData = PrismaModel(
  {
    name: 'UnderwritingFinancialData',
    dbSchema: 'backoffice',
    key: ['underwritingId'],
    description: 'Indonesia only',
  },
  {
    underwritingId: T.Integer(),
    previousYearRevenue: T.Nullable(
      T.Money({ title: 'Revenue for previous year' }),
    ),
    currentYearRevenue: T.Nullable(
      T.Money({ title: 'Revenue for current year' }),
    ),
    grossMargin: T.Nullable(T.Percent({ title: 'Gross margin' })),
    operatingMargin: T.Nullable(T.Percent({ title: 'Operating margin' })),
    avgMonthlyRevenue6Months: T.Nullable(
      T.Money({ title: 'Average monthly revenue for 6 months' }),
    ),
    avgMonthlyRevenue12Months: T.Nullable(
      T.Money({ title: 'Average monthly revenue for 12 months' }),
    ),
    avgTransactions6Months: T.Nullable(
      T.Integer({ title: 'Average transactions for 6 months' }),
    ),
    monthsWithZeroRevenue12Months: T.Nullable(
      T.Integer({ title: 'Number of months with zero Revenue (12 months)' }),
    ),
  },
);
