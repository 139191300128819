import { T } from '../../typebox';

export const InvoiceAdditionType = T.OurEnum(
  {
    prepayment: {},
    penalty: {},
    rebate: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
