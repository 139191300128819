import { StaticDecode, Value } from '@getmo/common/vendor/typebox';
import {
  createContext,
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import {
  Application,
  applicationGetEndpoint,
  applicationUpdateEndpoint,
} from '@getmo/onboarding/schemas/endpoints/application';
import { callApi } from '../api';
import { R } from '@getmo/common/vendor/remeda';

const ApplicationContext = createContext<
  | undefined
  | {
      application: Application;
      setApplication: Dispatch<SetStateAction<Application>>;
      refreshApplication: () => Promise<Application>;
      updateApplication: (data: StaticDecode<typeof applicationUpdateEndpoint.body>) => Promise<void>;
      applicationRef: MutableRefObject<Application>;
    }
>(undefined);

const emptyApplication = Value.Decode(Application, Value.Cast(Application, {}));

export const ApplicationProvider = ({ children }: { children?: ReactNode }) => {
  const [application, setApplicationRaw] = useState(emptyApplication);
  const applicationRef = useRef(application);

  const setApplication: typeof setApplicationRaw = useCallback(
    (data) =>
      setApplicationRaw((currentValue) => {
        const newValue = typeof data === 'function' ? data(currentValue) : data;
        applicationRef.current = newValue;
        return newValue;
      }),
    [setApplicationRaw],
  );

  const updateApplication = useCallback(
    async (data: StaticDecode<typeof applicationUpdateEndpoint.body>) => {
      const res = await callApi(applicationUpdateEndpoint, { body: data });
      setApplication((a) => ({
        ...a,
        ...R.omitBy(data, (v) => v === undefined),
        qualification: res.qualification,
        vidaKycStatus: res.vidaKycStatus,
        geoDivision: res.geoDivision,
      }));
    },
    [setApplication],
  );

  const refreshApplication = useCallback(async () => {
    const res = await callApi(applicationGetEndpoint, {});
    setApplication(res);
    return res;
  }, [setApplication]);

  const value = { application, updateApplication, setApplication, refreshApplication, applicationRef };

  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>;
};

export const useApplication = () => {
  const context = useContext(ApplicationContext);
  if (!context) throw new Error();
  return context;
};
