import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const DisbursementState = PrismaModel(
  {
    name: 'DisbursementState',
    dbSchema: 'backoffice',
    key: ['underwritingId'],
    description: 'Pre-disbursement process state',
  },
  {
    underwritingId: T.Integer(),
    destAccountType: T.String({ default: '' }),
    destAccountNumber: T.String({ default: '' }),
    plannedDate: T.LocalDate(),
    slackThreadId: T.Nullable(T.String()),
    loanAgreementNumber: T.Unique(T.Nullable(T.String())),
    signature: T.Nullable(T.String()),
    disbursementRequested: T.Boolean({ default: false }),
  },
);
