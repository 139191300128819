import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const UnderwritingContractorsData = PrismaModel(
  {
    name: 'UnderwritingContractorsData',
    dbSchema: 'backoffice',
    key: ['underwritingId'],
    description: 'Indonesia only',
  },
  {
    underwritingId: T.Integer(),
    avgCustomers6Months: T.Nullable(
      T.Number({
        title: 'Average number of customers for 6 months',
        unit: 'pcs',
      }),
    ),
    mainCustomerShare6Months: T.Nullable(
      T.Percent({ title: 'Share of the main customer for 6 months' }),
    ),
    avgSuppliers6Months: T.Nullable(
      T.Number({
        title: 'Average number of suppliers for 6 months',
        unit: 'pcs',
      }),
    ),
    mainSupplierShare6Months: T.Nullable(
      T.Percent({ title: 'Share of the main supplier for 6 months' }),
    ),
  },
);
