import { T } from '../../typebox';

export const MessageDirection = T.OurEnum(
  {
    inbound: {},
    outbound: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
