import { $Enums } from '@getmo/common/prisma';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Box, Chip, Typography } from '@mui/material';
import { Formik } from 'formik';
import { FC, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Image } from '../../../../components/Image';
import { StepContent } from '../../../../components/StepContent/StepContent';
import { yup } from '../../../../utils/yup';
import { useNextStep } from '../../../hooks/useNextStep';
import Bca from './../../../../assets/banks/bca-logo.svg';
import Bni from './../../../../assets/banks/bni-logo.png';
import Bri from './../../../../assets/banks/bri-logo.png';
import cimbNiaga from './../../../../assets/banks/cimbNiaga-logo.png';
import Mandiri from './../../../../assets/banks/mandiri-logo.png';
import Ocbc from './../../../../assets/banks/ocbc-logo.png';
import Sea from './../../../../assets/banks/sea-logo.svg';
import { BankDocuments } from './BankDocuments';
import { PaperCard } from './PaperCard';
import { useApplication } from '../../../../auth/applicationContext';
import { FullDocument } from '@getmo/onboarding/schemas/models/documents';

export type BankStatementsInitialValues = {
  banks: {
    bankStatementCorporate: FullDocument[];
    bankStatementPersonal: FullDocument[];
  };
};

const BankStatements: FC = () => {
  const title = <>Bank Statements</>;
  const subtitle = (
    <>
      Speed up your loan request processing time by uploading your recent bank&nbsp;statements <br /> Rest assured your
      data is safe with us.
    </>
  );

  const { application } = useApplication();

  const initialValues = useMemo(
    () => ({
      banks: {
        [$Enums.DocumentType.bankStatementCorporate]: application.documents.filter(
          (d) => d.type === $Enums.DocumentType.bankStatementCorporate,
        ),
        [$Enums.DocumentType.bankStatementPersonal]: application.documents.filter(
          (d) => d.type === $Enums.DocumentType.bankStatementPersonal,
        ),
      },
    }),
    [application.id],
  );

  const [initialErrors, setInitialErrors] = useState({});

  const validation = Yup.object({
    banks: Yup.object().test({
      message: yup.uploadMinFile(1),
      test: (v) => {
        const values = v as BankStatementsInitialValues['banks'];
        if (application.legalType === $Enums.LegalType.corporate) {
          return !!values.bankStatementCorporate.length;
        }
        return !!values.bankStatementPersonal.length;
      },
    }),
  });

  const { goToNextStep } = useNextStep();

  const onSubmit = () => {
    setInitialErrors({});
    goToNextStep();
  };

  return (
    <StepContent title={title} subtitle={subtitle} width="large">
      <Box display="flex" flexDirection="column">
        <Box mb={4} display="flex" gap={2} alignItems="center" justifyContent="space-evenly" flexWrap="wrap">
          <Image src={Bni} height={20} display="inline-block" />
          <Image src={Mandiri} height={20} display="inline-block" />
          <Image src={Bca} height={20} display="inline-block" />
          <Image src={Bri} height={20} display="inline-block" />
          <Image src={Ocbc} height={20} display="inline-block" />
          <Image src={cimbNiaga} height={20} display="inline-block" />
          <Image src={Sea} height={20} display="inline-block" />
          <Chip
            variant="outlined"
            icon={<AccountBalanceIcon sx={{ fontSize: '1.25rem' }} />}
            label="Other"
            sx={{ border: 'none', height: '20px' }}
          />
        </Box>
        <Box mb={4}>
          <PaperCard title="1. Download Your Bank Statements">
            <Typography color="text.primary">
              &#8226; Log in to your online banking website or mobile banking app.
            </Typography>
            <Typography color="text.primary">
              &#8226; Navigate to <b>Account Information</b> or <b>e-Statements</b>.
            </Typography>
            <Typography color="text.primary">
              &#8226; Select the desired period, and download your e-statement in <b>PDF format</b>.
            </Typography>
          </PaperCard>
        </Box>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          initialErrors={initialErrors}
          validationSchema={validation}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, values, isValidating, errors, touched }) => {
            return (
              <BankDocuments
                inputName="banks"
                values={values}
                errors={errors}
                touched={touched}
                legalType={application.legalType}
                isValidating={isValidating}
                onSubmit={handleSubmit}
              />
            );
          }}
        </Formik>
      </Box>
    </StepContent>
  );
};

export default BankStatements;
