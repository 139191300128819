import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { ShopConnectionStatus } from './ShopConnectionStatus';

export const ShopConnection = PrismaModel(
  {
    name: 'ShopConnection',
    dbSchema: 'public',
    key: ['saleChannelId'],
    description: 'Access data for a connected marketplace',
  },
  {
    saleChannelId: T.Integer(),
    createdAt: T.CreatedAt(),
    updatedAt: T.UpdatedAt(),
    lastSyncAt: T.Nullable(T.Date()),
    status: ShopConnectionStatus,
    shopId: T.Nullable(T.Integer()),
    shopName: T.Nullable(T.String()),
    accessToken: T.Nullable(T.String()),
    refreshToken: T.Nullable(T.String()),
    tokenExpiresAt: T.Nullable(T.Date()),
    refreshTokenExpiresAt: T.Nullable(T.Date()),
  },
);
