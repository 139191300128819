import { Onboarding } from './onboarding';
import * as DB from '@getmo/common/prisma/typebox';
import * as DBRel from '@getmo/common/prisma/typebox/relations';
import { ClientModel, ClientRelation, type StaticDecode,WithRelations } from '@getmo/common/typebox';

export { DocumentType } from '@getmo/common/prisma/typebox';

export const Document = ClientModel(DB.Document, ['id', 'type', 'fileName', 'coownerId', 'pdfCreator', 'pdfProducer']);
export const BankStatement = ClientModel(DB.BankStatement, [
  'bank',
  'startDate',
  'endDate',
  'accountNumber',
  'currency',
]);

export const RelBankStatementDocument = ClientRelation(DBRel.BankStatementDocument, BankStatement, Document);
export const RelDocumentOnboarding = ClientRelation(DBRel.DocumentOnboarding, Document, Onboarding);

export const FullDocument = WithRelations(Document, RelBankStatementDocument.rev);
export type FullDocument = StaticDecode<typeof FullDocument>;
