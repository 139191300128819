import { createEndpoint } from '@getmo/common/endpoint';
import * as DB from '@getmo/common/prisma/typebox';
import { T } from '@getmo/common/typebox';
import { type StaticDecode } from '@getmo/common/vendor/typebox';

export type Shop = StaticDecode<typeof shop>;
const shop = T.Object({
  id: T.Number(),
  isSynced: T.Boolean(),
  link: T.String(),
  type: DB.ShopType,
});

export type ShopData = StaticDecode<typeof shopData>;
const shopData = T.Object({
  type: DB.ShopType,
  link: T.String(),
});

export type ShopSyncLink = StaticDecode<typeof shopSyncLink>;
const shopSyncLink = T.Object({
  link: T.String(),
  type: T.Nullable(DB.ShopType),
  isSynced: T.Boolean(),
  syncLink: T.String(),
});

export const shopCreateEndpoint = createEndpoint({
  method: 'post',
  path: '/user/data/shops',
  body: shopData,
  response: shop,
});

export const shopUpdateEndpoint = createEndpoint({
  method: 'patch',
  path: '/user/data/shops/:shopId',
  params: T.Object({
    shopId: T.Number(),
  }),
  body: shopData,
  response: shop,
});

export const shopDeleteEndpoint = createEndpoint({
  method: 'delete',
  path: '/user/data/shops/:shopId',
  params: T.Object({
    shopId: T.Number(),
  }),
  response: T.Object({}),
});

export const shopSyncEndpoint = createEndpoint({
  method: 'get',
  path: '/user/data/shops/:saleChannelId/sync',
  params: T.Object({
    saleChannelId: T.Number(),
  }),
  response: shopSyncLink,
});

export const shopeeCallbackEndpoint = createEndpoint({
  method: 'get',
  path: '/shops/callback/shopee/:saleChannelId',
  params: T.Object({
    saleChannelId: T.Number(),
  }),
  qs: T.Object({
    refererHost: T.Optional(T.String()),
    code: T.String(),
    shop_id: T.Number(),
  }),
  response: T.Void(),
});

export const lazadaCallbackEndpoint = createEndpoint({
  method: 'get',
  path: '/shops/callback/lazada/:saleChannelId',
  params: T.Object({
    saleChannelId: T.Number(),
  }),
  qs: T.Object({
    refererHost: T.Optional(T.String()),
    code: T.String(),
  }),
  response: T.Void(),
});
