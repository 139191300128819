import { createType, FormatFuncKind, type FormattableSchema, type MaybeObjectFormatOptions } from '../base';
import { type TNull, type TSchema, Type, TypeGuard } from '@sinclair/typebox';
import { type SchemaOptions } from 'type-fest';

export { type TNull } from '@sinclair/typebox';
declare module '@sinclair/typebox' {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  interface TNull
    extends TSchema,
      FormattableSchema<
        MaybeObjectFormatOptions<{
          fallback?: string;
        }>
      > {}
}

export const { IsNull } = TypeGuard;
export const Null = (opts?: SchemaOptions): TNull =>
  createType(Type.Null(opts), {
    [FormatFuncKind]: (_, { fallback = 'N/A' } = {}) => fallback,
  });
