import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const PhBank = PrismaModel(
  {
    name: 'PhBank',
    dbSchema: 'public',
    key: ['id'],
  },
  {
    id: T.Integer(),
    title: T.String(),
    createdAt: T.CreatedAt(),
    updatedAt: T.UpdatedAt(),
  },
);
