import { T } from '../../typebox';

export const PaymentStatus = T.OurEnum(
  {
    success: {},
    failure: {},
    pending: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
