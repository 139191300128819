import { log } from '../../utils/log';
import { R } from '../../vendor/remeda';
import {
  createType,
  format,
  FormatFuncKind,
  type FormatOptions,
  type FormattableSchema,
  type IfDefined,
  inheritFormattable,
  isPrismaType,
  LogTransformKind,
  type MaybeObjectFormatOptions,
  PrismaTypeKind,
} from '../base';
import { type SchemaOptions, type TArray, type TSchema, Type, TypeGuard } from '@sinclair/typebox';

export { type TArray } from '@sinclair/typebox';
declare module '@sinclair/typebox' {
  // eslint-disable-next-line  @typescript-eslint/no-shadow
  interface TArray<T extends TSchema>
    extends TSchema,
      FormattableSchema<
        MaybeObjectFormatOptions<
          IfDefined<FormatOptions<T>> & {
            arraySeparator?: string;
          }
        >
      > {}
}

export const { IsArray } = TypeGuard;
const TypeArray = <T extends TSchema>(itemSchema: T, o?: SchemaOptions) =>
  inheritFormattable<T, TArray<T>>(
    itemSchema,
    createType(Type.Array(itemSchema, { ...R.pick(itemSchema, ['title', 'description']), ...o }), {
      [LogTransformKind]: (value, schema) => {
        if (!Array.isArray(value)) {
          return value;
        }
        return value.map((v) => log.cleanExtraValue(schema.items, v));
      },
      [PrismaTypeKind]: isPrismaType(itemSchema)
        ? R.splice(itemSchema[PrismaTypeKind].split(' '), 1, 0, ['[]', '@default([])']).join(' ')
        : undefined,
    }) satisfies TArray<T>,
    (s) => ({
      [FormatFuncKind]: (value, opts?: unknown) =>
        value
          .map((item) => format(s as never, item as never, opts as never))
          .join((opts as { arraySeparator: string } | undefined)?.arraySeparator ?? ', '),
    }),
  );
export { TypeArray as Array };
