import { T } from '../../typebox';

export const UnderwritingPhInventoryAssets = T.OurEnum(
  {
    adequate: { title: 'Visible and Adequate' },
    inadequate: { title: 'Inadequate/Not Visible/Not Applicable' },
  },
  {
    dbSchema: 'backoffice',
  },
);
