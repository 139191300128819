import { log } from '../../utils/log';
import { createType, LogTransformKind } from '../base';
import { type SchemaOptions, type TSchema, type TUnion, Type, TypeGuard, Value } from '@getmo/common/vendor/typebox';

export { type TUnion } from '@getmo/common/vendor/typebox';

export const { IsUnion } = TypeGuard;
const UnionType = <const T extends TSchema[]>(properties: T, opts?: SchemaOptions): TUnion<T> =>
  createType(Type.Union(properties, opts), {
    [LogTransformKind]: (value, schema) => {
      for (const inner of schema.anyOf) {
        if (Value.Check(inner, value)) {
          return log.cleanExtraValue(inner, value);
        }
      }
      return value;
    },
  });
export { UnionType as Union };
