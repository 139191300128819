import axios, { AxiosError, AxiosHeaders, CanceledError } from 'axios';
import { tokenStorage } from './auth/tokenStorage';
import { StaticDecode, TSchema, Value } from '@getmo/common/vendor/typebox';
import { formatPath } from '@getmo/common/utils/apiCommon';
import { Endpoint as TEndpoint } from '@getmo/common/endpoint';
import * as Sentry from '@sentry/react';
import { cleanUndefineds } from '@getmo/common/typebox';
import { R } from '@getmo/common/vendor/remeda';
import { applicationGetEndpoint } from '@getmo/onboarding/schemas/endpoints/application';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

export const setAuthorization = (token: string) => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};

export const removeAuthorization = () => {
  axios.defaults.headers.common['Authorization'] = null;
};

export const hasAuthorization = (): boolean => !!axios.defaults.headers.common['Authorization'];

axios.interceptors.response.use(
  (response) => (response.status === 204 ? undefined : response.data),
  async (e: AxiosError): Promise<ErrorResponse | CanceledError<void>> => {
    const response = e?.response?.data;
    const status = e.response?.status || 200;

    if ([AxiosError.ERR_NETWORK, AxiosError.ETIMEDOUT, AxiosError.ECONNABORTED].includes(e.code as string)) {
      (e as unknown as { fingerprint: string[] }).fingerprint = ['network-error'];
      (e as unknown as { level: Sentry.SeverityLevel }).level = 'warning';
    } else if (e.response?.status) {
      (e as unknown as { level: Sentry.SeverityLevel }).level = 'warning';
    }

    if (status === 401) {
      tokenStorage.deleteAccess();
      window.location.href = '/';
      return Promise.reject(new ErrorResponse(e, status, response as MessageResponse));
    }

    if (status === 400 && e.config?.url === applicationGetEndpoint.path) {
      tokenStorage.deleteAccess();
      window.location.href = '/';
    }

    if ([400, 403].includes(status)) {
      return Promise.reject(new ErrorResponse(e, status, response as AnyErrorResponse));
    }

    if (e instanceof CanceledError) {
      return Promise.reject(e);
    }

    Sentry.captureException(e);

    return Promise.reject(new ExceptionResponse(e, status, { message: 'Oops! Something went wrong.' }));
  },
);

type IfNotEmpty<K extends string, T extends TSchema> = [StaticDecode<T>] extends [never]
  ? { [key in K]?: never }
  : unknown extends StaticDecode<T>
    ? { [key in K]?: never }
    : { [key in K]: StaticDecode<T> };
export const callApi = async <E extends TEndpoint<TSchema, TSchema, TSchema, TSchema>>(
  endpoint: E,
  {
    signal,
    headers,
    params,
    body,
    qs,
  }: IfNotEmpty<'params', E['params']> &
    IfNotEmpty<'body', E['body']> &
    IfNotEmpty<'qs', E['qs']> & {
      headers?: AxiosHeaders;
      signal?: AbortSignal;
    },
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let bodyData: any = Value.Encode(endpoint.body, cleanUndefineds(body));
  if (endpoint.consumes === 'multipart/form-data') {
    const form = new FormData();
    for (const [k, v] of R.entries(bodyData)) {
      if (v instanceof File) {
        form.append(k, v);
      } else {
        form.append(k, new Blob([JSON.stringify(v)], { type: 'application/json' }), '');
      }
    }
    bodyData = form;
  }
  if (endpoint.consumes === 'application/x-www-form-urlencoded') {
    bodyData = new URLSearchParams(bodyData);
  }

  const response = await axios.request({
    url: formatPath('', endpoint, params),
    method: endpoint.method,
    data: bodyData,
    params: qs,
    signal,
    headers: {
      'Content-Type': endpoint.consumes,
      ...headers,
    },
  });

  return Value.Decode<E['response']>(endpoint.response, response);
};

export type MessageResponse = { message: string };
export type ValidationErrorResponse = Record<string, string[]>;
export type AnyErrorResponse = MessageResponse | ValidationErrorResponse;

export class ErrorResponse extends Error {
  constructor(
    cause: Error,
    readonly status: number,
    readonly response: MessageResponse | ValidationErrorResponse,
  ) {
    super();
    this.cause = cause;
  }
}

export class ExceptionResponse extends Error {
  constructor(
    cause: Error,
    readonly status: number,
    readonly response: MessageResponse,
  ) {
    super();
    this.cause = cause;
  }
}
