import { $Enums } from '@prisma/client';

export const PH_PERSONAL_ID_TYPES: Partial<Record<$Enums.PhPersonalIdType, string>> = {
  [$Enums.PhPersonalIdType.driversLicense]: 'a00-00-000000',
  [$Enums.PhPersonalIdType.passport]: 'a0000000a',
  [$Enums.PhPersonalIdType.sss]: '00-0000000-0',
  [$Enums.PhPersonalIdType.tin]: '000-000-000-000',
  [$Enums.PhPersonalIdType.umid]: '0000-0000000-0',
  [$Enums.PhPersonalIdType.philhealth]: '00-000000000-0',
};
