import { Box, Card, CardContent, CardHeader, Grid, Paper, Skeleton, Typography } from '@mui/material';
import { StepContent } from '../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../components/StepSubmitButton/StepSubmitButton';
import { currency } from '../../utils/currency';
import { useNextStep } from '../hooks/useNextStep';
import { useThemeQuery } from '../hooks/useThemeQuery';
import { useApplication } from '../../auth/applicationContext';

export const LoanSimulation = () => {
  const title = [<>Loan Simulation / Estimation</>];
  const cardTitle = <>Based on your submission, you can get up to:</>;
  const { goToNextStep } = useNextStep();
  const { isSmall } = useThemeQuery();

  const { application } = useApplication();

  const loanInfo = {
    minAmount: Math.round(Number(application.revenue) * 0.2 * 3),
    maxAmount: Math.round(Number(application.revenue) * 0.2 * 7),
    minPeriod: 3,
    maxPeriod: 7,
    minFee: 24,
    maxFee: 35,
  };

  return (
    <StepContent title={title} width="extended">
      <Grid container direction="column" gap={3} flex={1}>
        <Grid item>
          <Card component={Paper} sx={{ p: isSmall ? '0.5rem' : '3rem' }} elevation={3}>
            <CardHeader title={cardTitle} titleTypographyProps={{ variant: 'h6' }} />
            <CardContent>
              <>
                <Box mb={2}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Loan Size
                  </Typography>
                  <Typography variant="body1" fontWeight={600}>
                    {loanInfo ? (
                      <>
                        {currency.format(loanInfo.minAmount)} - {currency.format(loanInfo.maxAmount)}
                      </>
                    ) : (
                      <Skeleton width="50%" height="24px" animation="wave" />
                    )}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Period
                  </Typography>
                  <Typography variant="body1" fontWeight={600}>
                    {loanInfo ? (
                      <>
                        {loanInfo.minPeriod} - {loanInfo.maxPeriod} months
                      </>
                    ) : (
                      <Skeleton width="20%" height="24px" animation="wave" />
                    )}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Monthly Rate
                  </Typography>
                  <Typography variant="body1" fontWeight={600}>
                    Start from 1.5%
                  </Typography>
                </Box>
                <Typography sx={{ fontStyle: 'italic' }}>
                  Estimated figures are subject to change in the actual loan offer.
                </Typography>
              </>
            </CardContent>
          </Card>
        </Grid>
        <Grid item display="flex" justifyContent="center" pt={2}>
          <StepSubmitButton type="button" onClick={() => goToNextStep()} />
        </Grid>
      </Grid>
    </StepContent>
  );
};
