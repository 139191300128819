import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { LoanStatus } from './LoanStatus';

export const Loan = PrismaModel(
  {
    name: 'Loan',
    dbSchema: 'backoffice',
    key: ['id'],
    uniques: [['businessId', 'orderNumber']],
    indexes: [['employeeId']],
    description: 'Disbursement description',
  },
  {
    id: T.Integer(),
    businessId: T.Integer(),
    createdAt: T.CreatedAt({
      description: 'Date and time of the record creation',
    }),
    orderNumber: T.Integer({
      description:
        'Order numbers of the loans disbursed to the same applicant ',
    }),
    name: T.String(),
    appealName: T.String(),
    date: T.LocalDate({ description: 'Disbursement date' }),
    specialFirstRepaymentDate: T.Nullable(T.LocalDate()),
    loanAgreementNumber: T.Unique(T.String()),
    restructureId: T.Nullable(
      T.Integer({
        description:
          'ID number of a new loan that should be repayed insted of the current one',
      }),
    ),
    writeOffDate: T.Nullable(T.LocalDate()),
    termsId: T.Unique(T.Integer({ description: 'Selected Loan Terms' })),
    vaNumber: T.String({ title: 'Virtual account' }),
    employeeId: T.Integer(),
    personInChargeId: T.Nullable(T.Integer()),
    assignmentId: T.Nullable(
      T.Integer({
        description:
          'Last assignment made with respect of the loan. Exception: if a field visit was assigned and it was finished, this record will   \nbe reset.  All assignments: LoanAction table',
      }),
    ),
    endDate: T.LocalDate(),
    status: LoanStatus,
    repaymentAmount: T.Money(),
    repaidAmount: T.Money(),
    lastPaymentDate: T.Nullable(T.LocalDate()),
    lastInvoiceDate: T.Nullable(T.LocalDate()),
    paymentOverdueDate: T.Nullable(T.LocalDate()),
  },
);
