import { createType, FormatFuncKind, type SimpleFormattable } from '../base';
import { type SchemaOptions, type TLiteral, type TLiteralValue, Type, TypeGuard } from '@sinclair/typebox';

export { type TLiteral } from '@sinclair/typebox';
declare module '@sinclair/typebox' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-interface, @typescript-eslint/no-shadow
  interface TLiteral<T extends TLiteralValue> extends SimpleFormattable {}
}

export const { IsLiteral } = TypeGuard;
export const Literal = <const T extends TLiteralValue>(v: T, opts?: SchemaOptions): TLiteral<T> =>
  createType(Type.Literal(v, opts), {
    [FormatFuncKind]: String,
  });
