import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { ShopType } from './ShopType';

export const OnboardingSaleChannel = PrismaModel(
  {
    name: 'OnboardingSaleChannel',
    dbSchema: 'public',
    key: ['id'],
    indexes: [['onboardingId']],
    description: 'Link to a marketplace or website',
  },
  {
    id: T.AutoIncrement(),
    onboardingId: T.Integer(),
    type: ShopType,
    name: T.Nullable(T.String()),
    link: T.Uri(),
    isSynced: T.Boolean({ default: false }),
    isDataLoaded: T.Boolean({ default: false }),
  },
);
