import { T } from '../../typebox';

export const MessageChannel = T.OurEnum(
  {
    sms: {},
    email: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
