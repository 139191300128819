import { $Enums } from '@getmo/common/prisma';
import { ChangeEventHandler, FocusEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { urls } from '../../../utils/url';
import { useSnackbar } from '../../components/snackbar/useSnackbar';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useOpenWindow } from '../../hooks/useOpenWindow';
import { addProtocol } from '@getmo/common/utils/url';
import { useDebounce } from '../../hooks/useDebounce';
import { Button } from '@mui/material';
import { getShopType, getShopConnectionType } from '@getmo/common/utils/shops';
import {
  Shop,
  shopCreateEndpoint,
  shopDeleteEndpoint,
  shopSyncEndpoint,
  shopUpdateEndpoint,
} from '@getmo/onboarding/schemas/endpoints/shops';
import { callApi } from '../../../api';
import { useApplication } from '../../../auth/applicationContext';
import { R } from '@getmo/common/vendor/remeda';

export const useConnectionStoreLink = (
  shopId: number | undefined,
  setShopId: (id: number) => void,
  deleteInput: () => void,
) => {
  const { application, setApplication } = useApplication();
  const [url, setUrl] = useState('');
  const [syncLink, setSyncLink] = useState<string | undefined>(undefined);
  const [isConnectRequired, setIsConnectRequired] = useState(false);
  const [error, setError] = useState('');
  const [needAcceptEmail, setNeedAcceptEmail] = useState(false);
  const shop = application.saleChannels.find((c) => c.id === shopId);
  const isConnected = useMemo(() => !!shop?.isSynced, [shop]);
  const inputRef = useRef(null);
  const [axiosAbort, setAxiosAbort] = useState(new AbortController());

  const updateURL = (url: string) => {
    axiosAbort.abort();
    setAxiosAbort(new AbortController());
    setError('');
    setUrl(url);
  };

  useDebounce(() => updateShopData(url), 1000);

  const onURLChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => updateURL(target.value);

  const validate = (url: string): string => {
    if (!url) {
      return '';
    }
    if (!urls.isValid(url, { strictProtocol: false })) {
      return 'Invalid URL';
    }
    if (application.saleChannels.some(({ id, link }) => link === url && id !== shopId)) {
      return 'Shop with this URL already exists';
    }
    return '';
  };

  const onClickRemoveBtn = useCallback(async () => {
    if (shop) {
      await callApi(shopDeleteEndpoint, {
        params: { shopId: shop.id },
      });
      setApplication((a) => ({ ...a, saleChannels: a.saleChannels.filter((c) => c.id !== shop.id) }));
    }
    setUrl('');
    deleteInput();
  }, [deleteInput, shop]);

  const updateShopData = useCallback(
    async (url: string) => {
      const error = validate(url);
      const shopType = getShopType(url);
      if (!error && shopType) {
        const data = {
          link: url,
          type: shopType,
        };
        if (!shop) {
          const channel = await callApi(shopCreateEndpoint, { body: data });
          setApplication((a) => ({ ...a, saleChannels: a.saleChannels.concat(channel) }));
          setShopId(channel.id);
          return;
        }

        if (shop.link === url) {
          return;
        }

        const channel = await callApi(shopUpdateEndpoint, { params: { shopId: shop.id }, body: data });
        setApplication((a) => ({
          ...a,
          saleChannels: R.splice(
            a.saleChannels,
            a.saleChannels.findIndex((c) => c.id === shop.id),
            1,
            [channel],
          ),
        }));
      }

      if (shop && !error && !url) {
        await callApi(shopDeleteEndpoint, {
          params: { shopId: shop.id },
        });
        setApplication((a) => ({
          ...a,
          saleChannels: a.saleChannels.filter((c) => c.id !== shop.id),
        }));
      }
    },
    [shop],
  );

  const onBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value.trim();
    const url = addProtocol(value);
    if (value !== url) {
      updateURL(url);
    }
    updateShopData(url);
    setError(url ? validate(url) : '');
  };

  useEffect(() => {
    const setShopData = async (shop: Shop) => {
      setUrl(shop.link);

      if (isConnected || getShopConnectionType(shop.type)) {
        setSyncLink(undefined);
      }
    };
    if (shop) {
      setShopData(shop);
    }

    setIsConnectRequired(shop ? !!getShopConnectionType(shop.type) : false);
  }, [shop]);

  useEffect(() => {
    if (shop) {
      setSyncLink(undefined);
    }
  }, [url]);

  const { showError, showWarning, closeSnackbar } = useSnackbar();
  const { openWindow } = useOpenWindow();
  const [isSyncFetching, setIsSyncFetching] = useState(false);
  const handleConnect = useCallback(async () => {
    if (!shop) {
      return;
    }

    if (!syncLink) {
      setIsSyncFetching(true);
      try {
        const result = await callApi(shopSyncEndpoint, {
          params: { saleChannelId: shop.id },
          signal: axiosAbort.signal,
        });
        setSyncLink(result.syncLink);

        if (result.type === $Enums.ShopType.tokopedia) {
          setNeedAcceptEmail(result.isSynced);
          if (!result.isSynced) {
            showWarning(
              <span>
                An email has been sent to the account linked to your store.
                <br />
                Follow the link inside to grant GetMo read-only access to marketplace data,
                <br /> then come back here and press &quot;Connect account&quot; again.
              </span>,
              {
                persist: true,
                action: (id) => (
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => {
                      closeSnackbar(id);
                    }}
                  >
                    Dismiss
                  </Button>
                ),
              },
            );
          }
          return;
        }

        if (result.syncLink) {
          openWindow(result.syncLink);
        } else {
          showWarning('Cannot connect your shop at the moment, try again later');
        }
      } catch (e) {
        const { error } = useApiErrors(e);
        if (error) {
          showError(error);
        }
      } finally {
        setIsSyncFetching(false);
        setAxiosAbort(new AbortController());
      }

      return;
    }

    if (shop?.type !== $Enums.ShopType.tokopedia) {
      openWindow(syncLink);
    }
  }, [shop, syncLink]);

  return {
    shop,
    isSyncFetching,
    isConnectRequired,
    isConnected,
    inputValue: url,
    inputRef,
    error,
    onURLChange,
    onBlur,
    onClickRemoveBtn,
    handleConnect,
    needAcceptEmail,
  };
};
