import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const CreditHistorySlackRequest = PrismaModel(
  {
    name: 'CreditHistorySlackRequest',
    dbSchema: 'backoffice',
    key: ['id'],
    description: 'Indonesia only',
  },
  {
    id: T.AutoIncrement(),
    threadTs: T.String(),
    isCorporate: T.Boolean(),
    underwritingId: T.Integer(),
    coownerId: T.Nullable(T.Integer()),
  },
);
