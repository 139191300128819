import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const UnderwritingPhBankAccountMonthData = PrismaModel(
  {
    name: 'UnderwritingPhBankAccountMonthData',
    dbSchema: 'backoffice',
    key: ['bankAccountId', 'month'],
  },
  {
    bankAccountId: T.Integer(),
    month: T.YearMonth(),
    totalCredits: T.Money(),
    totalDebits: T.Money(),
    endingBalance: T.Money(),
    dailyAverageBalance: T.Money(),
  },
);
