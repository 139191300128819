import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const VirtualAccount = PrismaModel(
  {
    name: 'VirtualAccount',
    dbSchema: 'backoffice',
    key: ['number'],
  },
  {
    number: T.String(),
    name: T.String(),
  },
);
