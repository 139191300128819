import { T } from '../../typebox';

export const UnderwritingPipeline = T.OurEnum(
  {
    highTickets: {},
    midTickets: {},
    lowTickets: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
