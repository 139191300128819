import { T } from '../../typebox';

export const FieldVisitTenure = T.OurEnum(
  {
    lessThanYear: {},
    y1: { title: '1' },
    y2: { title: '2' },
    y3: { title: '3' },
    y4: { title: '4' },
    y5: { title: '5' },
    y6: { title: '6' },
    y7: { title: '7' },
    y8: { title: '8' },
    y9: { title: '9' },
    y10: { title: '10' },
    moreThan10Years: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
