import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const UnderwritingPhAfs = PrismaModel(
  {
    name: 'UnderwritingPhAfs',
    dbSchema: 'backoffice',
    key: ['id'],
  },
  {
    id: T.AutoIncrement(),
    underwritingId: T.Integer(),
    endingPeriod: T.LocalDate(),
    revenue: T.Nullable(T.Money()),
    costOfGoodsSold: T.Nullable(T.Money()),
    netIncome: T.Nullable(T.Money({ title: 'Net Income / Profit' })),
    cash: T.Nullable(T.Money()),
    accountsReceivables: T.Nullable(T.Money()),
    inventory: T.Nullable(T.Money()),
    totalCurrentAssets: T.Nullable(T.Money()),
    totalAssets: T.Nullable(T.Money()),
    tradePayables: T.Nullable(T.Money()),
    totalShortTermLiabilities: T.Nullable(T.Money()),
    totalLiabilities: T.Nullable(T.Money()),
  },
);
