import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { SaleChannel } from './SaleChannel';

export const Business = PrismaModel(
  {
    name: 'Business',
    dbSchema: 'backoffice',
    key: ['id'],
    description: 'Client of a loan',
  },
  {
    id: T.AutoIncrement(),
    name: T.String(),
    saleChannels: T.Array(SaleChannel),
  },
);
