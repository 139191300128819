import { T } from '../../typebox';

export const IdCity = T.OurEnum(
  {
    bali: {},
    bandarLampung: {},
    bandung: {},
    jabodetabek: {},
    malang: {},
    medan: {},
    palembang: {},
    semarang: {},
    surabaya: {},
    surakarta: {},
    yogyakarta: {},
    other: {},
  },
  {
    dbSchema: 'public',
  },
);
