import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { UnderwritingPhReferenceCheckFeedbackQuality } from './UnderwritingPhReferenceCheckFeedbackQuality';
import { UnderwritingPhReferenceCheckValidation } from './UnderwritingPhReferenceCheckValidation';

export const UnderwritingPhReferenceCheck = PrismaModel(
  {
    name: 'UnderwritingPhReferenceCheck',
    dbSchema: 'backoffice',
    key: ['id'],
  },
  {
    id: T.AutoIncrement(),
    underwritingId: T.Integer(),
    name: T.String(),
    phone: T.String(),
    email: T.String(),
    validation: UnderwritingPhReferenceCheckValidation,
    feedback: T.String(),
    feedbackQuality: UnderwritingPhReferenceCheckFeedbackQuality,
  },
);
