import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const SalesAgent = PrismaModel(
  {
    name: 'SalesAgent',
    dbSchema: 'public',
    key: ['id'],
  },
  {
    id: T.AutoIncrement(),
    name: T.String(),
  },
);
