import { T } from '../../typebox';

export const Region = T.OurEnum(
  {
    id: { title: 'Indonesia' },
    ph: { title: 'Phillipines' },
  },
  {
    dbSchema: 'public',
  },
);
