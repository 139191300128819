import { createType, FormatFuncKind, type SimpleFormattable } from '../base';
import { Literal, Union } from '../vendorType';
import { type SchemaOptions, type TLiteral, type TLiteralValue, type TUnion } from '@sinclair/typebox';

interface TLiteralUnion<T extends TLiteralValue[]>
  extends SimpleFormattable,
    TUnion<{ [key in keyof T]: TLiteral<T[key]> }> {}

export const LiteralUnion = <const T extends TLiteralValue[]>(values: T, opts?: SchemaOptions): TLiteralUnion<T> =>
  createType(
    Union(
      values.map((v) => Literal(v)),
      opts,
    ),
    { [FormatFuncKind]: String },
  );
