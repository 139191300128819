import { T } from '../../typebox';

export const RepaymentScheduleStatus = T.OurEnum(
  {
    pendingInvoice: {},
    pendingRevenue: {},
    unpaid: {},
    paidOnTime: {},
    paidLate: {},
    restructured: {},
    writtenOff: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
