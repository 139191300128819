import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const CreditHistory = PrismaModel(
  {
    name: 'CreditHistory',
    dbSchema: 'backoffice',
    key: ['id'],
    description: 'Indonesia. All credit histories submitted',
  },
  {
    id: T.AutoIncrement({ description: 'Credit history Id' }),
    status: T.SmallInt(),
    statusText: T.String(),
    createdAt: T.Date(),
    pdfRequestId: T.Unique(T.Uuid()),
    coownerId: T.Nullable(T.Integer()),
    corporate: T.Boolean({ default: false }),
    employeeId: T.Nullable(T.Integer()),
    underwritingId: T.Integer(),
  },
);
