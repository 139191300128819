import { T } from '../../typebox';

export const Currency = T.OurEnum(
  {
    usd: {},
    eur: {},
    idr: {},
    php: {},
  },
  {
    dbSchema: 'public',
  },
);
