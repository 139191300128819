import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const ShopOrderItem = PrismaModel(
  {
    name: 'ShopOrderItem',
    dbSchema: 'public',
    key: ['id'],
    uniques: [['itemId', 'modelId', 'orderId']],
    description:
      "An item of client's sale loaded from a marketplace connection",
  },
  {
    id: T.AutoIncrement(),
    quantity: T.Integer(),
    price: T.Decimal(),
    oldPrice: T.Nullable(T.Decimal()),
    modelId: T.Nullable(T.String()),
    itemId: T.String(),
    orderId: T.Integer(),
    productId: T.Nullable(T.Integer()),
  },
);
