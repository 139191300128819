import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const UnderwritingPrevYearMonthlyRevenue = PrismaModel(
  {
    name: 'UnderwritingPrevYearMonthlyRevenue',
    dbSchema: 'backoffice',
    key: ['financialId'],
    description: 'Indonesia only',
  },
  {
    financialId: T.Integer(),
    september: T.Nullable(T.Money({ title: 'September' })),
    august: T.Nullable(T.Money({ title: 'August' })),
    july: T.Nullable(T.Money({ title: 'July' })),
    june: T.Nullable(T.Money({ title: 'June' })),
    may: T.Nullable(T.Money({ title: 'May' })),
    april: T.Nullable(T.Money({ title: 'April' })),
    march: T.Nullable(T.Money({ title: 'March' })),
    february: T.Nullable(T.Money({ title: 'February' })),
    january: T.Nullable(T.Money({ title: 'January' })),
    december: T.Nullable(T.Money({ title: 'December' })),
    november: T.Nullable(T.Money({ title: 'November' })),
    october: T.Nullable(T.Money({ title: 'October' })),
  },
);
