import { GeoDivision } from '@getmo/onboarding/schemas/models/dictionaries';
import { createReducer } from '@reduxjs/toolkit';
import { dictionariesThunk } from './thunks';

type Division = GeoDivision & {
  parent?: Division | null;
};
type State = {
  isLoadingGeoDivisions: boolean;
  geoDivisions: Record<string, Division[]>;
};

const initialState: State = {
  isLoadingGeoDivisions: false,
  geoDivisions: {},
};

export default createReducer(initialState, (builder) => {
  builder.addCase(dictionariesThunk.loadGeoDivisions.pending, (state) => {
    state.isLoadingGeoDivisions = true;
  });
  builder.addCase(dictionariesThunk.loadGeoDivisions.rejected, (state) => {
    state.isLoadingGeoDivisions = false;
  });
  builder.addCase(dictionariesThunk.loadGeoDivisions.fulfilled, (state, { meta, payload }) => {
    state.isLoadingGeoDivisions = false;
    state.geoDivisions[meta.arg.parentDivision?.code ?? ''] = payload.map((child) => ({
      ...child,
      parent: meta.arg.parentDivision,
    }));
  });
});
