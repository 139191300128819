import { RelGeoDivisionParent } from '../models/dictionaries';
import { RelBankStatementDocument, RelDocumentOnboarding } from '../models/documents';
import {
  Onboarding,
  OnboardingCoowner,
  RelOnboardingCompanyContactOnboarding,
  RelOnboardingCoownerOnboarding,
  RelOnboardingEmergencyContactOnboarding,
  RelOnboardingGeoDivision,
  RelOnboardingSaleChannelOnboarding,
  RelPersonUnverifiedDataOnboarding,
} from '../models/onboarding';
import { createEndpoint } from '@getmo/common/endpoint';
import { RelWithRelations, type StaticDecode, T, WithRelations } from '@getmo/common/typebox';
import { R } from '@getmo/common/vendor/remeda';

export const Application = T.Object({
  ...R.omit(
    WithRelations(
      Onboarding,
      RelWithRelations(
        RelOnboardingGeoDivision,
        RelWithRelations(
          RelGeoDivisionParent,
          RelWithRelations(RelGeoDivisionParent, RelWithRelations(RelGeoDivisionParent)),
        ),
      ),
      RelOnboardingSaleChannelOnboarding.rev,
      RelPersonUnverifiedDataOnboarding.rev,
      RelOnboardingCompanyContactOnboarding.rev,
      RelOnboardingEmergencyContactOnboarding.rev,
      RelOnboardingCoownerOnboarding.rev,
      RelWithRelations(RelDocumentOnboarding.rev, RelBankStatementDocument.rev),
    ).properties,
    ['phCity'],
  ),
  phCity: T.Optional(Onboarding.properties.phCity),
});
export type Application = StaticDecode<typeof Application>;

export const applicationGetEndpoint = createEndpoint({
  method: 'get',
  path: '/application',
  response: Application,
});

export const applicationUpdateEndpoint = createEndpoint({
  method: 'patch',
  path: '/application',
  body: T.Partial(
    T.Omit(Application, [
      'id',
      'qualification',
      'vidaKycStatus',
      'geoDivision',
      'documents',
      'coowners',
      'saleChannels',
    ]),
  ),
  response: Application,
});

export const coownerCreateUpdateEndpoint = createEndpoint({
  method: 'post',
  path: '/coowners',
  body: T.Object({
    id: T.Optional(OnboardingCoowner.properties.id),
    ...R.pick(OnboardingCoowner.properties, ['name', 'phone', 'email']),
    documentIds: T.Optional(T.Array(T.Number())),
  }),
  response: OnboardingCoowner,
});

export const coownerDeleteEndpoint = createEndpoint({
  method: 'delete',
  path: '/user/data/coowners/:coownerId',
  params: T.Object({
    coownerId: T.Number(),
  }),
  response: T.Object({}),
});
