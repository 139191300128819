import { formatEnumValue } from '@getmo/common/utils/formats';
import { Grid } from '@mui/material';
import { $Enums } from '@prisma/client';
import { Form, Formik } from 'formik';
import { FC, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { StepContent } from '../../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../../components/StepSubmitButton/StepSubmitButton';
import { yup } from '../../../utils/yup';
import FormikSelectInput from '../../components/FormikSelectInput';
import FormikMaskInput from '../../components/FormikMaskInput';
import { useSnackbar } from '../../components/snackbar/useSnackbar';
import { DocumentFileInput } from '../../components/upload/DocumentFileInput';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useNextStep } from '../../hooks/useNextStep';
import { defaultMimeTypes as defaultMimes } from '../../types/mimeType';
import { DF } from '@getmo/common/vendor/date-fns';
import { PH_PERSONAL_ID_TYPES } from '@getmo/common/utils/phPersonalIdType';
import { Gender, PhPersonalIdType } from '@getmo/common/schemas/models/onboarding';
import { R } from '@getmo/common/vendor/remeda';
import { useApplication } from '../../../auth/applicationContext';
import { FullDocument } from '@getmo/onboarding/schemas/models/documents';

const personalIdTypeOptions = R.pipe(
  $Enums.PhPersonalIdType,
  R.values(),
  R.filter((v) => v in PH_PERSONAL_ID_TYPES),
  R.map((v) => ({
    label: formatEnumValue(PhPersonalIdType, v),
    value: v,
  })),
);

const genderOptions = R.map(R.values($Enums.Gender), (v) => ({
  label: formatEnumValue(Gender, v),
  value: v,
}));

interface InitialValues {
  phPersonalIdType: $Enums.PhPersonalIdType;
  personalIdNumber: string;
  dob: string;
  gender: $Enums.Gender;
  files: {
    personalId: FullDocument[];
  };
}

const PhIdentityVerification: FC = () => {
  const title = <>Identity Verification</>;
  const subtitle = (
    <>
      Please provide the following essential details to assist us in tailoring the perfect financing solution for your
      business.
    </>
  );

  const { goToNextStep } = useNextStep();
  const { application, updateApplication, setApplication } = useApplication();
  const initialValues = useMemo<InitialValues>(
    () => ({
      phPersonalIdType: application.personUnverifiedData?.phPersonalIdType ?? $Enums.PhPersonalIdType.umid,
      personalIdNumber: application.personUnverifiedData?.personalIdNumber ?? '',
      dob: application.personUnverifiedData?.dob ? DF.format(application.personUnverifiedData.dob, 'yyyy/MM/dd') : '',
      gender: application.personUnverifiedData?.gender ?? ('' as never),

      files: {
        personalId: application.documents.filter((d) => !d.coownerId && d.type === $Enums.DocumentType.personalId),
      },
    }),
    [],
  );

  const validation = Yup.object({
    phPersonalIdType: Yup.string().required(),
    personalIdNumber: Yup.string()
      .required()
      .test({
        name: 'isValid',
        message: 'Invalid format',
        test: (value, context) => {
          const docType = context.parent.phPersonalIdType as $Enums.PhPersonalIdType;
          return value.length === PH_PERSONAL_ID_TYPES[docType]?.length;
        },
      }),
    dob: Yup.string()
      .required()
      .test({
        name: 'isDate1',
        message: 'This field is required',
        test: (value) => value.length >= 10,
      })
      .test({
        name: 'isDate2',
        message: 'Not a valid date',
        test: (value) => !isNaN(DF.parse(value, 'yyyy/MM/dd', 0).valueOf()),
      })
      .test({
        name: 'isDateOfBirth1',
        message: 'Invalid date of birth',
        test: (value) =>
          DF.parse(value, 'yyyy/MM/dd', 0).valueOf() < Date.now() &&
          DF.differenceInYears(new Date(), DF.parse(value, 'yyyy/MM/dd', 0).valueOf()) <= 110,
      })
      .test({
        name: 'isDateOfBirth2',
        message: 'Unfortunately, we do not provide services to minors',
        test: (value) => DF.differenceInYears(new Date(), DF.parse(value, 'yyyy/MM/dd', 0).valueOf()) >= 18,
      }),
    gender: Yup.string().required(),
    files: Yup.object({
      personalId: Yup.array().min(1, yup.uploadMinFile(1)),
    }),
  });

  const [isSubmitting, setSubmitting] = useState(false);
  const [initialErrors, setInitialErrors] = useState({});
  const { showError } = useSnackbar();

  const onSubmit = async (values: typeof initialValues) => {
    setInitialErrors({});
    setSubmitting(true);

    try {
      await updateApplication({
        personUnverifiedData: {
          phPersonalIdType: values.phPersonalIdType,
          personalIdNumber: values.personalIdNumber,
          dob: DF.parse(values.dob, 'yyyy/MM/dd', 0),
          gender: values.gender,
          fullName: application.name ?? '',
        },
      });

      const files = R.values(values.files).flat();
      const uploadIds = files.map((u) => u.id);
      setApplication((a) => ({
        ...a,
        documents: a.documents.filter((d) => !uploadIds.includes(d.id)).concat(files),
      }));

      await goToNextStep();
    } catch (e) {
      const { error, validationErrors } = useApiErrors(e);
      setInitialErrors(validationErrors);
      if (error) {
        showError(error);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <StepContent title={title} subtitle={subtitle} width="medium">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        initialErrors={initialErrors}
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container direction="column" gap={0} pt={0}>
                <Grid item>
                  <FormikSelectInput
                    name="phPersonalIdType"
                    label="Type of Personal ID"
                    options={personalIdTypeOptions}
                    required
                  />
                </Grid>
                <Grid item sx={{ mb: 1 }}>
                  <FormikMaskInput
                    name="personalIdNumber"
                    type="text"
                    label="ID Number"
                    placeholder={PH_PERSONAL_ID_TYPES[values.phPersonalIdType]?.toUpperCase()}
                    mask={PH_PERSONAL_ID_TYPES[values.phPersonalIdType]}
                    required
                  />
                </Grid>
                <Grid item sx={{ mb: 1 }}>
                  <FormikMaskInput
                    name="dob"
                    type="text"
                    label="Date of birth"
                    placeholder="YYYY/MM/DD"
                    mask="0000/00/00"
                    required
                  />
                </Grid>
                <Grid item>
                  <FormikSelectInput name="gender" label="Sex" options={genderOptions} required />
                </Grid>
                <Grid item>
                  <DocumentFileInput
                    name="files.personalId"
                    label="Picture of Valid ID"
                    subtitle="Please upload a photo or a scan of the valid ID selected in the previous question"
                    docType={$Enums.DocumentType.personalId}
                    mimeTypes={defaultMimes.personalId}
                    extractImageFromPdf="ktp"
                    required
                  />
                </Grid>
                <Grid item display="flex" justifyContent="center" pt={2}>
                  <StepSubmitButton isSubmitting={isSubmitting} />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StepContent>
  );
};

export default PhIdentityVerification;
