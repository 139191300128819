import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { ShopOrderStatus } from './ShopOrderStatus';

export const ShopOrder = PrismaModel(
  {
    name: 'ShopOrder',
    dbSchema: 'public',
    key: ['id'],
    uniques: [['number', 'connectionId']],
    indexes: [['createdAt'], ['amount']],
    description: "Client's sale data loaded from a marketplace connection",
  },
  {
    id: T.AutoIncrement(),
    number: T.String(),
    createdAt: T.CreatedAt(),
    updatedAt: T.UpdatedAt(),
    status: ShopOrderStatus,
    amount: T.Decimal(),
    fee: T.Decimal(),
    city: T.Nullable(T.String()),
    connectionId: T.Integer(),
  },
);
