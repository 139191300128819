import { T } from '../../typebox';

export const KycStatus = T.OurEnum(
  {
    success: {},
    failed: {},
    inProgress: {},
  },
  {
    dbSchema: 'public',
  },
);
