import { T } from '../../typebox';

export const LoanStatus = T.OurEnum(
  {
    current: {},
    paidOff: {},
    delinquent: {},
    restructured: {},
    writtenOff: {},
  },
  {
    dbSchema: 'backoffice',
    default: 'current',
  },
);
