import { $Enums } from '@getmo/common/prisma';
import { AccountBalance, Clear, DescriptionOutlined, FileCopy, Photo, PictureAsPdf } from '@mui/icons-material';
import { Box, CircularProgress, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import mime from 'mime';
import { useMemo, useState } from 'react';
import { Image } from '../../../../components/Image';
import { MimeType } from '../../../types/mimeType';
import BCAPreview from './../../../../assets/banks/previews/bca.svg';
import BNIPreview from './../../../../assets/banks/previews/bni.svg';
import BRIPreview from './../../../../assets/banks/previews/bri.svg';
import MANDIRIPreview from './../../../../assets/banks/previews/mandiri.svg';
import { UploadedFilePreviewProps } from './useFileUpload';
import { noTranslateClassName } from '../../../../utils/weglot';

const getIcon = (fileName: string): JSX.Element => {
  const sx: SxProps<Theme> = { px: '2px' };
  const mimeType = mime.getType(fileName);

  switch (mimeType) {
    case MimeType.PDF:
      return <PictureAsPdf color="error" sx={sx} />;

    case MimeType.PNG:
    case MimeType.JPG:
      return <Photo color="info" sx={sx} />;

    case MimeType.XLSX:
      return <DescriptionOutlined color="success" sx={sx} />;

    default:
      return <FileCopy color="info" sx={sx} />;
  }
};

const bankIcons: Partial<Record<$Enums.Bank, string>> = {
  [$Enums.Bank.bni]: BNIPreview,
  [$Enums.Bank.bca]: BCAPreview,
  [$Enums.Bank.mandiri]: MANDIRIPreview,
  [$Enums.Bank.bri]: BRIPreview,
};

const getBankIcon = (bank: string) => {
  const bankIcon = bank ? bankIcons[bank as $Enums.Bank] : null;
  if (!bankIcon) {
    return <AccountBalance color="disabled" sx={{ pr: '0.5rem' }} />;
  }
  return <Image src={bankIcon} height={24} sx={{ pr: '5px' }} />;
};

export const UploadedFilePreview = ({
  file: { id, fileName },
  file,
  deleteFile,
  onDeletedFile,
}: UploadedFilePreviewProps) => {
  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await deleteFile(id);
      onDeletedFile(id);
    } finally {
      setIsLoading(false);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const bank = useMemo(() => file.bankStatements?.find((bs) => bs.bank)?.bank, [file]);

  return (
    <Box>
      <Tooltip title={fileName} placement="top" enterTouchDelay={0} classes={{ tooltip: noTranslateClassName }}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          width="fit-content"
          maxWidth="220px"
          px="6px"
          py="6px"
          sx={(theme) => ({
            border: `1px solid ${theme.palette.text.disabled}`,
            borderRadius: '8px',
          })}
        >
          {bank ? getBankIcon(bank) : getIcon(fileName)}
          <Typography
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
            className={noTranslateClassName}
          >
            {fileName}
          </Typography>

          {isLoading && (
            <Box flexShrink={0} width={28} height={24} display="flex" justifyContent="center" alignItems="center">
              <CircularProgress sx={{ color: 'text.disabled' }} size="18px" thickness={5} />
            </Box>
          )}
          {!isLoading && <Clear color="disabled" sx={{ cursor: 'pointer', px: '2px' }} onClick={handleDelete} />}
        </Box>
      </Tooltip>
    </Box>
  );
};
