import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { Currency } from './Currency';
import { PaymentStatus } from './PaymentStatus';

export const XenditPayment = PrismaModel(
  {
    name: 'XenditPayment',
    dbSchema: 'backoffice',
    key: ['referenceId'],
  },
  {
    referenceId: T.String(),
    externalId: T.Unique(T.Nullable(T.String())),
    paymentMethodId: T.String(),
    paymentMethodDisplay: T.Nullable(T.String()),
    currency: Currency,
    amount: T.Money(),
    status: PaymentStatus,
    failureCode: T.Nullable(T.String()),
    webhookIds: T.Array(T.String()),
    createdAt: T.CreatedAt(),
    updatedAt: T.UpdatedAt(),
    loanId: T.Integer(),
    xenditCustomerId: T.Nullable(T.String()),
    invoiceDate: T.LocalDate(),
  },
);
