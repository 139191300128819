import {
  createType,
  FormatFuncKind,
  type FormattableSchema,
  type MaybeObjectFormatOptions,
  PrismaTypeKind,
} from '../base';
import { type SchemaOptions, type TBoolean, type TSchema, Type, TypeGuard } from '@sinclair/typebox';

export { type TBoolean } from '@sinclair/typebox';
declare module '@sinclair/typebox' {
  // eslint-disable-next-line  @typescript-eslint/no-shadow
  interface TBoolean
    extends TSchema,
      FormattableSchema<
        MaybeObjectFormatOptions<{
          trueLabel?: string;
          falseLabel?: string;
        }>
      > {}
}

export const { IsBoolean } = TypeGuard;
export const Boolean = (opts?: SchemaOptions): TBoolean =>
  createType(Type.Boolean(opts), {
    [FormatFuncKind]: (value, { trueLabel = 'Yes', falseLabel = 'No' } = {}) => (value ? trueLabel : falseLabel),
    [PrismaTypeKind]: 'Boolean',
  });
