import { T } from '../../typebox';

export const LoanActionType = T.OurEnum(
  {
    comment: {},
    assignment: {},
    fieldVisit: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
