import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { UnderwritingPhObligationInformationSource } from './UnderwritingPhObligationInformationSource';
import { UnderwritingPhObligationType } from './UnderwritingPhObligationType';

export const UnderwritingPhObligation = PrismaModel(
  {
    name: 'UnderwritingPhObligation',
    dbSchema: 'backoffice',
    key: ['id'],
  },
  {
    id: T.AutoIncrement(),
    underwritingId: T.Integer(),
    type: UnderwritingPhObligationType,
    informationSource: UnderwritingPhObligationInformationSource,
    loanAmount: T.Money(),
    loanDuration: T.Integer(),
    remainingLoanDuration: T.Integer(),
    monthlyRepayment: T.Money(),
  },
);
