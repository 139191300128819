import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const JointeffPayment = PrismaModel(
  {
    name: 'JointeffPayment',
    dbSchema: 'backoffice',
    key: ['id'],
    description: 'Description of a payment from Jointeff response',
  },
  {
    id: T.AutoIncrement(),
    index: T.Integer(),
    refDate: T.Date(),
    merchantId: T.String(),
    amount: T.Money(),
    currency: T.String(),
    description: T.String(),
    procId: T.String(),
    category: T.String(),
    paymentHistoryId: T.Integer(),
  },
);
