import { T } from '../../typebox';

export const GeoDivisionType = T.OurEnum(
  {
    region: {},
    province: {},
    city: {},
    municipality: {},
    subMunicipality: {},
    barangay: {},
  },
  {
    dbSchema: 'public',
  },
);
