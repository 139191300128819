import { T } from './t';
import { type Nullable, unwrapNullable } from './util';
import { R } from '@getmo/common/vendor/remeda';
import {
  type Evaluate,
  type TArray,
  type TObject,
  type TOptional,
  type TProperties,
  type TSchema,
  TypeGuard,
} from '@sinclair/typebox';

export const PrismaModelKind = Symbol.for('PrismaModelKind');
export type PrismaModelKind = typeof PrismaModelKind;

export type RelationModificator = 'Array' | 'Nullable' | '';
export type WrapByModificator<M extends RelationModificator, T extends TSchema> = M extends 'Array'
  ? TArray<T>
  : M extends 'Nullable'
    ? Nullable<T>
    : T;
const WrapByModificator = <M extends RelationModificator, T extends TSchema>(
  modificator: M,
  schema: T,
): WrapByModificator<M, T> =>
  (modificator === 'Array' ? T.Array(schema) : modificator === 'Nullable' ? T.Nullable(schema) : schema) as never;

export interface PrismaModelOptions<Name extends string, T extends string> {
  name: Name;
  dbSchema: string;
  key: T[];
  indexes?: T[][];
  uniques?: T[][];
  description?: string;
}
export interface PrismaModel<Name extends string = string, T extends TProperties = TProperties> extends TObject<T> {
  [PrismaModelKind]: PrismaModelOptions<Name, keyof T & string>;
}

export const PrismaModel = <Name extends string, T extends TProperties>(
  opts: PrismaModelOptions<Name, keyof T & string>,
  props: T,
) => T.Object(props, { [PrismaModelKind]: opts }) as PrismaModel<Name, T>;

export const isPrismaModel = (value: unknown): value is PrismaModel =>
  TypeGuard.IsObject(value) && PrismaModelKind in value;

export const unwrapRelation = (schema: TSchema) =>
  T.IsNullable(schema) ? unwrapNullable(schema) : TypeGuard.IsArray(schema) ? schema.items : schema;

export const isRelationProp = (schema: TSchema) => isPrismaModel(unwrapRelation(schema));

export interface PrismaRelation<
  M1 extends PrismaModel = PrismaModel,
  N1 extends string = string,
  Mod1 extends RelationModificator = RelationModificator,
  M2 extends PrismaModel = PrismaModel,
  N2 extends string = string,
  Mod2 extends RelationModificator = RelationModificator,
> {
  fromModel: M1;
  fromKey: N1;
  fromFields: string[];
  fromType: Mod1;
  toModel: M2;
  toKey: N2;
  toFields: string[];
  toType: Mod2;
  onDelete?: string;
  rev: PrismaRelation<M2, N2, Mod2, M1, N1, Mod1>;
}

export const PrismaRelation = <
  M1 extends PrismaModel,
  N1 extends string,
  Mod1 extends RelationModificator,
  M2 extends PrismaModel,
  N2 extends string,
  Mod2 extends RelationModificator,
>(
  fromModel: M1,
  fromKey: N1,
  fromFields: (keyof M1['properties'] & string)[],
  fromType: Mod1,
  toModel: M2,
  toKey: N2,
  toFields: (keyof M2['properties'] & string)[],
  toType: Mod2,
  onDelete?: string,
): PrismaRelation<M1, N1, Mod1, M2, N2, Mod2> => ({
  fromModel,
  fromKey,
  fromFields,
  fromType,
  toModel,
  toKey,
  toFields,
  toType,
  onDelete,
  get rev() {
    return PrismaRelation(toModel, toKey, toFields, toType, fromModel, fromKey, fromFields, fromType, onDelete);
  },
});

export const ClientRelation = <
  R extends PrismaRelation,
  M1 extends PrismaModel<R['fromModel'][PrismaModelKind]['name']>,
  M2 extends PrismaModel<R['toModel'][PrismaModelKind]['name']>,
>(
  r: R,
  model1: M1,
  model2: M2,
): PrismaRelation<M1, R['fromKey'], R['fromType'], M2, R['toKey'], R['toType']> =>
  PrismaRelation(model1, r.fromKey, r.fromFields, r.fromType, model2, r.toKey, r.toFields, r.toType);

type RelationOptProps<R extends PrismaRelation[]> = Evaluate<{
  [Rel in R[number] as Rel['fromKey']]: TOptional<WrapByModificator<Rel['fromType'], Rel['toModel']>>;
}>;

type WithOptRelations<M extends PrismaModel, R extends PrismaRelation<M>[]> = PrismaModel<
  M[PrismaModelKind]['name'],
  MergeProps<M['properties'], RelationOptProps<R>>
>;
export const WithOptRelations = <M extends PrismaModel, R extends PrismaRelation<M>[]>(
  model: M,
  ...relations: [...R]
): WithOptRelations<M, R> =>
  PrismaModel(model[PrismaModelKind], {
    ...model.properties,
    ...R.mapToObj(relations, (r) => [r.fromKey, T.Optional(WrapByModificator(r.fromType, r.toModel))] as const),
  }) as never;

export const RelWithOptRelations = <R extends PrismaRelation, RS extends PrismaRelation<R['toModel']>[]>(
  rel: R,
  ...relations: [...RS]
): PrismaRelation<
  R['fromModel'],
  R['fromKey'],
  R['fromType'],
  WithOptRelations<R['toModel'], RS>,
  R['toKey'],
  R['toType']
> => ClientRelation(rel, rel.fromModel, WithOptRelations(rel.toModel, ...relations)) as never;

type RelationProps<R extends PrismaRelation[]> = Evaluate<{
  [Rel in R[number] as Rel['fromKey']]: WrapByModificator<Rel['fromType'], Rel['toModel']>;
}>;
type WithRelations<M extends PrismaModel, R extends PrismaRelation<M>[]> = PrismaModel<
  M[PrismaModelKind]['name'],
  MergeProps<M['properties'], RelationProps<R>>
>;
export const WithRelations = <M extends PrismaModel, R extends PrismaRelation<M>[]>(
  model: M,
  ...relations: [...R]
): WithRelations<M, R> =>
  PrismaModel(model[PrismaModelKind], {
    ...model.properties,
    ...R.mapToObj(relations, (r) => [r.fromKey, WrapByModificator(r.fromType, r.toModel)] as const),
  }) as never;
export const RelWithRelations = <R extends PrismaRelation, RS extends PrismaRelation<R['toModel']>[]>(
  rel: R,
  ...relations: [...RS]
): PrismaRelation<
  R['fromModel'],
  R['fromKey'],
  R['fromType'],
  WithRelations<R['toModel'], RS>,
  R['toKey'],
  R['toType']
> => ClientRelation(rel, rel.fromModel, WithRelations(rel.toModel, ...relations)) as never;

export const ClientModel = <Name extends string, T extends TProperties, K extends (keyof T)[]>(
  model: PrismaModel<Name, T>,
  keys: [...K],
): PrismaModel<Name, Evaluate<Pick<T, K[number]>>> =>
  PrismaModel(model[PrismaModelKind], R.pick(model.properties, keys) as never) as never;

type MergeProps<T1 extends TProperties, T2 extends TProperties> = Evaluate<{
  [K in keyof T1 | keyof T2]: K extends keyof T2 ? T2[K] : K extends keyof T1 ? T1[K] : never;
}>;

type OptRelationProp<T extends PrismaModel = PrismaModel> =
  | TOptional<T>
  | TOptional<Nullable<T>>
  | TOptional<TArray<T>>;

export type RelationPickSelection<T extends TProperties> = {
  [K in keyof T]?: T[K] extends OptRelationProp<PrismaModel<infer _, infer P>> ? RelationPickSelection<P> : never;
};

type PickRelationsProps<T extends TProperties, S> = Evaluate<{
  [K in keyof T]: K extends keyof S ? WrappedPickRelations<T[K], S[K]> : T[K];
}>;

type WrappedPickRelations<T extends TSchema, S> =
  T extends Nullable<PrismaModel<infer N, infer P>>
    ? Nullable<PickRelations<N, P, S>>
    : T extends TArray<PrismaModel<infer N, infer P>>
      ? TArray<PickRelations<N, P, S>>
      : T extends TOptional<PrismaModel<infer N, infer P>>
        ? PickRelations<N, P, S>
        : never;

export type PickRelations<N extends string, T extends TProperties, S> = PrismaModel<N, PickRelationsProps<T, S>>;

export const WrappedPickRelations = <M extends TSchema, S>(schema: M, select: S): WrappedPickRelations<M, S> =>
  (TypeGuard.IsArray(schema)
    ? T.Array(PickRelations(schema.items as never, select as never))
    : T.IsNullable(schema)
      ? T.Nullable(PickRelations(unwrapNullable(schema) as never, select as never))
      : PickRelations(schema as never, select as never)) as never;

export const PickRelations = <N extends string, T extends TProperties, S extends RelationPickSelection<T>>(
  model: PrismaModel<N, T>,
  select: S,
): PickRelations<N, T, S> =>
  PrismaModel(
    model[PrismaModelKind],
    R.pipe(
      model.properties as TProperties,
      R.mapValues((s, k) =>
        TypeGuard.IsOptional(s) && isRelationProp(s)
          ? select[k]
            ? WrappedPickRelations(s, select[k] as never)
            : undefined
          : s,
      ),
      R.pickBy(R.isDefined),
    ) as never,
  ) as never;
