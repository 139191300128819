import { T } from '../../typebox';

export const PaymentFrequency = T.OurEnum(
  {
    weekly: {},
    biWeekly: {},
    monthly: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
