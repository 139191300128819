import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const JointeffPaymentHistory = PrismaModel(
  {
    name: 'JointeffPaymentHistory',
    dbSchema: 'backoffice',
    key: ['id'],
    description: 'Jointeff response data',
  },
  {
    id: T.AutoIncrement({ description: 'Payment ID' }),
    underwritingId: T.Unique(T.Integer()),
    token: T.Nullable(T.String()),
  },
);
