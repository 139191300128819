import { Stack } from '@mui/material';
import { useFileUpload } from '../../components/upload/base/useFileUpload';
import { MimeType } from '../../types/mimeType';
import { $Enums } from '@getmo/common/prisma';

export const PhSelfieInput = ({ onChange }: { selfie: string; onChange: (selfie: string) => void }) => {
  const { element, previews } = useFileUpload({
    inputName: 'files.selfie',
    subtitle: 'Please upload a picture of you in your place of business',
    label: 'Selfie in the Store',
    isRequired: true,
    showInfoAlert: true,
    capture: 'user',
    multiple: false,
    mimeTypes: [MimeType.JPG, MimeType.PNG],
    extractImageFromPdf: 'ktp',
    upload: (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onerror = reject;
        reader.onload = () => {
          onChange(reader.result as string);
          resolve({
            id: -1,
            fileName: file.name,
            type: $Enums.DocumentType.selfie,
            coownerId: null,
            pdfCreator: null,
            pdfProducer: null,
            bankStatements: [],
          });
        };
      }),
    deleteFile: async () => onChange(''),
  });

  return (
    <Stack>
      {element}
      {previews && (
        <Stack direction="row" gap={1} flexWrap="wrap" mb={2}>
          {previews}
        </Stack>
      )}
    </Stack>
  );
};
