import { T } from '../../typebox';

export const FieldVisitHouseOwnership = T.OurEnum(
  {
    own: {},
    rent: {},
    freeUse: {},
    is: { title: 'IS' },
  },
  {
    dbSchema: 'backoffice',
  },
);
