import { T } from '../../typebox';

export const UserRole = T.OurEnum(
  {
    fieldAgent: {},
    accountManager: {},
    administrator: {},
    decisionMaker: {},
    underwriter: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
