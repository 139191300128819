import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { UnderwritingPhInventoryAssets } from './UnderwritingPhInventoryAssets';
import { UnderwritingPhLoanPurposeStatus } from './UnderwritingPhLoanPurposeStatus';
import { UnderwritingPhUnderwriterOpinion } from './UnderwritingPhUnderwriterOpinion';

export const UnderwritingPhOverall = PrismaModel(
  {
    name: 'UnderwritingPhOverall',
    dbSchema: 'backoffice',
    key: ['underwritingId'],
  },
  {
    underwritingId: T.Integer(),
    tin: T.Nullable(T.String({ title: 'TIN' })),
    operationStart: T.Nullable(
      T.LocalDate({ title: 'Year of operation start' }),
    ),
    loanPurpose: T.Nullable(T.String()),
    loanPurposeStatus: T.Nullable(UnderwritingPhLoanPurposeStatus),
    inventoryAssets: T.Nullable(UnderwritingPhInventoryAssets),
    underwriterOpinion: T.Nullable(UnderwritingPhUnderwriterOpinion),
  },
);
