import { T } from '../../typebox';

export const FieldVisitEyeTest = T.OurEnum(
  {
    good: {},
    med: {},
    bad: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
