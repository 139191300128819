import { T } from '../../typebox';

export const UnderwritingPhLoanPurposeStatus = T.OurEnum(
  {
    clear: {},
    notClear: {},
    uncertain: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
