import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { SaleChannel } from './SaleChannel';

export const CustomerSaleChannelRevenue = PrismaModel(
  {
    name: 'CustomerSaleChannelRevenue',
    dbSchema: 'backoffice',
    key: ['id'],
    uniques: [['loanId', 'date', 'saleChannel']],
    description: 'For RBF loans',
  },
  {
    id: T.AutoIncrement(),
    loanId: T.Integer(),
    date: T.LocalDate(),
    saleChannel: T.Nullable(SaleChannel),
    amount: T.Money(),
  },
);
