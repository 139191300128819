import { PrismaRelation } from '../../typebox';
import * as DB from './';

export const AyoconnectCardOnboarding = PrismaRelation(
  DB.AyoconnectCard,
  'onboarding',
  ['onboardingId'],
  '',
  DB.Onboarding,
  'ayoconnectCards',
  ['id'],
  'Array',
  'Cascade',
);
export const AyoconnectPaymentCard = PrismaRelation(
  DB.AyoconnectPayment,
  'card',
  ['cardReferenceNumber'],
  'Nullable',
  DB.AyoconnectCard,
  'ayoconnectPayments',
  ['referenceNumber'],
  'Array',
  'SetNull',
);
export const AyoconnectPaymentLoan = PrismaRelation(
  DB.AyoconnectPayment,
  'loan',
  ['loanId'],
  '',
  DB.Loan,
  'ayoconnectPayments',
  ['id'],
  'Array',
  'Restrict',
);
export const AyoconnectPaymentInvoice = PrismaRelation(
  DB.AyoconnectPayment,
  'invoice',
  ['loanId', 'invoiceDate'],
  '',
  DB.Invoice,
  'ayoconnectPayments',
  ['loanId', 'date'],
  'Array',
  'Restrict',
);
export const BankStatementDocument = PrismaRelation(
  DB.BankStatement,
  'document',
  ['documentId'],
  '',
  DB.Document,
  'bankStatements',
  ['id'],
  'Array',
  'Cascade',
);
export const BankTransactionSummaryOnboarding = PrismaRelation(
  DB.BankTransactionSummary,
  'onboarding',
  ['onboardingId'],
  '',
  DB.Onboarding,
  'bankTransactionSummary',
  ['id'],
  'Nullable',
  'Cascade',
);
export const LoanBusiness = PrismaRelation(
  DB.Loan,
  'business',
  ['businessId'],
  '',
  DB.Business,
  'loans',
  ['id'],
  'Array',
);
export const CollectionVisitAssignment = PrismaRelation(
  DB.CollectionVisit,
  'assignment',
  ['id'],
  '',
  DB.LoanAction,
  'collectionVisit',
  ['id'],
  'Nullable',
);
export const CreditHistoryLoanCreditHistory = PrismaRelation(
  DB.CreditHistoryLoan,
  'creditHistory',
  ['creditHistoryId'],
  '',
  DB.CreditHistory,
  'loans',
  ['id'],
  'Array',
  'Cascade',
);
export const CreditHistoryEmployee = PrismaRelation(
  DB.CreditHistory,
  'employee',
  ['employeeId'],
  'Nullable',
  DB.Employee,
  'creditHistories',
  ['id'],
  'Array',
);
export const CreditHistoryUnderwriting = PrismaRelation(
  DB.CreditHistory,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'creditHistory',
  ['id'],
  'Array',
  'Cascade',
);
export const CreditHistoryCoowner = PrismaRelation(
  DB.CreditHistory,
  'coowner',
  ['coownerId'],
  'Nullable',
  DB.OnboardingCoowner,
  'creditHistory',
  ['id'],
  'Array',
  'Cascade',
);
export const CreditHistoryLoanHistoricalDataCreditHistoryLoan = PrismaRelation(
  DB.CreditHistoryLoanHistoricalData,
  'creditHistoryLoan',
  ['creditHistoryId', 'creditHistoryLoanContractCode'],
  '',
  DB.CreditHistoryLoan,
  'historicalData',
  ['creditHistoryId', 'contractCode'],
  'Array',
  'Cascade',
);
export const CreditHistorySlackRequestUnderwriting = PrismaRelation(
  DB.CreditHistorySlackRequest,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'creditHistorySlackRequests',
  ['id'],
  'Array',
  'Cascade',
);
export const CreditHistorySlackRequestCoowner = PrismaRelation(
  DB.CreditHistorySlackRequest,
  'coowner',
  ['coownerId'],
  'Nullable',
  DB.OnboardingCoowner,
  'creditHistorySlackRequests',
  ['id'],
  'Array',
  'Cascade',
);
export const CustomerSaleChannelRevenueInvoice = PrismaRelation(
  DB.CustomerSaleChannelRevenue,
  'invoice',
  ['loanId', 'date'],
  '',
  DB.Invoice,
  'saleChannelRevenues',
  ['loanId', 'date'],
  'Array',
  'Cascade',
);
export const DisbursementStateUnderwriting = PrismaRelation(
  DB.DisbursementState,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'disbursementState',
  ['id'],
  'Nullable',
  'Cascade',
);
export const DocumentOnboarding = PrismaRelation(
  DB.Document,
  'onboarding',
  ['onboardingId'],
  '',
  DB.Onboarding,
  'documents',
  ['id'],
  'Array',
  'Cascade',
);
export const DocumentOriginal = PrismaRelation(
  DB.Document,
  'original',
  ['originalId'],
  'Nullable',
  DB.Document,
  'document',
  ['id'],
  'Array',
);
export const DocumentCoowner = PrismaRelation(
  DB.Document,
  'coowner',
  ['coownerId'],
  'Nullable',
  DB.OnboardingCoowner,
  'documents',
  ['id'],
  'Array',
  'Cascade',
);
export const DocumentOcrDoc = PrismaRelation(
  DB.Document,
  'ocrDoc',
  ['ocrDocId'],
  'Nullable',
  DB.Document,
  'ocrSourceDocs',
  ['id'],
  'Array',
  'SetNull',
);
export const KtpOcrDataDocument = PrismaRelation(
  DB.KtpOcrData,
  'document',
  ['documentId'],
  '',
  DB.Document,
  'ktpOcrData',
  ['id'],
  'Nullable',
  'Cascade',
);
export const NpwpOcrDataDocument = PrismaRelation(
  DB.NpwpOcrData,
  'document',
  ['documentId'],
  '',
  DB.Document,
  'npwpOcrData',
  ['id'],
  'Nullable',
  'Cascade',
);
export const UnderwritingNoteEmployee = PrismaRelation(
  DB.UnderwritingNote,
  'employee',
  ['employeeId'],
  '',
  DB.Employee,
  'underwritingNotes',
  ['id'],
  'Array',
);
export const LoanEmployee = PrismaRelation(
  DB.Loan,
  'employee',
  ['employeeId'],
  '',
  DB.Employee,
  'createdLoans',
  ['id'],
  'Array',
);
export const TermsSuggestionEmployee = PrismaRelation(
  DB.TermsSuggestion,
  'employee',
  ['employeeId'],
  '',
  DB.Employee,
  'termsSuggestions',
  ['id'],
  'Array',
);
export const UnderwritingActionEmployee = PrismaRelation(
  DB.UnderwritingAction,
  'employee',
  ['employeeId'],
  '',
  DB.Employee,
  'underwritingActions',
  ['id'],
  'Array',
);
export const OnboardingLeadOwner = PrismaRelation(
  DB.Onboarding,
  'leadOwner',
  ['leadOwnerId'],
  'Nullable',
  DB.Employee,
  'ownedLeadOnboardings',
  ['id'],
  'Array',
);
export const UnderwritingFieldAgent = PrismaRelation(
  DB.Underwriting,
  'fieldAgent',
  ['fieldAgentId'],
  'Nullable',
  DB.Employee,
  'fieldVisits',
  ['id'],
  'Array',
);
export const UnderwritingUnderwriter = PrismaRelation(
  DB.Underwriting,
  'underwriter',
  ['underwriterId'],
  'Nullable',
  DB.Employee,
  'underwritings',
  ['id'],
  'Array',
);
export const LoanActionReporter = PrismaRelation(
  DB.LoanAction,
  'reporter',
  ['reporterId'],
  '',
  DB.Employee,
  'loanActionReporters',
  ['id'],
  'Array',
);
export const LoanActionAssignee = PrismaRelation(
  DB.LoanAction,
  'assignee',
  ['assigneeId'],
  'Nullable',
  DB.Employee,
  'loanActionAssignees',
  ['id'],
  'Array',
);
export const LoanPersonInCharge = PrismaRelation(
  DB.Loan,
  'personInCharge',
  ['personInChargeId'],
  'Nullable',
  DB.Employee,
  'inChargeLoan',
  ['id'],
  'Array',
);
export const GeoDivisionParent = PrismaRelation(
  DB.GeoDivision,
  'parent',
  ['parentCode'],
  'Nullable',
  DB.GeoDivision,
  'subdivisions',
  ['code'],
  'Array',
);
export const OnboardingGeoDivision = PrismaRelation(
  DB.Onboarding,
  'geoDivision',
  ['geoDivisionCode'],
  'Nullable',
  DB.GeoDivision,
  'onboardings',
  ['code'],
  'Array',
);
export const InvoiceLoan = PrismaRelation(
  DB.Invoice,
  'loan',
  ['loanId'],
  '',
  DB.Loan,
  'invoices',
  ['id'],
  'Array',
  'Cascade',
);
export const InvoiceSchedule = PrismaRelation(
  DB.Invoice,
  'schedule',
  ['loanId', 'date'],
  '',
  DB.RepaymentSchedule,
  'invoice',
  ['loanId', 'date'],
  'Nullable',
);
export const InvoiceAdditionInvoice = PrismaRelation(
  DB.InvoiceAddition,
  'invoice',
  ['loanId', 'date'],
  '',
  DB.Invoice,
  'additions',
  ['loanId', 'date'],
  'Array',
  'Cascade',
);
export const XenditPaymentInvoice = PrismaRelation(
  DB.XenditPayment,
  'invoice',
  ['loanId', 'invoiceDate'],
  '',
  DB.Invoice,
  'xenditPayments',
  ['loanId', 'date'],
  'Array',
  'Restrict',
);
export const JointeffPaymentPaymentHistory = PrismaRelation(
  DB.JointeffPayment,
  'paymentHistory',
  ['paymentHistoryId'],
  '',
  DB.JointeffPaymentHistory,
  'payments',
  ['id'],
  'Array',
  'Cascade',
);
export const JointeffPaymentHistoryUnderwriting = PrismaRelation(
  DB.JointeffPaymentHistory,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'jointeffPaymentHistory',
  ['id'],
  'Nullable',
  'Cascade',
);
export const KbliParent = PrismaRelation(
  DB.Kbli,
  'parent',
  ['parentCode'],
  'Nullable',
  DB.Kbli,
  'children',
  ['code'],
  'Array',
);
export const KbliUnderwritingRequestDescriptionData = PrismaRelation(
  DB.Kbli,
  'underwritingRequestDescriptionData',
  [],
  'Array',
  DB.UnderwritingRequestDescriptionData,
  'kbli',
  [],
  'Array',
);
export const LoanUnderwriting = PrismaRelation(
  DB.Loan,
  'underwriting',
  ['id'],
  '',
  DB.Underwriting,
  'loan',
  ['id'],
  'Nullable',
);
export const LoanRestructure = PrismaRelation(
  DB.Loan,
  'restructure',
  ['restructureId'],
  'Nullable',
  DB.Loan,
  'restructureFor',
  ['id'],
  'Array',
);
export const LoanTerms = PrismaRelation(
  DB.Loan,
  'terms',
  ['termsId'],
  '',
  DB.TermsSuggestion,
  'loan',
  ['id'],
  'Nullable',
);
export const LoanVirtualAccount = PrismaRelation(
  DB.Loan,
  'virtualAccount',
  ['vaNumber'],
  '',
  DB.VirtualAccount,
  'loans',
  ['number'],
  'Array',
);
export const LoanAssignment = PrismaRelation(
  DB.Loan,
  'assignment',
  ['assignmentId'],
  'Nullable',
  DB.LoanAction,
  'currentlyAssignedTo',
  ['id'],
  'Array',
);
export const RepaymentScheduleLoan = PrismaRelation(
  DB.RepaymentSchedule,
  'loan',
  ['loanId'],
  '',
  DB.Loan,
  'repaymentSchedules',
  ['id'],
  'Array',
  'Cascade',
);
export const LoanActionLoan = PrismaRelation(
  DB.LoanAction,
  'loan',
  ['loanId'],
  '',
  DB.Loan,
  'actions',
  ['id'],
  'Array',
  'Cascade',
);
export const LoanRestructureLoan = PrismaRelation(
  DB.LoanRestructure,
  'loan',
  ['loanId'],
  '',
  DB.Loan,
  'restructures',
  ['id'],
  'Array',
);
export const XenditPaymentLoan = PrismaRelation(
  DB.XenditPayment,
  'loan',
  ['loanId'],
  '',
  DB.Loan,
  'xenditPayments',
  ['id'],
  'Array',
  'Restrict',
);
export const LoanDailyOverdueLoan = PrismaRelation(
  DB.LoanDailyOverdue,
  'loan',
  ['loanId'],
  '',
  DB.Loan,
  'dailyOverdues',
  ['id'],
  'Array',
  'Cascade',
);
export const LoanHistoryIntervalValuesLoan = PrismaRelation(
  DB.LoanHistoryIntervalValues,
  'loan',
  ['loanId'],
  '',
  DB.Loan,
  'historyIntervalValues',
  ['id'],
  'Array',
  'Cascade',
);
export const LoanRestructureTerms = PrismaRelation(
  DB.LoanRestructure,
  'terms',
  ['termsId'],
  '',
  DB.TermsSuggestion,
  'selectingRestructure',
  ['id'],
  'Nullable',
);
export const OnboardingOriginal = PrismaRelation(
  DB.Onboarding,
  'original',
  ['originalId'],
  'Nullable',
  DB.Onboarding,
  'copies',
  ['id'],
  'Array',
);
export const OnboardingApplicant = PrismaRelation(
  DB.Onboarding,
  'applicant',
  ['applicantId'],
  'Nullable',
  DB.Person,
  'onboardings',
  ['id'],
  'Array',
);
export const UnderwritingOnboarding = PrismaRelation(
  DB.Underwriting,
  'onboarding',
  ['id'],
  '',
  DB.Onboarding,
  'underwriting',
  ['id'],
  'Nullable',
);
export const OnboardingSaleChannelOnboarding = PrismaRelation(
  DB.OnboardingSaleChannel,
  'onboarding',
  ['onboardingId'],
  '',
  DB.Onboarding,
  'saleChannels',
  ['id'],
  'Array',
  'Cascade',
);
export const OnboardingCompanyContactOnboarding = PrismaRelation(
  DB.OnboardingCompanyContact,
  'onboarding',
  ['onboardingId'],
  '',
  DB.Onboarding,
  'companyContact',
  ['id'],
  'Nullable',
  'Cascade',
);
export const OnboardingEmergencyContactOnboarding = PrismaRelation(
  DB.OnboardingEmergencyContact,
  'onboarding',
  ['onboardingId'],
  '',
  DB.Onboarding,
  'emergencyContact',
  ['id'],
  'Nullable',
  'Cascade',
);
export const OnboardingCoownerOnboarding = PrismaRelation(
  DB.OnboardingCoowner,
  'onboarding',
  ['onboardingId'],
  '',
  DB.Onboarding,
  'coowners',
  ['id'],
  'Array',
  'Cascade',
);
export const PersonUnverifiedDataOnboarding = PrismaRelation(
  DB.PersonUnverifiedData,
  'onboarding',
  ['onboardingId'],
  '',
  DB.Onboarding,
  'personUnverifiedData',
  ['id'],
  'Nullable',
  'Cascade',
);
export const XenditCustomerOnboarding = PrismaRelation(
  DB.XenditCustomer,
  'onboarding',
  ['onboardingId'],
  '',
  DB.Onboarding,
  'xenditCustomer',
  ['id'],
  'Nullable',
);
export const PhCreditHistoryOnboarding = PrismaRelation(
  DB.PhCreditHistory,
  'onboarding',
  ['onboardingId'],
  '',
  DB.Onboarding,
  'phCreditHistory',
  ['id'],
  'Array',
  'Cascade',
);
export const ShopConnectionSaleChannel = PrismaRelation(
  DB.ShopConnection,
  'saleChannel',
  ['saleChannelId'],
  '',
  DB.OnboardingSaleChannel,
  'shopConnection',
  ['id'],
  'Nullable',
  'Cascade',
);
export const UnderwritingPhBankAccountBank = PrismaRelation(
  DB.UnderwritingPhBankAccount,
  'bank',
  ['bankId'],
  '',
  DB.PhBank,
  'underwritingBankAccount',
  ['id'],
  'Array',
);
export const RepaymentVirtualAccount = PrismaRelation(
  DB.Repayment,
  'virtualAccount',
  ['vaNumber'],
  '',
  DB.VirtualAccount,
  'repayments',
  ['number'],
  'Array',
);
export const RepaymentAssignmentRepayment = PrismaRelation(
  DB.RepaymentAssignment,
  'repayment',
  ['repaymentId'],
  '',
  DB.Repayment,
  'assignments',
  ['id'],
  'Array',
  'Cascade',
);
export const RepaymentAssignmentRepaymentSchedule = PrismaRelation(
  DB.RepaymentAssignment,
  'repaymentSchedule',
  ['loanId', 'repaymentScheduleDate'],
  '',
  DB.RepaymentSchedule,
  'assignments',
  ['loanId', 'date'],
  'Array',
  'Cascade',
);
export const UnderwritingSalesAgent = PrismaRelation(
  DB.Underwriting,
  'salesAgent',
  ['salesAgentId'],
  'Nullable',
  DB.SalesAgent,
  'underwritings',
  ['id'],
  'Array',
);
export const ShopOrderConnection = PrismaRelation(
  DB.ShopOrder,
  'connection',
  ['connectionId'],
  '',
  DB.ShopConnection,
  'orders',
  ['saleChannelId'],
  'Array',
  'Cascade',
);
export const ShopProductConnection = PrismaRelation(
  DB.ShopProduct,
  'connection',
  ['connectionId'],
  '',
  DB.ShopConnection,
  'products',
  ['saleChannelId'],
  'Array',
  'Cascade',
);
export const ShopOrderItemOrder = PrismaRelation(
  DB.ShopOrderItem,
  'order',
  ['orderId'],
  '',
  DB.ShopOrder,
  'items',
  ['id'],
  'Array',
  'Cascade',
);
export const ShopOrderItemProduct = PrismaRelation(
  DB.ShopOrderItem,
  'product',
  ['productId'],
  'Nullable',
  DB.ShopProduct,
  'orderItems',
  ['id'],
  'Array',
  'Cascade',
);
export const TermsSuggestionUnderwriting = PrismaRelation(
  DB.TermsSuggestion,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'termsSuggestions',
  ['id'],
  'Array',
  'Cascade',
);
export const UnderwritingSelectedTerms = PrismaRelation(
  DB.Underwriting,
  'selectedTerms',
  ['selectedTermsId'],
  'Nullable',
  DB.TermsSuggestion,
  'selectingUnderwritings',
  ['id'],
  'Array',
  'SetNull',
);
export const UnderwritingActionSelectedTerms = PrismaRelation(
  DB.UnderwritingAction,
  'selectedTerms',
  ['selectedTermsId'],
  'Nullable',
  DB.TermsSuggestion,
  'selectingUnderwritingActions',
  ['id'],
  'Array',
  'SetNull',
);
export const UnderwritingNoteUnderwriting = PrismaRelation(
  DB.UnderwritingNote,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'notes',
  ['id'],
  'Array',
  'Cascade',
);
export const UnderwritingPhFieldVisitDataUnderwriting = PrismaRelation(
  DB.UnderwritingPhFieldVisitData,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'phFieldVisitData',
  ['id'],
  'Nullable',
  'Cascade',
);
export const UnderwritingPhOverallUnderwriting = PrismaRelation(
  DB.UnderwritingPhOverall,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'phOverall',
  ['id'],
  'Nullable',
  'Cascade',
);
export const UnderwritingPhCashCycleUnderwriting = PrismaRelation(
  DB.UnderwritingPhCashCycle,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'phCashCycle',
  ['id'],
  'Nullable',
);
export const UnderwritingPhObligationUnderwriting = PrismaRelation(
  DB.UnderwritingPhObligation,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'phObligations',
  ['id'],
  'Array',
  'Cascade',
);
export const UnderwritingPhReferenceCheckUnderwriting = PrismaRelation(
  DB.UnderwritingPhReferenceCheck,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'phReferenceChecks',
  ['id'],
  'Array',
  'Cascade',
);
export const UnderwritingPhAfsUnderwriting = PrismaRelation(
  DB.UnderwritingPhAfs,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'phAfs',
  ['id'],
  'Array',
  'Cascade',
);
export const UnderwritingPhBankAccountUnderwriting = PrismaRelation(
  DB.UnderwritingPhBankAccount,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'phBankAccounts',
  ['id'],
  'Array',
  'Cascade',
);
export const UnderwritingPhSalesReportUnderwriting = PrismaRelation(
  DB.UnderwritingPhSalesReport,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'phSalesReports',
  ['id'],
  'Array',
  'Cascade',
);
export const UnderwritingRequestDescriptionDataUnderwriting = PrismaRelation(
  DB.UnderwritingRequestDescriptionData,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'requestDescriptionData',
  ['id'],
  'Nullable',
  'Cascade',
);
export const UnderwritingInterviewDataUnderwriting = PrismaRelation(
  DB.UnderwritingInterviewData,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'interviewData',
  ['id'],
  'Nullable',
  'Cascade',
);
export const UnderwritingMainMarketplaceDataUnderwriting = PrismaRelation(
  DB.UnderwritingMainMarketplaceData,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'mainMarketplaceData',
  ['id'],
  'Nullable',
  'Cascade',
);
export const UnderwritingContractorsDataUnderwriting = PrismaRelation(
  DB.UnderwritingContractorsData,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'contractorsData',
  ['id'],
  'Nullable',
  'Cascade',
);
export const UnderwritingFinancialDataUnderwriting = PrismaRelation(
  DB.UnderwritingFinancialData,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'financialData',
  ['id'],
  'Nullable',
  'Cascade',
);
export const UnderwritingCreditHistoryDataUnderwriting = PrismaRelation(
  DB.UnderwritingCreditHistoryData,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'creditHistoryData',
  ['id'],
  'Nullable',
  'Cascade',
);
export const UnderwritingActionUnderwriting = PrismaRelation(
  DB.UnderwritingAction,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'actions',
  ['id'],
  'Array',
  'Cascade',
);
export const UnderwritingStageTimingsUnderwriting = PrismaRelation(
  DB.UnderwritingStageTimings,
  'underwriting',
  ['underwritingId'],
  '',
  DB.Underwriting,
  'stageTimings',
  ['id'],
  'Array',
  'Cascade',
);
export const UnderwritingPrevYearMonthlyRevenueUnderwritingFinancialData =
  PrismaRelation(
    DB.UnderwritingPrevYearMonthlyRevenue,
    'underwritingFinancialData',
    ['financialId'],
    '',
    DB.UnderwritingFinancialData,
    'prevYearMonthlyRevenue',
    ['underwritingId'],
    'Nullable',
    'Cascade',
  );
export const UnderwritingPhBankAccountMonthDataBankAccount = PrismaRelation(
  DB.UnderwritingPhBankAccountMonthData,
  'bankAccount',
  ['bankAccountId'],
  '',
  DB.UnderwritingPhBankAccount,
  'monthData',
  ['id'],
  'Array',
  'Cascade',
);
export const XenditPaymentXenditCustomer = PrismaRelation(
  DB.XenditPayment,
  'xenditCustomer',
  ['xenditCustomerId'],
  'Nullable',
  DB.XenditCustomer,
  'xenditPayments',
  ['externalId'],
  'Array',
  'SetNull',
);
