import { T } from '../../typebox';

export const FieldVisitBusinessPermit = T.OurEnum(
  {
    yes: {},
    no: {},
    bc: { title: 'BC' },
  },
  {
    dbSchema: 'backoffice',
  },
);
