import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const LoanDailyOverdue = PrismaModel(
  {
    name: 'LoanDailyOverdue',
    dbSchema: 'analytics',
    key: ['loanId', 'date'],
    description:
      'DPD. Due Borrowers. For each due borrower: each date from his due period + dpd at this date + amount due at this date',
  },
  {
    loanId: T.Integer(),
    date: T.LocalDate({
      description: 'all the dates when the borrower has an unpaid invoice',
    }),
    dpd: T.Integer({ description: 'dpd by the date' }),
    amount: T.Money({ description: 'total amount due by the date' }),
    principalAmount: T.Money(),
    interestAmount: T.Money(),
    penaltyAmount: T.Money(),
  },
);
