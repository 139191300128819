import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

import { InvoiceAdditionType } from './InvoiceAdditionType';

export const InvoiceAddition = PrismaModel(
  {
    name: 'InvoiceAddition',
    dbSchema: 'backoffice',
    key: ['id'],
  },
  {
    id: T.AutoIncrement(),
    loanId: T.Integer(),
    date: T.LocalDate(),
    type: InvoiceAdditionType,
    title: T.String(),
    amount: T.Money(),
  },
);
