import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const Invoice = PrismaModel(
  {
    name: 'Invoice',
    dbSchema: 'backoffice',
    key: ['loanId', 'date'],
  },
  {
    loanId: T.Integer(),
    date: T.LocalDate({
      description: 'Scheduled date from Repayment Schedule',
    }),
    baseAmount: T.Money(),
  },
);
