import { T } from '../../typebox';

export const OnboardingQualification = T.OurEnum(
  {
    rejected: {},
    lowTicket: {},
    midTicket: {},
    highTicket: {},
  },
  {
    dbSchema: 'public',
  },
);
