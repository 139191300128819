import { T } from '../../typebox';

export const UnderwritingPhObligationInformationSource = T.OurEnum(
  {
    cibiReport: {},
    selfDeclared: {},
    other: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
