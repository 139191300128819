import { T } from '../../typebox';
import { PrismaModel } from '../../typebox';

export const SmsSlackThread = PrismaModel(
  {
    name: 'SmsSlackThread',
    dbSchema: 'backoffice',
    key: ['phone'],
  },
  {
    phone: T.String(),
    slackThreadId: T.String(),
  },
);
